import React, { useState, useEffect } from "react"
import LayoutModal from "../../components/Custom/LayoutModel"
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import "../DatatableScss/datatables.scss"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { Row, Col } from "../imports/import"
import axios from "axios"
import {
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  Button,
  Modal,
  Label,
} from "reactstrap"
import { useDispatch } from "react-redux"
import { userEdit } from "../../store/actions"
import { withRouter } from "react-router-dom"
import { toast } from "react-toastify"
import Tostify from "components/Custom/Tostify"
import Loader from "components/Custom/Loader"
import ContactsTable from "./ContactsTable"
import Multiselect from "multiselect-react-dropdown"
function DirectoryList(props) {
  const dispatch = useDispatch()
  const [directoryList, setDirectoryList] = useState([])
  const [backupList, setBackuList] = useState([])
  const [rowIndexTable, setRowIndexTable] = useState()
  const [loader, setLoader] = useState(false)
  const [modal_xlarge, setmodal_xlarge] = useState(false)
  const [directoriesList, setDirectoriesList] = useState(null)
  const [selectedDirectory, setSelectedDirected] = useState([])

  useEffect(() => {
    let Data = []
    backupList.map((d, i) => {
      selectedDirectory.map((g, ind) => {
        if (d.directoryType._id == g._id) {
          Data.push(d)
        }
      })
    })
    let newData = [...new Set(Data)]
    setDirectoryList(newData)
    if (selectedDirectory.length < 1) {
      setDirectoryList(backupList)
    }
  }, [selectedDirectory.length])

  useEffect(() => {
    var configCity = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/directory_types`,
      headers: {
        "x-auth-token": props.userData.token,
      },
    }

    axios(configCity)
      .then(function (response) {
        setDirectoriesList(response.data)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [])

  useEffect(() => {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/directories/0`,
      headers: {
        "x-auth-token": props.userData.token,
      },
    }

    axios(config)
      .then(function (response) {
        setDirectoryList(response.data.directories)
        setBackuList(response.data.directories)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [])

  function tog_xlarge() {
    setmodal_xlarge(!modal_xlarge)
  }

  const editUserData = data => {
    try {
      localStorage.setItem("editData", JSON.stringify(data))
    } catch (e) {
      console.log("user list editData localStorage fail")
    }

    try {
      dispatch(userEdit(data))

      props.history.push("/add-directory")
    } catch (e) {
      console.log("user list redux editdata  fail")
    }
  }

  const changeStatus = data => {
    setRowIndexTable(rowIndexTable - 1)
    setLoader(true)
    var config = {
      method: "put",
      url: `${process.env.REACT_APP_BASE_URL}/directories/${data._id}/${
        data.isActive ? "0" : "1"
      }`,
      headers: {
        "x-auth-token": props.userData.token,
        "Content-Type": "application/json",
      },
    }

    axios(config)
      .then(function (response) {
        let values = directoryList
        values[directoryList.indexOf(data)].isActive = !data.isActive
        setDirectoryList([...values])
        setLoader(false)
        setTimeout(() => {
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        }, 200)
      })
      .catch(function (error) {
        console.log(error)
        setLoader(false)
        toast.error(error.response && error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
  }

  const columns = [
    {
      dataField: "_id",
      text: "id",
      hidden: true,
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
      formatter: (cellContent, row, rowIndex, field) => {
        return (
          <div
            style={{
              width: 150,
              wordBreak: "break-all",
              overflowWrap: "break-word",
              whiteSpace: "break-spaces",
            }}
          >
            <p>{cellContent}</p>
          </div>
        )
      },
    },

    {
      dataField: "servicesStr",
      text: "Services",
      sort: true,
      formatter: (cellContent, row, rowIndex, field) => {
        return (
          <div
            style={{
              width: 200,
              wordBreak: "break-all",
              overflowWrap: "break-word",
              whiteSpace: "break-spaces",
            }}
          >
            <p>{cellContent}</p>
          </div>
        )
      },
    },
    {
      dataField: "directoryType.name",
      text: "Directory Type",
      sort: true,
    },
    {
      dataField: "companyEmail",
      text: "Company Email",
      sort: true,
    },
    {
      dataField: "mobile",
      text: "Phone",
      sort: true,
    },
    {
      dataField: "isActive",
      text: "Status",
      sort: true,
      hidden: props.userData.isAdmin ? false : true,
      formatter: (cellContent, row, rowIndex, field) => {
        if (row.isActive) {
          return (
            <>
              <Button
                size="sm"
                type="button"
                id={rowIndex === rowIndexTable ? "PopoverLegacy" : ""}
                className="btn btn-success"
              >
                Active
              </Button>
              {rowIndex === rowIndexTable && (
                <UncontrolledPopover
                  placement="top"
                  target="PopoverLegacy"
                  trigger="legacy"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <PopoverHeader style={{ textAlign: "center" }}>
                    Are you sure, you want to{" "}
                    {row.isActive ? "deactivate" : "activate"} this directory?
                  </PopoverHeader>
                  <PopoverBody>
                    <Button
                      size="sm"
                      className="btn btn-success"
                      style={{
                        marginRight: 5,
                      }}
                      onClick={() => changeStatus(row)}
                      color="primary"
                    >
                      Yes
                    </Button>
                    <Button
                      size="sm"
                      style={{
                        marginLeft: 5,
                      }}
                      onClick={() => setRowIndexTable(rowIndexTable - 1)}
                      color="danger"
                    >
                      No
                    </Button>
                  </PopoverBody>
                </UncontrolledPopover>
              )}
            </>
          )
        } else {
          return (
            <>
              {" "}
              <Button
                type="button"
                size="sm"
                id={rowIndex === rowIndexTable ? "PopoverLegacy" : ""}
                className="btn btn-danger"
              >
                Deactive
              </Button>
              {rowIndex === rowIndexTable && (
                <UncontrolledPopover
                  placement="top"
                  target="PopoverLegacy"
                  trigger="legacy"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <PopoverHeader style={{ textAlign: "center" }}>
                    Are you sure, you want to{" "}
                    {row.isActive ? "deactivate" : "activate"} this directory?
                  </PopoverHeader>
                  <PopoverBody>
                    <Button
                      size="sm"
                      style={{
                        marginRight: 5,
                      }}
                      className="btn btn-success"
                      onClick={() => changeStatus(row)}
                      color="primary"
                    >
                      Yes
                    </Button>
                    <Button
                      size="sm"
                      style={{
                        marginLeft: 5,
                      }}
                      onClick={() => setRowIndexTable(rowIndexTable - 1)}
                      color="danger"
                    >
                      No
                    </Button>
                  </PopoverBody>
                </UncontrolledPopover>
              )}
            </>
          )
        }
      },
      events: {
        onMouseEnter: (e, column, columnIndex, row, rowIndex) => {
          setRowIndexTable(rowIndex)
        },
      },
    },
    {
      dataField: "contacts",
      text: "Action",

      formatter: (cellContent, row, rowIndex, field) => {
        return (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Button
              type="button"
              color="primary"
              size="sm"
              style={{
                backgroundColor:
                  row.contacts[0].mobile !== "" ||
                  row.contacts[0].name !== "" ||
                  row.contacts[0].email !== "" ||
                  row.contacts[0].designation !== ""
                    ? "#39C0ED"
                    : "#BDBDBD",
                border: "none",
                cursor:
                  row.contacts[0].mobile !== "" ||
                  row.contacts[0].name !== "" ||
                  row.contacts[0].email !== "" ||
                  row.contacts[0].designation !== ""
                    ? "pointer"
                    : "not-allowed",
              }}
              className="btn"
              onClick={() => {
                row.contacts[0].mobile !== "" ||
                row.contacts[0].name !== "" ||
                row.contacts[0].email !== "" ||
                row.contacts[0].designation !== ""
                  ? setmodal_xlarge(true)
                  : null
              }}
            >
              Contacts
            </Button>
            <div
              style={{
                display: "flex",
                marginLeft: 8,
              }}
            >
              <Button
                type="button"
                size="sm"
                className="btn btn-warning"
                onClick={() => editUserData(row)}
              >
                Edit
              </Button>
            </div>
            {rowIndex === rowIndexTable && modal_xlarge && (
              <Modal
                size="xl"
                isOpen={modal_xlarge}
                toggle={() => {
                  tog_xlarge()
                }}
              >
                <div className="modal-header">
                  <ContactsTable data={cellContent} dirData={row} />
                </div>
              </Modal>
            )}
          </div>
        )
      },
      events: {
        onMouseEnter: (e, column, columnIndex, row, rowIndex) => {
          setRowIndexTable(rowIndex)
        },
      },
    },
  ]
  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange,
  }) => (
    <div className="btn-group" role="group">
      {options.map(option => {
        const isSelect = currSizePerPage === `${option.page}`
        return (
          <button
            key={option.text}
            type="button"
            onClick={() => onSizePerPageChange(option.page)}
            className={`btn ${isSelect ? "btn-secondary" : "btn-primary"}`}
          >
            {option.text}
          </button>
        )
      })}
    </div>
  )
  const defaultSorted = [
    {
      dataField: "name",
      order: "asc",
    },
  ]

  const pageOptions = {
    sizePerPage: 10,
    sizePerPageRenderer,
    totalSize: directoryList.length, // replace later with size(customers),
  }
  const { SearchBar } = Search
  if (loader) {
    return <Loader />
  }
  return (
    <>
      <Tostify />
      <LayoutModal
        title="Directory List"
        sideTitle="Directory"
        button={true}
        btnTitle="Add Directory"
        onclick={() => props.history.push("/add-directory")}
      >
        <PaginationProvider
          pagination={paginationFactory(pageOptions)}
          keyField="_id"
          columns={columns}
          data={directoryList}
        >
          {({ paginationProps, paginationTableProps }) => (
            <ToolkitProvider
              keyField="_id"
              columns={columns}
              data={directoryList}
              search
            >
              {toolkitProps => (
                <React.Fragment>
                  <Row
                    className="mb-2"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      position: "relative",
                      alignItems: "center",
                      alignContent: "center",
                    }}
                  >
                    <Col
                      md={4}
                      sm={4}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        position: "relative",
                        marginBottom: 30,
                      }}
                    >
                      <Label for="directory">Filter By Directory</Label>
                      <Multiselect
                        id="directory"
                        style={{
                          chips: {
                            color: "#fff",
                          },
                        }}
                        options={
                          directoriesList !== null ? directoriesList : []
                        } // Options to display in the dropdown// Preselected value to persist in dropdown
                        onSelect={event => setSelectedDirected(event)}
                        displayValue="name" // Property name to display in the dropdown options
                      />
                    </Col>
                    <Col
                      md={8}
                      sm={8}
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <SearchBar {...toolkitProps.searchProps} />
                          <i className="bx bx-search-alt search-icon" />
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col xl="12">
                      <div className="table-responsive">
                        <BootstrapTable
                          responsive
                          bordered={false}
                          striped={true}
                          defaultSorted={defaultSorted}
                          hover
                          classes={"table align-middle table-nowrap"}
                          headerWrapperClasses={"thead-light"}
                          {...toolkitProps.baseProps}
                          {...paginationTableProps}
                        />
                      </div>
                    </Col>
                  </Row>
                </React.Fragment>
              )}
            </ToolkitProvider>
          )}
        </PaginationProvider>
      </LayoutModal>
    </>
  )
}
DirectoryList.propTypes = {
  userData: PropTypes.object,
  editUser: PropTypes.any,
  history: PropTypes.any,
}

const mapStateToProps = state => {
  return { userData: state.userData }
}

export default connect(mapStateToProps)(withRouter(DirectoryList))
