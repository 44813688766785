import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"

//Dashboard
import Dashboard from "./dashboard/reducer"

import userData from "./user/reducer"

import userEdit from "./edit/reducer"

import courseId from "./learning/reducer"
import webinarId from "./webinar/reducer"

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  ForgetPassword,
  Dashboard,
  userData,
  userEdit,
  courseId,
  webinarId,
})

export default rootReducer
