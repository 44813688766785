import { connect } from "react-redux"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import React, { useState, useEffect } from "react"
import axios from "axios"
import BootstrapTable from "react-bootstrap-table-next"
import moment from "moment"
import {
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  Button,
  Input
} from "reactstrap"
import Loader from "components/Custom/Loader"
import { toast } from "react-toastify"
import Tostify from "components/Custom/Tostify"
import LayoutModal from "../../components/Custom/LayoutModel"
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import { Row, Col } from "../imports/import"
import { useDispatch } from "react-redux"
import { userEdit } from "../../store/actions"
function WebinarList(props) {
  const [WebinarList, setWebinarList] = useState([])
  const [loader, setLoader] = useState(false)
  const [rowIndexTable, setRowIndexTable] = useState()
  const dispatch = useDispatch()
  useEffect(() => {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/webinars/0`,
      headers: {
        "x-auth-token": props.userData.token,
      },
    }

    axios(config)
      .then(function (response) {
        setWebinarList(response.data.webinars)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [])

  const changeStatus = data => {
    setRowIndexTable(rowIndexTable - 1)
    setLoader(true)
    var config = {
      method: "put",
      url: `${process.env.REACT_APP_BASE_URL}/webinars/status/${data._id}/${
        data.isActive ? "0" : "1"
      }`,
      headers: {
        "x-auth-token": props.userData.token,
        "Content-Type": "application/json",
      },
    }

    axios(config)
      .then(function (response) {
        let values = WebinarList
        values[WebinarList.indexOf(data)].isActive = !data.isActive
        setWebinarList([...values])
        setLoader(false)
        setTimeout(() => {
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        }, 200)
      })
      .catch(function (error) {
        console.log(error)
        setLoader(false)
        toast.error(error.response && error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
  }

  const setWebinarPremium = data => {
    setRowIndexTable(rowIndexTable - 1)
    setLoader(true)
    var config = {
      method: "put",
      url: `${process.env.REACT_APP_BASE_URL}/webinars/premium/${data._id}/${
        data.isPremium ? "0" : "1"
      }`,
      headers: {
        "x-auth-token": props.userData.token,
        "Content-Type": "application/json",
      },
    }

    axios(config)
      .then(function (response) {
        setLoader(false)
        let values = WebinarList
        values[WebinarList.indexOf(data)].isPremium = !data.isPremium
        setWebinarList([])
        setTimeout(()=>{
          setWebinarList([...values])
        },200)
        setTimeout(() => {
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        }, 200)
      })
      .catch(function (error) {
        console.log(error)
        setLoader(false)
        toast.error(error.response && error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
  }


  const editUserData = data => {
    try {
      dispatch(userEdit(data))

      props.history.push("/add-webinar")
    } catch (e) {
      console.log("user list redux editdata  fail")
    }
  }

  const columns = [
    {
      dataField: "title",
      text: "Title",
      sort: true,
    },

    {
      dataField: "date",
      text: "Date",
      sort: true,
      formatter: (cellContent, row, rowIndex, field) => {
        return (
          <div
            style={{
              width: 100,
              wordBreak: "break-all",
              overflowWrap: "break-word",
              whiteSpace: "break-spaces",
            }}
          >
            <p>{moment(cellContent).format("DD/MM/YYYY")}</p>
          </div>
        )
      },
    },
    {
      dataField: "from",
      text: "From Time",
      sort: true,
      formatter: (cellContent, row, rowIndex, field) => {
        return (
          <div
            style={{
              width: 100,
              wordBreak: "break-all",
              overflowWrap: "break-word",
              whiteSpace: "break-spaces",
            }}
          >
            <p>{moment(cellContent).format("LT")}</p>
          </div>
        )
      },
    },
    {
      dataField: "to",
      text: "To Time",
      sort: true,
      formatter: (cellContent, row, rowIndex, field) => {
        return (
          <div
            style={{
              width: 100,
              wordBreak: "break-all",
              overflowWrap: "break-word",
              whiteSpace: "break-spaces",
            }}
          >
            <p>{moment(cellContent).format("LT")}</p>
          </div>
        )
      },
    },
    {
      dataField: "dummy1",
      text: "Language",
      sort: true,
      formatter: (cellContent, row, rowIndex, field) => {
        return (
          <div
            style={{
              justifyContent: "row",
              display: "flex",
            }}
          >
            {row.languages.map((d, i) => {
              return (
                <p
                  style={{
                    marginLeft: 4,
                  }}
                  key={i}
                >
                  {i < row.languages.length - 1 ? d.name + ", " : d.name}
                </p>
              )
            })}
          </div>
        )
      },
    },
    {
      dataField: "mrp",
      text: "MRP",
      sort: true,
    },
    {
      dataField: "price",
      text: "Price",
      sort: true,
    },
    {
      dataField: "dummy02",
      text: "Premium",
      isDummyField: true,
      formatter: (cellContent, row, rowIndex) => {
        if (row.isPremium) {
          setTimeout(() => {
            try {
              document.getElementById(`premium${row._id}`).checked = true
            } catch (e) {
              console.log(e)
            }
          }, 200)
        }
        return (
        
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
             <Input
                          type="checkbox"
                          id={"premium" + row._id ? "premium" + row._id : ""}
                          // checked={row.isPremium}
                          className="form-control"
                          placeholder="Link"
                          onChange={e => {                           
                            setWebinarPremium(row)
                          }}
                        />          
          </div>
        )
      },
      events: {
        onMouseEnter: (e, column, columnIndex, row, rowIndex) => {
          setRowIndexTable(rowIndex)
        },
      },
    },
    {
      dataField: "isActive",
      text: "Status",
      sort: true,
      formatter: (cellContent, row, rowIndex, field) => {
        if (row.isActive) {
          return (
            <>
              <Button
                size="sm"
                type="button"
                id={rowIndex === rowIndexTable ? "PopoverLegacy" : ""}
                className="btn btn-success"
                onClick={() => {}}
              >
                Active
              </Button>
              {rowIndex === rowIndexTable && (
                <UncontrolledPopover
                  placement="top"
                  target="PopoverLegacy"
                  trigger="legacy"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <PopoverHeader style={{ textAlign: "center" }}>
                    Are you sure, you want to deactivate this webinar ?
                  </PopoverHeader>
                  <PopoverBody>
                    <Button
                      size="sm"
                      style={{
                        marginRight: 5,
                      }}
                      onClick={() => changeStatus(row)}
                      color="success"
                    >
                      Yes
                    </Button>
                    <Button
                      size="sm"
                      style={{
                        marginLeft: 5,
                      }}
                      onClick={() => setRowIndexTable(rowIndexTable - 1)}
                      color="danger"
                    >
                      No
                    </Button>
                  </PopoverBody>
                </UncontrolledPopover>
              )}
            </>
          )
        } else {
          return (
            <>
              <Button
                size="sm"
                type="button"
                id={rowIndex === rowIndexTable ? "PopoverLegacy" : ""}
                className="btn btn-danger"
              >
                Deactive
              </Button>
              {rowIndex === rowIndexTable && (
                <UncontrolledPopover
                  placement="top"
                  target="PopoverLegacy"
                  trigger="legacy"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <PopoverHeader style={{ textAlign: "center" }}>
                    Are you sure, you want to activate this webinar ?
                  </PopoverHeader>
                  <PopoverBody>
                    <Button
                      size="sm"
                      style={{
                        marginRight: 5,
                      }}
                      onClick={() => changeStatus(row)}
                      color="success"
                    >
                      Yes
                    </Button>
                    <Button
                      size="sm"
                      style={{
                        marginLeft: 5,
                      }}
                      onClick={() => setRowIndexTable(rowIndexTable - 1)}
                      color="danger"
                    >
                      No
                    </Button>
                  </PopoverBody>
                </UncontrolledPopover>
              )}
            </>
          )
        }
      },

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          rowIndex !== rowIndexTable && setRowIndexTable(rowIndex)
        },
        onMouseEnter: (e, column, columnIndex, row, rowIndex) => {
          setRowIndexTable(rowIndex)
        },
      },
    },
    {
      dataField: "dummy01",
      text: "Action",
      isDummyField: true,
      formatter: (cellContent, row, rowIndex) => {
        return (
          <div
            style={{
              display: "flex",
            }}
          >
            <Button
              size="sm"
              type="button"
              className="btn btn-warning "
              onClick={() => editUserData(row)}
            >
              Edit
            </Button>
          </div>
        )
      },
      events: {
        onMouseEnter: (e, column, columnIndex, row, rowIndex) => {
          setRowIndexTable(rowIndex)
        },
      },
    },
  ]

  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange,
  }) => (
    <div className="btn-group" role="group">
      {options.map(option => {
        const isSelect = currSizePerPage === `${option.page}`
        return (
          <button
            key={option.text}
            type="button"
            onClick={() => onSizePerPageChange(option.page)}
            className={`btn ${isSelect ? "btn-secondary" : "btn-primary"}`}
          >
            {option.text}
          </button>
        )
      })}
    </div>
  )

  const defaultSorted = [
    {
      dataField: "name",
      order: "asc",
    },
  ]

  const pageOptions = {
    sizePerPage: 10,
    sizePerPageRenderer,
    totalSize: WebinarList.length, // replace later with size(customers),
  }

  const { SearchBar } = Search

  if (loader) {
    return <Loader />
  }
  return (
    <>
      <Tostify />
      <LayoutModal
        title="Webinar List"
        sideTitle="User"
        button={true}
        btnTitle="Add New Webinar"
        onclick={() => {
          props.history.push("/add-webinar")
        }}
      >
        <PaginationProvider
          pagination={paginationFactory(pageOptions)}
          keyField="_id"
          columns={columns}
          data={WebinarList}
        >
          {({ paginationProps, paginationTableProps }) => (
            <ToolkitProvider
              keyField="_id"
              columns={columns}
              data={WebinarList}
              search
            >
              {toolkitProps => (
                <React.Fragment>
                  <Row className="mb-2">
                    <Col md="4">
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <SearchBar {...toolkitProps.searchProps} />
                          <i className="bx bx-search-alt search-icon" />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl="12">
                      <div className="table-responsive">
                        <BootstrapTable
                          keyField={"_id"}
                          responsive
                          bordered={false}
                          striped={true}
                          defaultSorted={defaultSorted}
                          hover
                          // selectRow={selectRow}
                          classes={"table align-middle table-nowrap"}
                          headerWrapperClasses={"thead-light"}
                          {...toolkitProps.baseProps}
                          {...paginationTableProps}
                        />
                      </div>
                    </Col>
                  </Row>
                </React.Fragment>
              )}
            </ToolkitProvider>
          )}
        </PaginationProvider>
      </LayoutModal>
    </>
  )
}

WebinarList.propTypes = {
  userData: PropTypes.object,
  editUser: PropTypes.any,
  history: PropTypes.any,
}

const mapStateToProps = state => {
  return { userData: state.userData }
}

export default connect(mapStateToProps)(withRouter(WebinarList))
