import PropTypes from "prop-types"
import React from "react"
import { Row, Col, FormFeedback, Input, Label, Form } from "reactstrap"
//redux
import { useSelector, connect } from "react-redux"
import { withRouter } from "react-router-dom"
// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"
import axios from "axios"
import LayoutModal from "../../components/Custom/LayoutModel"
const ForgetPasswordPage = props => {
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      oldPassword: "",
      newPassword: "",
      cnfPassword: "",
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string().required("Please Enter Your Old Password"),
      newPassword: Yup.string()
        .required("Please Enter Your New Password")
        .min(6, "Password must have at least 6 characters")
        .notOneOf(
          [Yup.ref("oldPassword")],
          "Please enter different password from old password"
        ),
      cnfPassword: Yup.string()
        .required("Please confirm Your Password")
        .oneOf([Yup.ref("newPassword")], "Your passwords do not match."),
    }),
    onSubmit: values => {
      var data = JSON.stringify({
        password: values.oldPassword,
        newpassword: values.newPassword,
      })

      var config = {
        method: "put",
        url: `${process.env.REACT_APP_BASE_URL}/users/change`,
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": props.userData.token,
        },
        data: data,
      }

      axios(config)
        .then(function (response) {
          document.getElementById("oldPassword").value = ""
          document.getElementById("newPassword").value = ""
          document.getElementById("cnfPassword").value = ""
        })
        .catch(function (error) {
          console.log(error)
        })
    },
  })

  const { forgetError, forgetSuccessMsg } = useSelector(state => ({
    forgetError: state.ForgetPassword.forgetError,
    forgetSuccessMsg: state.ForgetPassword.forgetSuccessMsg,
  }))

  return (
    <LayoutModal button={false} size={4} title="Reset Password">
      <Form
        className="form-horizontal"
        onSubmit={e => {
          e.preventDefault()
          validation.handleSubmit()
          return false
        }}
      >
        <div className="mb-3">
          <Label className="form-label">Old Password</Label>
          <Input
            name="oldPassword"
            id="oldPassword"
            className="form-control"
            placeholder="Enter your old password"
            type="password"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.oldPassword || ""}
            invalid={
              validation.touched.oldPassword && validation.errors.oldPassword
                ? true
                : false
            }
          />
          {validation.touched.oldPassword && validation.errors.oldPassword ? (
            <FormFeedback type="invalid">
              {validation.errors.oldPassword}
            </FormFeedback>
          ) : null}
        </div>

        <div className="mb-3">
          <Label className="form-label">New Password</Label>
          <Input
            name="newPassword"
            id="newPassword"
            className="form-control"
            placeholder="Enter your new password"
            type="password"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.newPassword || ""}
            invalid={
              validation.touched.newPassword && validation.errors.newPassword
                ? true
                : false
            }
          />
          {validation.touched.newPassword && validation.errors.newPassword ? (
            <FormFeedback type="invalid">
              {validation.errors.newPassword}
            </FormFeedback>
          ) : null}
        </div>

        <div className="mb-3">
          <Label className="form-label">Confirm Password</Label>
          <Input
            name="cnfPassword"
            id="cnfPassword"
            className="form-control"
            placeholder="Confirm your password"
            type="password"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.cnfPassword || ""}
            invalid={
              validation.touched.cnfPassword && validation.errors.cnfPassword
                ? true
                : false
            }
          />
          {validation.touched.cnfPassword && validation.errors.cnfPassword ? (
            <FormFeedback type="invalid">
              {validation.errors.cnfPassword}
            </FormFeedback>
          ) : null}
        </div>

        <Row className="mb-3">
          <Col className="text-start">
            <button className="btn btn-primary w-md " type="submit">
              Reset
            </button>
          </Col>
        </Row>
      </Form>
    </LayoutModal>
  )
}

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
  userData: PropTypes.object,
}

const mapStateToProps = state => {
  return {
    userData: state.userData,
  }
}

export default connect(mapStateToProps)(withRouter(ForgetPasswordPage))
