import React, { useState, useEffect } from "react"
import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  Label,
  Form,
  Input,
  FormFeedback,
  InputGroup,
  FormGroup,
} from "reactstrap"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { withRouter, Link } from "react-router-dom"
//Import Breadcrumb
import Multiselect from "multiselect-react-dropdown"
import PropTypes from "prop-types"
import { useDispatch, connect } from "react-redux"
import axios from "axios"
import { useFormik } from "formik"
import * as Yup from "yup"
import moment from "moment"
import { toast } from "react-toastify"
import Loader from "components/Custom/Loader"
import { userEdit } from "../../store/actions"
import TextField from "@mui/material/TextField"
import LocalizationProvider from "@mui/lab/LocalizationProvider"
import TimePicker from "@mui/lab/TimePicker"
import MomentUtils from "@date-io/moment"
import Selector from "components/Custom/Selector"
import { webinarId } from "../../store/actions"
const AddDetails = props => {
  const [languageList, setLanguageList] = useState([])
  const [selectLanguage, setSelectedLanguage] = useState([])
  const [durationTypes, setDurationTypes] = useState([])
  const [selectDuration, selectDurationTypes] = useState(null)
  const [loader, setLoader] = useState(false)
  const [date, setDate] = useState(null)
  const [files, setFiles] = useState(null)
  const [showEditImage, setShowEditImage] = useState(true)
  const [showEditCertificates, setShowEditCertificates] = useState(true)
  const [certificates, setCertificates] = useState(null)
  const [dateFrom, setDateFrom] = useState(
    props.editData
      ? props.editData.from
      : moment(new Date()).hour(9).minutes(0).seconds(0)
  )
  const [dateTo, setDateTo] = useState(
    props.editData
      ? props.editData.to
      : moment(new Date()).hour(17).minutes(0).seconds(0)
  )
  const dispatch = useDispatch()
  // getting durationtypes data
  useEffect(() => {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/formdata/durationtypes`,
      headers: {
        "x-auth-token": props.userData.token,
      },
    }

    axios(config)
      .then(function (response) {
        setDurationTypes(response.data.duration_types)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [])

  useEffect(() => {
    if (props.editData) {
      setSelectedLanguage(props.editData.languages)
      if (props.editData.durationType) {
        selectDurationTypes(props.editData.durationType._id)
      }
      setDate(props.editData.date)
    }
    return () => dispatch(userEdit(null))
  }, [props.editData])
  const validation = useFormik({
    initialValues: {
      title: props.editData ? props.editData.title : "",
      duration: props.editData ? props.editData.duration : "",
      overview: props.editData ? props.editData.overview : "",
      videolink: props.editData ? props.editData.video : "",
      mrp: props.editData ? props.editData.mrp : "",
      price: props.editData ? props.editData.price : "",
      views: props.editData ? props.editData.views : "",
      rating: props.editData ? props.editData.rating : "",
      soldTimes: props.editData ? props.editData.soldTimes : "",
      file: "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please enter title "),
      duration: Yup.string().required("Please enter duration"),
      overview: Yup.string().required("Please enter overview"),
      videolink: Yup.string().required("Please enter video link "),
      rating: Yup.string().required("Please enter rating"),
      mrp: Yup.string().required("Please enter mrp"),
      price: Yup.string().required("Please enter price"),
      views: Yup.string().required("Please enter views"),
      soldTimes: Yup.string().required("Please enter soldTimes"),
    }),

    onSubmit: values => {
      if (date == null) {
        return toast.error("Please select date", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      }

      if (!props.editData) {
        if (certificates == null) {
          return toast.error("Please upload certificates", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        }

        if (files == null) {
          return toast.error("Please select a file", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        }
      }
      if (selectDuration == null) {
        return toast.error("Please select a duration types", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      }

      if (selectLanguage == "") {
        return toast.error("Please select languages", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      }

      const getId= (url)  => {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const match = url.match(regExp);
    
        return (match && match[2].length === 11)
          ? match[2]
          : null;
    }
        
    const videoId = getId(values.videolink);
    const embedVideoLink = "https://www.youtube.com/embed/" + videoId

      var data = new FormData()
      data.append("title", values.title)
      data.append("overview", values.overview)
      data.append("duration", values.duration)
      data.append("durationType", selectDuration)
      data.append("soldTimes", values.soldTimes)
      data.append("date", date)
      data.append("from", dateFrom)
      data.append("to", dateTo)
      selectLanguage.map((d, i) => {
        data.append(`languages[${i}]`, d._id)
      })
      data.append("mrp", values.mrp)
      data.append("price", values.price)
      data.append("views", values.views)
      data.append("rating", values.rating)
      data.append("video", embedVideoLink)
      files !== null && data.append("pic", files)
      certificates !== null && data.append("certificate", certificates)
      var config = {
        method: props.editData ? "put" : "post",
        url: `${process.env.REACT_APP_BASE_URL}/${
          props.editData ? "webinars/details/" + props.editData._id : "webinars"}`,
        headers: {
          "x-auth-token": props.userData.token,
          "Content-Type": "application/json",
        },
        data: data,
      }
      setLoader(true)
      axios(config)
        .then(function (response) {
          setLoader(false)
          dispatch(webinarId(response.data.id))
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        })
        .catch(function (error) {
          console.log(error)
          setLoader(false)
          toast.error(error.response && error.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        })
    },
  })

  // getting languages data
  useEffect(() => {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/formdata/languages`,
      headers: {
        "x-auth-token": props.userData.token,
        "Content-Type": "application/json",
      },
    }

    axios(config)
      .then(function (response) {
        setLanguageList(response.data.languages)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [])

  if (loader) {
    return <Loader />
  }
  return (
    <>
      <React.Fragment>
        <div>
          <Form
            className="form-horizontal"
            onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
              return false
            }}
          >
            <Container fluid={true}>
              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <Row>
                        <Col lg={6}>
                          <div className="mb-3">
                            <Label htmlFor="formrow-InputZip">
                              Title <span style={{ color: "red" }}>*</span>
                            </Label>
                            <Input
                              type="text"
                              id="title"
                              name="title"
                              className="form-control"
                              placeholder="Enter your Title"
                              onChange={validation.handleChange}
                              value={validation.values.title || ""}
                              invalid={
                                validation.touched.title &&
                                validation.errors.title
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.title &&
                            validation.errors.title ? (
                              <FormFeedback type="invalid">
                                {validation.errors.title}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div>
                            <Label for="services">
                              Languages <span style={{ color: "red" }}>*</span>
                            </Label>
                            <Multiselect
                              id="services"
                              style={{
                                chips: {
                                  color: "#fff",
                                },
                              }}
                              options={
                                languageList !== null ? languageList : []
                              } // Options to display in the dropdown
                              selectedValues={
                                props.editData
                                  ? props.editData.languages
                                  : selectLanguage
                              } // Preselected value to persist in dropdown
                              onSelect={event => setSelectedLanguage(event)}
                              // onSelect={this.onSelect} // Function will trigger on select event
                              onRemove={e => setSelectedLanguage(e)} // Function will trigger on remove event
                              displayValue="name" // Property name to display in the dropdown options
                            />
                            <p style={{ color: "red", fontSize: 11 }}> </p>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={6}>
                          <FormGroup className="mb-4">
                            <Label>
                              Date <span style={{ color: "red" }}>*</span>
                            </Label>
                            <InputGroup>
                              <Flatpickr
                                className="form-control d-block"
                                placeholder="DD/MM/YYYY"
                                name="starting_date"
                                value={
                                  props.editData ? props.editData.date : date
                                }
                                options={{
                                  altInput: true,
                                  altFormat: "d/m/Y",
                                  dateFormat: "d-m-yyyy",
                                }}
                                onChange={e => setDate(e[0])}
                                invalid={
                                  validation.touched.starting_date &&
                                  validation.errors.starting_date
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.starting_date &&
                              validation.errors.starting_date ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.starting_date}
                                </FormFeedback>
                              ) : null}
                            </InputGroup>
                          </FormGroup>
                        </Col>

                        <Col
                          lg={3}
                          style={{
                            marginTop: 25,
                          }}
                        >
                          <LocalizationProvider dateAdapter={MomentUtils}>
                            <TimePicker
                              label="From"
                              id="fromTime"
                              value={dateFrom}
                              onChange={e => {
                                const from = moment(e)
                                setDateFrom(from)
                              }}
                              renderInput={params => (
                                <TextField
                                  style={{ zIndex: 0 }}
                                  size="small"
                                  variant="outlined"
                                  {...params}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Col>

                        <Col
                          lg={3}
                          style={{
                            marginTop: 25,
                          }}
                        >
                          <LocalizationProvider dateAdapter={MomentUtils}>
                            <TimePicker
                              label="To"
                              id="toTime"
                              value={dateTo}
                              onChange={e => {
                                const to = moment(e)
                                setDateTo(to)
                              }}
                              renderInput={params => (
                                <TextField
                                  style={{ zIndex: 0 }}
                                  size="small"
                                  variant="outlined"
                                  {...params}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={6}>
                          <div className="mb-3">
                            <Label htmlFor="formrow-InputZip">
                              Duration <span style={{ color: "red" }}>*</span>
                            </Label>
                            <Input
                              type="text"
                              id="duration"
                              name="duration"
                              className="form-control"
                              placeholder="Enter your Duration"
                              onChange={validation.handleChange}
                              value={validation.values.duration || ""}
                              invalid={
                                validation.touched.duration &&
                                validation.errors.duration
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.duration &&
                            validation.errors.duration ? (
                              <FormFeedback type="invalid">
                                {validation.errors.duration}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3">
                            <Selector
                              label="Duration Type"
                              required
                              data={durationTypes !== null ? durationTypes : []}
                              onchange={event => {
                                selectDurationTypes(event.value)
                              }}
                              edit={
                                props.editData &&
                                props.editData.durationType && {
                                  label: props.editData.durationType.name,
                                  value: props.editData.durationType._id,
                                }
                              }
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={6}>
                          <div className="mb-3">
                            <Label htmlFor="formrow-InputZip">
                              Overview <span style={{ color: "red" }}>*</span>
                            </Label>
                            <Input
                              type="textarea"
                              id="overview"
                              name="overview"
                              className="form-control"
                              placeholder="Ente your Overview"
                              onChange={validation.handleChange}
                              value={validation.values.overview || ""}
                              invalid={
                                validation.touched.overview &&
                                validation.errors.overview
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.overview &&
                            validation.errors.overview ? (
                              <FormFeedback type="invalid">
                                {validation.errors.overview}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="mb-3">
                            <Label htmlFor="formrow-InputZip">
                              Video <span style={{ color: "red" }}>*</span>
                            </Label>
                            <Input
                              type="text"
                              id="videolink"
                              name="videolink"
                              className="form-control"
                              placeholder="Enter Video link"
                              onChange={validation.handleChange}
                              value={validation.values.videolink || ""}
                              invalid={
                                validation.touched.videolink &&
                                validation.errors.videolink
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.videolink &&
                            validation.errors.videolink ? (
                              <FormFeedback type="invalid">
                                {validation.errors.videolink}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={3}>
                          <div className="mb-3">
                            <Label htmlFor="formrow-InputZip">
                              MRP <span style={{ color: "red" }}>*</span>
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="mrp"
                              name="mrp"
                              placeholder="Enter MRP"
                              value={validation.values.mrp || ""}
                              onChange={validation.handleChange}
                              invalid={
                                validation.touched.mrp && validation.errors.mrp
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.mrp && validation.errors.mrp ? (
                              <FormFeedback type="invalid">
                                {validation.errors.mrp}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col lg={3}>
                          <div className="mb-3">
                            <Label htmlFor="formrow-InputZip">
                              Price <span style={{ color: "red" }}>*</span>
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="price"
                              name="price"
                              placeholder="Enter Price"
                              onChange={validation.handleChange}
                              value={validation.values.price || ""}
                              invalid={
                                validation.touched.price &&
                                validation.errors.price
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.price &&
                            validation.errors.price ? (
                              <FormFeedback type="invalid">
                                {validation.errors.price}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={3}>
                          <div className="mb-3">
                            <Label htmlFor="formrow-InputZip">
                              Views <span style={{ color: "red" }}>*</span>
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="views"
                              name="views"
                              placeholder="Enter Views"
                              value={validation.values.views || ""}
                              onChange={validation.handleChange}
                              invalid={
                                validation.touched.views &&
                                validation.errors.views
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.views &&
                            validation.errors.views ? (
                              <FormFeedback type="invalid">
                                {validation.errors.views}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col lg={3}>
                          <div className="mb-3">
                            <Label htmlFor="formrow-InputZip">
                              Rating <span style={{ color: "red" }}>*</span>
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="rating"
                              name="rating"
                              placeholder="Enter your Rating"
                              onChange={validation.handleChange}
                              value={validation.values.rating || ""}
                              invalid={
                                validation.touched.rating &&
                                validation.errors.rating
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.rating &&
                            validation.errors.rating ? (
                              <FormFeedback type="invalid">
                                {validation.errors.rating}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col md={6}>
                          <Label className="form-label">
                            Sold Times <span style={{ color: "red" }}>*</span>
                          </Label>
                          <Input
                            name="soldTimes"
                            id="soldTimes"
                            className="form-control"
                            placeholder="Sold time"
                            value={validation.values.soldTimes || ""}
                            onKeyPress={event => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault()
                              }
                            }}
                            type="text"
                            onChange={validation.handleChange}
                            invalid={
                              validation.touched.soldTimes &&
                              validation.errors.soldTimes
                                ? true
                                : false
                            }
                          />
                          {validation.touched.soldTimes &&
                          validation.errors.soldTimes ? (
                            <FormFeedback type="invalid">
                              {validation.errors.soldTimes}
                            </FormFeedback>
                          ) : null}
                        </Col>
                      </Row>

                      <Row>
                        <Col lg={6}>
                          <div className="mb-6">
                            <Label className="form-label">
                              Upload Certificate
                            </Label>{" "}
                            <span style={{ color: "red" }}>*</span>
                            <Input
                              name="certificates"
                              id="certificates"
                              className="form-control"
                              type="file"
                              onChange={e => {
                                setCertificates(e.target.files[0])
                              }}
                              value={validation.certificates || ""}
                              invalid={
                                (validation.touched.certificates &&
                                  certificates !== null &&
                                  certificates.size >
                                    Number(process.env.REACT_APP_FILE_SIZE)) ||
                                validation.errors.certificates
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.file &&
                            certificates !== null &&
                            certificates.size >
                              Number(process.env.REACT_APP_FILE_SIZE) ? (
                              <FormFeedback type="invalid">
                                File must be less than 2 MB
                              </FormFeedback>
                            ) : null}
                            {validation.touched.certificates &&
                            validation.errors.certificates ? (
                              <FormFeedback type="invalid">
                                {validation.errors.certificates}
                              </FormFeedback>
                            ) : null}
                            {certificates !== null && (
                              <Card
                                className="mt-2 mb-10 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                style={{
                                  backgroundColor:
                                    certificates.size >
                                    Number(process.env.REACT_APP_FILE_SIZE)
                                      ? "#ff6a6a"
                                      : "#2FDD92",
                                  color:
                                    certificates.size >
                                    Number(process.env.REACT_APP_FILE_SIZE)
                                      ? "#ffffff"
                                      : "#131313",
                                  width: "97%",
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                }}
                              >
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      <img
                                        data-dz-thumbnail=""
                                        height="80"
                                        className="avatar-sm rounded bg-light"
                                        style={{
                                          objectFit: "cover",
                                        }}
                                        alt={certificates.name}
                                        src={URL.createObjectURL(certificates)}
                                      />
                                    </Col>
                                    <Col>
                                      <Link
                                        to="#"
                                        style={{
                                          color:
                                            certificates.size >
                                            Number(
                                              process.env.REACT_APP_FILE_SIZE
                                            )
                                              ? "#ffffff"
                                              : "#131313",
                                        }}
                                      >
                                        {certificates.name}
                                      </Link>
                                      <p className="mb-0">
                                        <strong>
                                          {(
                                            certificates.size / 1048576
                                          ).toFixed(2) + " MB"}
                                        </strong>
                                      </p>
                                      <i
                                        className="bx bx-no-entry"
                                        style={{
                                          position: "absolute",
                                          top: 0,
                                          right: 10,
                                          color:
                                            certificates.size >
                                            Number(
                                              process.env.REACT_APP_FILE_SIZE
                                            )
                                              ? "#ffffff"
                                              : "#131313",
                                          fontSize: 17,
                                          fontWeight: 800,
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          setCertificates(null)
                                          document.getElementById(
                                            "certificates"
                                          ).value = ""
                                        }}
                                      ></i>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            )}
                            {props.editData &&
                              showEditCertificates &&
                              certificates == null && (
                                <Card
                                  className="mt-2 mb-10 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                  style={{
                                    backgroundColor:
                                      props.editData.certificate.fileSize >
                                      Number(process.env.REACT_APP_FILE_SIZE)
                                        ? "#ff6a6a"
                                        : "#2FDD92",
                                    color:
                                      props.editData.certificate.fileSize >
                                      Number(process.env.REACT_APP_FILE_SIZE)
                                        ? "#ffffff"
                                        : "#131313",
                                    width: "97%",
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                  }}
                                >
                                  <div className="p-2">
                                    <Row className="align-items-center">
                                      <Col className="col-auto">
                                        <img
                                          data-dz-thumbnail=""
                                          height="80"
                                          className="avatar-sm rounded bg-light"
                                          style={{
                                            objectFit: "cover",
                                          }}
                                          alt={
                                            props.editData.certificate
                                              .originalname
                                          }
                                          src={`${process.env.REACT_APP_IMAGE_URL}/webinar_pictures/${props.editData.certificate.filename}`}
                                        />
                                      </Col>
                                      <Col>
                                        <Link
                                          to="#"
                                          style={{
                                            color:
                                              props.editData.certificate
                                                .fileSize >
                                              Number(
                                                process.env.REACT_APP_FILE_SIZE
                                              )
                                                ? "#ffffff"
                                                : "#131313",
                                          }}
                                        >
                                          {
                                            props.editData.certificate
                                              .originalname
                                          }
                                        </Link>
                                        <p className="mb-0">
                                          <strong>
                                            {(
                                              props.editData.certificate
                                                .fileSize / 1048576
                                            ).toFixed(2) + " MB"}
                                          </strong>
                                        </p>
                                        <i
                                          className="bx bx-no-entry"
                                          style={{
                                            position: "absolute",
                                            top: 0,
                                            right: 10,
                                            color:
                                              props.editData.certificate
                                                .fileSize >
                                              Number(
                                                process.env.REACT_APP_FILE_SIZE
                                              )
                                                ? "#ffffff"
                                                : "#131313",
                                            fontSize: 17,
                                            fontWeight: 800,
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            setShowEditCertificates(false)
                                          }}
                                        ></i>
                                      </Col>
                                    </Row>
                                  </div>
                                </Card>
                              )}
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="mb-6">
                            <Label className="form-label">Upload Picture</Label>{" "}
                            <span style={{ color: "red" }}>*</span>
                            <Input
                              name="file"
                              id="file"
                              className="form-control"
                              type="file"
                              onChange={e => {
                                setFiles(e.target.files[0])
                              }}
                              value={validation.file || ""}
                              invalid={
                                (validation.touched.file &&
                                  files !== null &&
                                  files.size >
                                    Number(process.env.REACT_APP_FILE_SIZE)) ||
                                validation.errors.file
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.file &&
                            files !== null &&
                            files.size >
                              Number(process.env.REACT_APP_FILE_SIZE) ? (
                              <FormFeedback type="invalid">
                                File must be less than 2 MB
                              </FormFeedback>
                            ) : null}
                            {validation.touched.file &&
                            validation.errors.file ? (
                              <FormFeedback type="invalid">
                                {validation.errors.file}
                              </FormFeedback>
                            ) : null}
                            {files !== null && (
                              <Card
                                className="mt-2 mb-10 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                style={{
                                  backgroundColor:
                                    files.size >
                                    Number(process.env.REACT_APP_FILE_SIZE)
                                      ? "#ff6a6a"
                                      : "#2FDD92",
                                  color:
                                    files.size >
                                    Number(process.env.REACT_APP_FILE_SIZE)
                                      ? "#ffffff"
                                      : "#131313",
                                  width: "97%",
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                }}
                              >
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      <img
                                        data-dz-thumbnail=""
                                        height="80"
                                        className="avatar-sm rounded bg-light"
                                        style={{
                                          objectFit: "cover",
                                        }}
                                        alt={files.name}
                                        src={URL.createObjectURL(files)}
                                      />
                                    </Col>
                                    <Col>
                                      <Link
                                        to="#"
                                        style={{
                                          color:
                                            files.size >
                                            Number(
                                              process.env.REACT_APP_FILE_SIZE
                                            )
                                              ? "#ffffff"
                                              : "#131313",
                                        }}
                                      >
                                        {files.name}
                                      </Link>
                                      <p className="mb-0">
                                        <strong>
                                          {(files.size / 1048576).toFixed(2) +
                                            " MB"}
                                        </strong>
                                      </p>
                                      <i
                                        className="bx bx-no-entry"
                                        style={{
                                          position: "absolute",
                                          top: 0,
                                          right: 10,
                                          color:
                                            files.size >
                                            Number(
                                              process.env.REACT_APP_FILE_SIZE
                                            )
                                              ? "#ffffff"
                                              : "#131313",
                                          fontSize: 17,
                                          fontWeight: 800,
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          setFiles(null)
                                          document.getElementById(
                                            "file"
                                          ).value = ""
                                        }}
                                      ></i>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            )}
                            {props.editData &&
                              props.editData.picture &&
                              showEditImage &&
                              files == null && (
                                <Card
                                  className="mt-2 mb-10 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                  style={{
                                    backgroundColor:
                                      props.editData.picture.fileSize >
                                      Number(process.env.REACT_APP_FILE_SIZE)
                                        ? "#ff6a6a"
                                        : "#2FDD92",
                                    color:
                                      props.editData.picture.fileSize >
                                      Number(process.env.REACT_APP_FILE_SIZE)
                                        ? "#ffffff"
                                        : "#131313",
                                    width: "97%",
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                  }}
                                >
                                  <div className="p-2">
                                    <Row className="align-items-center">
                                      <Col className="col-auto">
                                        <img
                                          data-dz-thumbnail=""
                                          height="80"
                                          className="avatar-sm rounded bg-light"
                                          style={{
                                            objectFit: "cover",
                                          }}
                                          alt={
                                            props.editData.picture.originalname
                                          }
                                          src={`${process.env.REACT_APP_IMAGE_URL}/webinar_pictures/${props.editData.picture.filename}`}
                                        />
                                      </Col>
                                      <Col>
                                        <Link
                                          to="#"
                                          style={{
                                            color:
                                              props.editData.picture.fileSize >
                                              Number(
                                                process.env.REACT_APP_FILE_SIZE
                                              )
                                                ? "#ffffff"
                                                : "#131313",
                                          }}
                                        >
                                          {props.editData.picture.originalname}
                                        </Link>
                                        <p className="mb-0">
                                          <strong>
                                            {(
                                              props.editData.picture.fileSize /
                                              1048576
                                            ).toFixed(2) + " MB"}
                                          </strong>
                                        </p>
                                        <i
                                          className="bx bx-no-entry"
                                          style={{
                                            position: "absolute",
                                            top: 0,
                                            right: 10,
                                            color:
                                              props.editData.picture.fileSize >
                                              Number(
                                                process.env.REACT_APP_FILE_SIZE
                                              )
                                                ? "#ffffff"
                                                : "#131313",
                                            fontSize: 17,
                                            fontWeight: 800,
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            setShowEditImage(false)
                                          }}
                                        ></i>
                                      </Col>
                                    </Row>
                                  </div>
                                </Card>
                              )}
                          </div>
                        </Col>
                      </Row>
                      <div>
                        <button
                          type="submit"
                          className="btn btn-primary w-md mt-3"
                        >
                          Submit
                        </button>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </Form>
        </div>
      </React.Fragment>
    </>
  )
}
AddDetails.propTypes = {
  history: PropTypes.object,
  userData: PropTypes.object,
  editData: PropTypes.object,
}

const mapStateToProps = state => {
  return {
    userData: state.userData,
    editData: state.userEdit,
  }
}

export default connect(mapStateToProps)(withRouter(AddDetails))
