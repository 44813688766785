import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
//redux
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import axios from "axios"
import { toast } from "react-toastify"
import Loader from "components/Custom/Loader"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import {
  Row,
  Col,
  Input,
  Label,
  Form,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  Button,
} from "reactstrap"
const AddProfessor = props => {
  const [loader, setLoader] = useState(false)
  const [professorData, setprofessorsData] = useState([])
  const [name, setName] = useState("")
  const [doc, setDoc] = useState(null)
  const [nameErr, setNameErr] = useState("")
  const [fileErr, setFileErr] = useState("")
  const [editDoc, setEditDoc] = useState(null)
  const [editUser, setEditUser] = useState(false)
  const [rowIndexTable, setRowIndexTable] = useState()
  const [professorId, setProfessorId] = useState("")
  const [position, setPosition] = useState("")
  const [positionErr, setPositionErr] = useState("")
  const [description, setDescription] = useState("")
  const [descriptionErr, setDescriptionErr] = useState("")
  useEffect(() => {
    if (props.editData) {
      getProfessorData()
    }
  }, [props.editData])

  const handleSubmit = () => {
    if (name === "") {
      return setNameErr("Please Enter Name")
    } else {
      setNameErr("")
    }
    if (position === "") {
      return setPositionErr("Please Enter position")
    } else {
      setPositionErr("")
    }
    if (description === "") {
      return setDescriptionErr("Please Enter description")
    } else {
      setDescriptionErr("")
    }
    if (!editUser) {
      if (doc == null) {
        return setFileErr("Please upload file")
      } else {
        setFileErr("")
      }
    }

    var data = new FormData()
    data.append("name", name)
    doc !== null && data.append("photo", doc)
    data.append("isCourse", 1)
    data.append("position", position)
    data.append("description", description)
    data.append("course", props.editData ? props.editData._id : props.courseId)
    var config = {
      method: editUser ? "put" : "post",
      url: `${process.env.REACT_APP_BASE_URL}/proffesors/${
        editUser ? professorId : ""
      }`,
      headers: {
        "x-auth-token": props.userData.token,
        "Content-Type": "application/json",
      },
      data: data,
    }
    setLoader(true)
    axios(config)
      .then(function (response) {
        setEditUser(false)
        setEditDoc(null)
        setName("")
        setPosition("")
        setDescription("")
        setDoc(null)
        setLoader(false)
        getProfessorData()
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
      .catch(function (error) {
        console.log(error)
        setLoader(false)
        toast.error(error.response && error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
  }

  const getProfessorData = () => {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/proffesors/${
        props.editData ? props.editData._id : props.courseId
      }/0/1`,
      headers: {
        "x-auth-token": props.userData.token,
        "Content-Type": "application/json",
      },
    }

    axios(config)
      .then(function (response) {
        setprofessorsData(response.data.proffesors)
      })
      .catch(function (error) {
        console.log(error)
      })
  }
  const changeStatus = data => {
    setRowIndexTable(rowIndexTable - 1)
    setLoader(true)
    var config = {
      method: "put",
      url: `${process.env.REACT_APP_BASE_URL}/proffesors/${data._id}/${
        data.isActive ? "0" : "1"
      }`,
      headers: {
        "x-auth-token": props.userData.token,
        "Content-Type": "application/json",
      },
    }

    axios(config)
      .then(function (response) {
        setName("")
        setDoc(null)
        setEditDoc(null)
        setLoader(false)
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
        let values = professorData
        values[professorData.indexOf(data)].isActive = !data.isActive
        setprofessorsData([...values])
      })
      .catch(function (error) {
        console.log(error)
        setLoader(false)
        toast.error(error.response && error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
  }

  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "position",
      text: "Position",
      sort: true,
    },
    {
      dataField: "Description",
      isDummyField: true,
      text: "Description",
      formatter: (cellContent, row, rowIndex) => {
        return (
          <div
          style={{
            width: 200,
            wordBreak: "break-all",
            overflowWrap: "break-word",
            whiteSpace: "break-spaces",
          }}
        >
          <p>{row.description.substring(0, 50) + "..."}</p>
        </div>
        )
      },
    },
    {
      dataField: "df0",
      isDummyField: true,
      text: "Image",
      formatter: (cellContent, row, rowIndex) => {
        return (
          <div
            style={{
              display: "flex",
            }}
          >
            {row.photo && (
              <img
                data-dz-thumbnail=""
                height="80"
                className="avatar-sm rounded bg-light"
                style={{
                  objectFit: "cover",
                }}
                src={`${process.env.REACT_APP_IMAGE_URL}proffesor/${row.photo.filename}`}
              />
            )}
          </div>
        )
      },
    },
    {
      dataField: "isActive",
      text: "Status",
      sort: true,
      hidden: props.userData.isAdmin ? false : true,
      formatter: (cellContent, row, rowIndex, field) => {
        if (row.isActive) {
          return (
            <>
              <Button
                size="sm"
                type="button"
                id={rowIndex === rowIndexTable ? "PopoverLegacy" : ""}
                className="btn btn-success"
              >
                Active
              </Button>
              {rowIndex === rowIndexTable && (
                <UncontrolledPopover
                  placement="top"
                  target="PopoverLegacy"
                  trigger="legacy"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <PopoverHeader style={{ textAlign: "center" }}>
                    Are you sure, you want to deactive This professor?
                  </PopoverHeader>
                  <PopoverBody>
                    <Button
                      size="sm"
                      style={{
                        marginRight: 5,
                      }}
                      onClick={() => changeStatus(row)}
                      color="primary"
                    >
                      Yes
                    </Button>
                    <Button
                      size="sm"
                      style={{
                        marginLeft: 5,
                      }}
                      onClick={() => setRowIndexTable(rowIndexTable - 1)}
                      color="danger"
                    >
                      No
                    </Button>
                  </PopoverBody>
                </UncontrolledPopover>
              )}
            </>
          )
        } else {
          return (
            <>
              {" "}
              <Button
                size="sm"
                type="button"
                id={rowIndex === rowIndexTable ? "PopoverLegacy" : ""}
                className="btn btn-danger"
              >
                Deactive
              </Button>
              {rowIndex === rowIndexTable && (
                <UncontrolledPopover
                  placement="top"
                  target="PopoverLegacy"
                  trigger="legacy"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <PopoverHeader style={{ textAlign: "center" }}>
                    Are you sure, you want to active This professor?
                  </PopoverHeader>
                  <PopoverBody>
                    <Button
                      style={{
                        marginRight: 5,
                      }}
                      size="sm"
                      onClick={() => changeStatus(row)}
                      color="primary"
                    >
                      Yes
                    </Button>
                    <Button
                      style={{
                        marginLeft: 5,
                      }}
                      size="sm"
                      onClick={() => setRowIndexTable(rowIndexTable - 1)}
                      color="danger"
                    >
                      No
                    </Button>
                  </PopoverBody>
                </UncontrolledPopover>
              )}
            </>
          )
        }
      },
      events: {
        onMouseEnter: (e, column, columnIndex, row, rowIndex) => {
          setRowIndexTable(rowIndex)
        },
      },
    },
    {
      dataField: "df1",
      isDummyField: true,
      text: "Action",
      formatter: (cellContent, row, rowIndex) => {
        return (
          <div
            style={{
              display: "flex",
            }}
          >
            <Button
              size="sm"
              type="button"
              className="btn btn-warning"
              onClick={() => {
                setProfessorId(row._id)
                setName(row.name)
                setPosition(row.position)
                setDescription(row.description)
                setEditDoc(row.photo)
                setLoader(true)
                setEditUser(true)
                setTimeout(() => {
                  setLoader(false)
                }, 100)
              }}
            >
              Edit
            </Button>
          </div>
        )
      },
      events: {
        onMouseEnter: (e, column, columnIndex, row, rowIndex) => {
          setRowIndexTable(rowIndex)
        },
      },
    },
  ]

  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange,
  }) => (
    <div className="btn-group" role="group">
      {options.map(option => {
        const isSelect = currSizePerPage === `${option.page}`
        return (
          <button
            key={option.text}
            type="button"
            onClick={() => onSizePerPageChange(option.page)}
            className={`btn ${isSelect ? "btn-secondary" : "btn-primary"}`}
          >
            {option.text}
          </button>
        )
      })}
    </div>
  )

  const defaultSorted = [
    {
      dataField: "name",
      order: "asc",
    },
  ]

  const pageOptions = {
    sizePerPage: 10,
    sizePerPageRenderer,
    totalSize: professorData.length, // replace later with size(customers),
  }
  const { SearchBar } = Search
  if (loader) {
    return <Loader />
  }
  return (
    <>
      <div className="h4 card-title mt-2 mb-4">
        {" "}
        {editUser ? "Edit Professor" : "Add Professor"}
      </div>
      <Form
        className="form-horizontal"
        id="form"
        onSubmit={e => {
          e.preventDefault()
          handleSubmit()
        }}
      >
        <Row>
          <Col lg={4}>
            <div className="mb-3">
              <Label className="form-label">
                Name <span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                name="name"
                id="name"
                className="form-control"
                placeholder="Enter your Name."
                type="text"
                onChange={e => setName(e.target.value)}
                value={name || ""}
              />
              <span style={{ color: "red", fontSize: 10 }}>{nameErr}</span>
            </div>
          </Col>

          <Col lg={4}>
            <div className="mb-3">
              <Label className="form-label">
                Position <span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                name="position"
                id="position"
                className="form-control"
                placeholder="Enter your Position"
                type="text"
                onChange={e => setPosition(e.target.value)}
                value={position || ""}
              />
              <span style={{ color: "red", fontSize: 10 }}>{positionErr}</span>
            </div>
          </Col>

          <Col lg={4}>
            <div className="mb-3">
              <Label className="form-label">
                Description <span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                name="description"
                id="description"
                className="form-control"
                placeholder="Enter your Description"
                type="textarea"
                onChange={e => setDescription(e.target.value)}
                value={description || ""}
              />
              <span style={{ color: "red", fontSize: 10 }}>
                {descriptionErr}
              </span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={2}>
            <div className="mb-3">
              <div
                style={{
                  height: 100,
                  width: 100,
                  backgroundColor: "#f2f2f5",
                  marginBottom: 20,
                  border:
                    doc !== null ? "2px dashed #1D9Bf0" : "2px dashed #1D9Bf0",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <input
                  style={{
                    opacity: 0,
                    height: 100,
                    width: 100,
                    position: "absolute",
                  }}
                  onChange={e => {
                    if (typeof e.target.files[0] !== "undefined") {
                      setEditDoc(null)
                      setDoc(e.target.files[0])
                    }
                  }}
                  type="file"
                />

                {doc !== null || editDoc ? (
                  <img
                    src={
                      editDoc
                        ? `${process.env.REACT_APP_IMAGE_URL}proffesor/${editDoc.filename}`
                        : URL.createObjectURL(doc)
                    }
                    alt="img"
                    style={{
                      height: 98,
                      width: 98,
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <Label
                    style={{
                      textAlign: "center",
                      fontSize: 10,
                      marginTop: 12,
                    }}
                    className="form-label"
                  >
                    Upload Image <span style={{ color: "red" }}>*</span>
                  </Label>
                )}
              </div>
              <span style={{ color: "red", fontSize: 10 }}>{fileErr}</span>
            </div>
          </Col>

          <Col className="mt-4" lg={4}>
            <button
              className="btn btn-primary w-md "
              type="submit"
              style={{ marginBottom: 20 }}
            >
              Submit
            </button>
          </Col>
        </Row>
      </Form>
      <div className="h4 card-title mt-3 ">Professor List</div>
      <div
        className="d-flex flex-column flex-sm-column flex-md-column flex-lg-row"
        style={{ flex: 1, minHeight: "100vh" }}
      >
        <div className="col-sm-12 col-lg-12">
          <PaginationProvider
            pagination={paginationFactory(pageOptions)}
            keyField="name"
            columns={columns}
            data={professorData}
          >
            {({ paginationProps, paginationTableProps }) => (
              <ToolkitProvider
                keyField="name"
                columns={columns}
                data={professorData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-2">
                      <Col
                        md="4"
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          width: "100%",
                        }}
                      >
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <SearchBar {...toolkitProps.searchProps} />
                            <i className="bx bx-search-alt search-icon" />
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col xl="12">
                        <div className="table-responsive">
                          <BootstrapTable
                            responsive
                            bordered={false}
                            striped={true}
                            defaultSorted={defaultSorted}
                            hover
                            classes={"table align-middle table-nowrap"}
                            headerWrapperClasses={"thead-light"}
                            {...toolkitProps.baseProps}
                            {...paginationTableProps}
                          />
                        </div>
                      </Col>
                    </Row>
                  </React.Fragment>
                )}
              </ToolkitProvider>
            )}
          </PaginationProvider>
        </div>
      </div>
    </>
  )
}

AddProfessor.propTypes = {
  history: PropTypes.object,
  userData: PropTypes.object,
  editData: PropTypes.object,
  courseId: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    userData: state.userData,
    editData: state.userEdit,
    courseId: state.courseId,
  }
}

export default connect(mapStateToProps)(withRouter(AddProfessor))
