import { EDIT } from "./actionTypes"

// const Data = JSON.parse(localStorage.getItem("editData"))
const initialState = null;

const userEdit = (state = initialState, action) => {
    switch (action.type) {
        case EDIT:
          return action.payload 
        default:
          return state
      }
    }
   
 export default userEdit