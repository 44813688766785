import PropTypes from "prop-types"
import React, { useState, useEffect, useRef, useCallback } from "react"
import {
  Row,
  Col,
  CardBody,
  FormFeedback,
  Input,
  Label,
  Form,
  CardTitle,
  Button,
  Modal,
  Card,
} from "reactstrap"
//redux
import { useSelector, useDispatch, connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"
// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"
// import images
import axios from "axios"
import LayoutModal from "../../components/Custom/LayoutModel"
import Dropzone from "react-dropzone"
import Multiselect from "multiselect-react-dropdown"
import { toast } from "react-toastify"
import Tostify from "components/Custom/Tostify"
import Loader from "components/Custom/Loader"
import TextField from "@mui/material/TextField"
import LocalizationProvider from "@mui/lab/LocalizationProvider"
import TimePicker from "@mui/lab/TimePicker"
import MomentUtils from "@date-io/moment"
import moment from "moment"
import Selector from "components/Custom/Selector"
import Cropper from "react-easy-crop"
import getCroppedImg from "./getCroppedImg"
import imageCompression from "browser-image-compression"
import { userEdit } from "../../store/actions"
const Directory = props => {
  const dispatch = useDispatch()
  const [loader, setLoader] = useState(false)
  const [dateFrom, setDateFrom] = useState(
    props.editData
      ? props.editData.fromTime
      : moment(new Date()).hour(9).minutes(0).seconds(0)
  )
  const [dateTo, setDateTo] = useState(
    props.editData
      ? props.editData.toTime
      : moment(new Date()).hour(17).minutes(0).seconds(0)
  )
  const [servicesList, setServicesList] = useState(null)
  const [servicesListOld, setServicesListOld] = useState([])
  const [directoriesList, setDirectoriesList] = useState(null)
  const [selectedServices, setSelectedServices] = useState([])
  const [selectedDirectory, setSelectedDirected] = useState([])
  const [city, setCity] = useState(" ")
  const [state, setState] = useState(" ")
  const [toggleSwitch, setToggleSwitch] = useState(true)
  const [modal_xlarge, setmodal_xlarge] = useState(false)
  const [selectedFiles, setselectedFiles] = useState(null)
  const [editSelectedFile, setEditSelectedFiles] = useState(null)
  const [imgSrc, setImgSrc] = useState({ src: "", index: "" })
  const [compressedAllFiles, setCompressAllFiles] = useState(false)
  const [bannerImageFile, setBannerImageFile] = useState()
  const [compressLoader, setCompressLoader] = useState(false)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [serviceErr, setServiceErr] = useState("")
  const [directoryErr, setDirectoryErr] = useState("")
  const [contactErr, setContactErr] = useState(false)
  const [directory, setDirectory] = useState(
    props.editData && props.editData.directoryType._id
      ? props.editData.directoryType._id
      : null
  )
  const multiSelect = useRef(null)
  const [prevService, setPrevService] = useState(
    props.editData && props.editData.services
  )
  const [toggleDummy, setToggleDummy] = useState(false)
  const [states, setStates] = useState(null)
  const [selectedState, setSelectedState] = useState(
    props.editData && props.editData.pin !== null
      ? {
          value: props.editData.pin.state._id,
          label: props.editData.pin.state.name,
        }
      : null
  )
  const [cities, setCities] = useState(null)
  const [selectedCity, setSelectedCity] = useState(
    props.editData && props.editData.pin !== null
      ? {
          value: props.editData.pin.city._id,
          label: props.editData.pin.city.name,
        }
      : null
  )
  const [showEditData, setShowEditData] = useState(true)

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  useEffect(() => {
    var configState = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/states`,
      headers: {
        "x-auth-token": props.userData.token,
      },
    }

    axios(configState)
      .then(function (response) {
        setStates(response.data)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [])

  useEffect(() => {
    setCities([])
    var configCities = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/cities/1`,
      headers: {
        "x-auth-token": props.userData.token,
      },
    }

    axios(configCities)
      .then(function (response) {
        let filterCity = []
        response.data.map((d, i) => {
          if (selectedState.value === d.state._id) {
            filterCity.push(d)
          }
        })
        setCities(filterCity)
      })
      .catch(function (error) {
        // console.log(error)
      })
  }, [selectedState])

  useEffect(() => {
    if (modal_xlarge) {
      setCompressAllFiles(false)
    }
  }, [modal_xlarge])

  const showCroppedImage = useCallback(async () => {
    setCompressLoader(true)

    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    }

    let fileCompressed = selectedFiles !== null ? selectedFiles : []
    try {
      const croppedImage = await getCroppedImg(
        imgSrc.src,
        croppedAreaPixels,
        rotation
      )
      const compressFile = async file => {
        try {
          const data = await imageCompression(file, options).then(f => {
            let file = new File([f], selectedFiles[imgSrc.index].name, {
              type: "image/*",
            })
            Object.assign(f, {
              preview: URL.createObjectURL(f),
              originalPreview: URL.createObjectURL(file),
              formattedSize: formatBytes(f.size),
              originalSize: formatBytes(selectedFiles[imgSrc.index].size),
              name: selectedFiles[imgSrc.index].name,
              filename: selectedFiles[imgSrc.index].name,
              originalname: selectedFiles[imgSrc.index].name,
              compressed: true,
              bannerImage: false,
            })
            fileCompressed.splice(imgSrc.index, 1, f)
            return f
          })
          setselectedFiles([...fileCompressed])
          fileCompressed.length - 1 > Number(imgSrc.index)
            ? setImgSrc({
                src: fileCompressed[Number(imgSrc.index) + 1].preview,
                index: Number(imgSrc.index) + 1,
              })
            : setmodal_xlarge(!modal_xlarge)
          setCompressLoader(false)
          let result = true
          let bannerImageResult = false

          fileCompressed.map((d, i) => {
            if (i < fileCompressed.length - 1) {
              result = d.compressed && fileCompressed[i + 1].compressed
              bannerImageResult =
                d.bannerImage || fileCompressed[i + 1].bannerImage
            }
          })
          setCompressAllFiles(result)
          if (!bannerImageResult) {
            fileCompressed[0].bannerImage = true
            setBannerImageFile(fileCompressed[0])
            setselectedFiles([...fileCompressed])
          }
        } catch {
          console.log("Compress")
        }
      }
      compressFile(croppedImage)
    } catch (e) {
      console.error(e)
    }
  }, [croppedAreaPixels, rotation, imgSrc])

  function tog_xlarge() {
    if (compressedAllFiles) {
      setmodal_xlarge(!modal_xlarge)
    }
  }
  useEffect(() => {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/services`,
      headers: {
        "x-auth-token": props.userData.token,
      },
    }
    var configCity = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/directory_types`,
      headers: {
        "x-auth-token": props.userData.token,
      },
    }
    axios(config)
      .then(function (response) {
        setServicesListOld(response.data)
      })
      .catch(function (error) {
        console.log(error)
      })

    axios(configCity)
      .then(function (response) {
        setDirectoriesList(response.data)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [])

  useEffect(() => {
    let matchServicesWithDir = []
    setSelectedServices([])

    if (props.editData) {
      if (directory !== props.editData.directoryType._id) {
        setPrevService([])
      } else {
        setPrevService(props.editData.services)
        setSelectedServices(props.editData.services)
      }
    } else {
    }

    servicesListOld.map((d, i) => {
      if (props.editData && !toggleDummy) {
        if (d.directoryType._id == props.editData.directoryType._id) {
          matchServicesWithDir.push(d)
        }
      } else {
        if (d.directoryType._id == directory) {
          matchServicesWithDir.push(d)
        }
      }
    })
    setServicesList([...matchServicesWithDir])
    setToggleDummy(true)
  }, [directory, servicesListOld.length])

  useEffect(() => {
    if (props.editData) {
      const data = props.editData
      if (props.editData.pin !== null) {
        setSelectedServices(data.services)
        setSelectedDirected(data.directoryTypes)
        setCity(props.editData.pin.city.name)
        setState(props.editData.pin.state.name)
      } else {
        setCity(" ")
        setState(" ")
      }
      const rows = []
      data.contacts.map((d, i) => {
        Object.assign(d, { id: i + 1 })
        rows.push(d)
      })
      setFormRows(rows)
      if (rows.length > 0) {
        data.contacts.map((d, i) => {
          setTimeout(() => {
            document.getElementById("name" + i).value = d.name
            document.getElementById("email" + i).value = d.email
            document.getElementById("phone" + i).value = d.mobile
            document.getElementById("designation" + i).value = d.designation
          }, 100)
        })
      }

      const files = []
      data.pics.map((d, i) => {
        Object.assign(d, {
          preview: process.env.REACT_APP_IMAGE_URL + "directory/" + d.filename,
          formattedSize: formatBytes(d.fileSize),
          compressed: true,
          name: d.originalname,
        })
        files.push(d)
      })
      setselectedFiles([...files])
      setEditSelectedFiles(files.length)
    }

    return () => dispatch(userEdit(null))
  }, [])

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    // enableReinitialize: true,
    initialValues: {
      name: props.editData ? props.editData.name : "",
      about: props.editData ? props.editData.about : "",
      address: props.editData ? props.editData.address : "",
      pincode:
        props.editData && props.editData.pin !== null
          ? props.editData.pin.pin
          : "",
      gst: props.editData ? props.editData.gst : "",
      companyEmail: props.editData ? props.editData.companyEmail : "",
      mobile: props.editData ? props.editData.mobile : "",
      isVerified: props.editData ? props.editData.isVerified : false,
      isPremium: props.editData ? props.editData.isPremium : false,
      linkedin: props.editData ? props.editData.linkedin : "",
      website: props.editData ? props.editData.website : "",
      glink: props.editData ? props.editData.gMap : "",
      wp: props.editData ? props.editData.whatsapp : "",
    },

    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter item name"),
      about: Yup.string().required("Please Enter Brand"),
      address: Yup.string().required("Please Enter your address"),
      pincode: Yup.string().required("Please Enter pincode"),
      companyEmail: Yup.string()
        .required("Please Enter company Email")
        .email("Please Enter valid company email"),
      mobile: Yup.string()
        .required("Please Enter Mobile / Landline Number")
        .min(10, "Please enter valid Mobile / Landline number")
        .max(16, "Please enter valid Mobile / Landline number"),
      wp: Yup.string()
        .required("Please Enter Whatsapp Number")
        .min(10, "Please enter 10 digit mobile number")
        .max(10, "Please enter 10 digit mobile number"),
    }),

    onSubmit: values => {
      if (
        selectedServices.length > 0 &&
        directory !== null &&
        selectedState !== null &&
        selectedCity !== null
      ) {
        setServiceErr("")
        setDirectoryErr("")
        var data = new FormData()
        data.append("name", values.name)
        data.append("about", values.about)
        selectedServices.map((d, i) => {
          data.append(`services[${i}]`, d._id)
        })
        data.append("directoryType", directory)
        let errorContact = false
        formRows.map((d, i) => {
          data.append(`contacts[${i}][name]`, d.name)
          data.append(`contacts[${i}][email]`, d.email)
          data.append(`contacts[${i}][mobile]`, d.mobile)
          data.append(`contacts[${i}][designation]`, d.designation)
          if (d.mobile.length > 0 && d.mobile.length !== 10) {
            setContactErr(
              (i < d.mobile.length && d.mobile.length !== 10) ||
                formRows[i + 1].mobile.length !== 10
            )
            errorContact =
              (i < d.mobile.length && d.mobile.length !== 10) ||
              formRows[i + 1].mobile.length !== 10
            d.mobileErr = "Please enter 10 digit mobile number"
          } else {
            d.mobileErr = ""
          }
        })
        selectedFiles !== null &&
          selectedFiles.map((d, i) => {
            if (d._id) {
              return
            }
            data.append("pics", d, d.name)
          })

        data.append("address", values.address)
        data.append("companyEmail", values.companyEmail)
        data.append("mobile", values.mobile)
        data.append("whatsapp", values.wp)
        data.append("pin", values.pincode)
        data.append("linkedin", values.linkedin ? values.linkedin : "")
        data.append("website", values.website ? values.website : "")
        data.append("gMap", values.glink ? values.glink : "")
        data.append("fromTime", dateFrom)
        data.append("toTime", dateTo)
        data.append("isPremium", values.isPremium)
        data.append("isVerified", values.isVerified)
        data.append("gst", values.gst ? values.gst : "")
        data.append("city", selectedCity.value)
        data.append("state", selectedState.value)

        var config = {
          method: props.editData ? "put" : "post",
          url: `${process.env.REACT_APP_BASE_URL}/directories${
            props.editData ? "/" + props.editData._id : ""
          }`,
          headers: {
            "Content-Type": "multipart/form-data",
            "x-auth-token": props.userData.token,
          },
          data: data,
        }

        if (!errorContact) {
          setLoader(true)
          axios(config)
            .then(function (response) {
              setLoader(false)
              setTimeout(() => {
                toast.success(response.data.message, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                })
              }, 200)
              props.history.push("/directory-list")
            })
            .catch(function (error) {
              console.log(error)
              setLoader(false)
              toast.error(error.response && error.response.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              })
            })
        } else {
          toast.error(
            "Please enter 10 digit mobile number in Contact Details",
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            }
          )
        }
      } else {
        if (selectedServices.length < 1) {
          setServiceErr("Please select atleast one service")
          toast.error("Please select atleast one service", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        } else {
          setServiceErr("")
        }
        if (directory == null) {
          setDirectoryErr("Please select directory")
          toast.error("Please select atleast one directory", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        } else {
          setDirectoryErr("")
        }

        if (selectedState == null) {
          toast.error("Please select State", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        }

        if (selectedCity == null) {
          toast.error("Please select City", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        }
      }
    },
  })

  const { forgetError, forgetSuccessMsg } = useSelector(state => ({
    forgetError: state.ForgetPassword.forgetError,
    forgetSuccessMsg: state.ForgetPassword.forgetSuccessMsg,
  }))

  function handleAcceptedFiles(files) {
    const options = {
      maxSizeMB: 1.5,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    }

    const filesLength = Number(files.length)
    const prevFilesLength = Number(
      selectedFiles !== null ? selectedFiles.length : 0
    )
    if (filesLength + prevFilesLength > 5) {
      toast.error("Upload atmost 5 images", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      })
      setmodal_xlarge(false)
      return
    }

    try {
      let fileCompressed = []

      if (selectedFiles !== null && selectedFiles.length > 0) {
        files.map(file => {
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            originalPreview: URL.createObjectURL(file),
            formattedSize: formatBytes(file.size),
            originalSize: formatBytes(file.size),
            compressed: false,
            bannerImage: false,
          })
          selectedFiles.unshift(file)
          fileCompressed = selectedFiles
          setselectedFiles([...selectedFiles])

          setImgSrc({ src: selectedFiles[0].preview, index: 0 })
        })
      } else {
        files.map(file => {
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            originalPreview: URL.createObjectURL(file),
            formattedSize: formatBytes(file.size),
            originalSize: formatBytes(file.size),
            compressed: false,
            bannerImage: false,
          })
        })
        fileCompressed = files
        setselectedFiles(files)
        setImgSrc({ src: files[0].preview, index: 0 })
      }
    } catch {
      setmodal_xlarge(false)
      toast.error("Only Jpeg and PNG Images are supported", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      })
    }
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }
  const [formRows, setFormRows] = useState([
    {
      id: 1,
      name: "",
      email: "",
      designation: "",
      mobile: "",
      whatsapp: "",
      mobileErr: "",
      error: false,
    },
  ])

  const onAddFormRow = () => {
    const modifiedRows = [...formRows]
    modifiedRows.push({
      id: modifiedRows.length + 1,
      name: "",
      email: "",
      designation: "",
      mobile: "",
      whatsapp: "",
      mobileErr: "",
      error: false,
    })
    setFormRows(modifiedRows)
  }
  const onDeleteFormRow = id => {
    if (id !== 1) {
      var modifiedRows = [...formRows]
      modifiedRows = modifiedRows.filter(x => x["id"] !== id)
      setFormRows(modifiedRows)
    }
  }
  const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        No
      </div>
    )
  }

  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        Yes
      </div>
    )
  }

  const toggle = () => {
    setToggleSwitch(false)
    setTimeout(() => {
      setToggleSwitch(true)
    }, 1)
  }

  let demoData = [
    { _id: "5445546", name: "demo", type: "Admin" },
    { _id: "54455556", name: "DD", type: "Operator" },
  ]

  if (loader) {
    return <Loader />
  }

  return (
    <>
      <Tostify />
      <LayoutModal
        size={8}
        button={true}
        form={true}
        title={props.editData ? "Edit Directory" : "Add Directory"}
        btnTitle={props.editData ? "Add Directory" : "Directory List"}
        onclick={() => {
          props.editData
            ? window.location.reload()
            : props.history.push("/directory-list")
        }}
      >
        <Form
          className="form-horizontal"
          id="form"
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >
          <div className="mb-3">
            <Label className="form-label">
              Name <span style={{ color: "red" }}>*</span>
            </Label>
            <Input
              name="name"
              id="name"
              className="form-control"
              placeholder="Enter your Item Name"
              type="text"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.name || ""}
              invalid={
                validation.touched.name && validation.errors.name ? true : false
              }
            />
            {validation.touched.name && validation.errors.name ? (
              <FormFeedback type="invalid">
                {validation.errors.name}
              </FormFeedback>
            ) : null}
          </div>
          <Row className="mb-3">
            <Col md={6}>
              <div className="mb-3">
                <Label className="form-label">
                  About <span style={{ color: "red" }}>*</span>
                </Label>
                <Input
                  name="about"
                  id="about"
                  className="form-control"
                  placeholder="Enter about yourself"
                  type="textarea"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.about || ""}
                  invalid={
                    validation.touched.about && validation.errors.about
                      ? true
                      : false
                  }
                />
                {validation.touched.about && validation.errors.about ? (
                  <FormFeedback type="invalid">
                    {validation.errors.about}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-3">
                <Label className="form-label">
                  Address <span style={{ color: "red" }}>*</span>
                </Label>
                <Input
                  name="address"
                  id="address"
                  className="form-control"
                  placeholder="Enter your Description"
                  type="textarea"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.address || ""}
                  invalid={
                    validation.touched.address && validation.errors.address
                      ? true
                      : false
                  }
                />
                {validation.touched.address && validation.errors.address ? (
                  <FormFeedback type="invalid">
                    {validation.errors.address}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={6}>
              <div style={{ zIndex: 999 }}>
                <Selector
                  label="Directory Type"
                  required
                  data={directoriesList !== null ? directoriesList : []}
                  onchange={event => {
                    setDirectory(event.value)
                    multiSelect.current.state.selectedValues = []
                  }}
                  edit={
                    props.editData && {
                      label: props.editData.directoryType.name,
                      value: props.editData.directoryType._id,
                    }
                  }
                />
                <p style={{ color: "red", fontSize: 11 }}>{directoryErr}</p>
              </div>
            </Col>

            <Col md={6}>
              <div>
                <Label for="services">
                  Services <span style={{ color: "red" }}>*</span>
                </Label>
                <Multiselect
                  id="services"
                  ref={multiSelect}
                  style={{
                    chips: {
                      color: "#fff",
                    },
                  }}
                  options={servicesList !== null ? servicesList : []} // Options to display in the dropdown
                  selectedValues={prevService} // Preselected value to persist in dropdown
                  onSelect={event => setSelectedServices(event)}
                  // onSelect={this.onSelect} // Function will trigger on select event
                  onRemove={e => setSelectedServices(e)} // Function will trigger on remove event
                  displayValue="name" // Property name to display in the dropdown options
                />

                <p style={{ color: "red", fontSize: 11 }}>{serviceErr}</p>
              </div>
            </Col>
          </Row>

          <Row
            className="mb-3"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Col
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: 25,
              }}
              md={8}
            >
              <Col md={5}>
                <LocalizationProvider dateAdapter={MomentUtils}>
                  <TimePicker
                    label="From"
                    id="fromTime"
                    value={dateFrom}
                    onChange={e => {
                      const from = moment(e)
                      setDateFrom(from)
                    }}
                    renderInput={params => (
                      <TextField
                        style={{ zIndex: 0 }}
                        size="small"
                        variant="outlined"
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Col>
              <Col md={5}>
                <LocalizationProvider dateAdapter={MomentUtils}>
                  <TimePicker
                    label="To"
                    id="toTime"
                    value={dateTo}
                    onChange={e => {
                      const to = moment(e)
                      setDateTo(to)
                    }}
                    renderInput={params => (
                      <TextField
                        style={{ zIndex: 0 }}
                        size="small"
                        variant="outlined"
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Col>
            </Col>
            <Col md={4}>
              <Label className="form-label">GST</Label>
              <Input
                name="gst"
                id="gst"
                className="form-control"
                placeholder="Enter about yourself"
                type="text"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.gst || ""}
                invalid={
                  validation.touched.gst && validation.errors.gst ? true : false
                }
              />
              {validation.touched.gst && validation.errors.gst ? (
                <FormFeedback type="invalid">
                  {validation.errors.gst}
                </FormFeedback>
              ) : null}
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={4}>
              <div className="mb-3">
                <Label className="form-label">
                  Company Email <span style={{ color: "red" }}>*</span>
                </Label>
                <Input
                  name="companyEmail"
                  id="companyEmail"
                  className="form-control"
                  placeholder="Enter your Company Email"
                  type="email"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.companyEmail || ""}
                  invalid={
                    validation.touched.companyEmail &&
                    validation.errors.companyEmail
                      ? true
                      : false
                  }
                />
                {validation.touched.companyEmail &&
                validation.errors.companyEmail ? (
                  <FormFeedback type="invalid">
                    {validation.errors.companyEmail}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col md={4}>
              <div className="mb-3">
                <Label className="form-label">
                  Mobile / Landline <span style={{ color: "red" }}>*</span>
                </Label>
                <Input
                  name="mobile"
                  id="mobile"
                  className="form-control"
                  placeholder="Enter your Mobile / Landline Number"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.mobile || ""}
                  invalid={
                    validation.touched.mobile && validation.errors.mobile
                      ? true
                      : false
                  }
                  onKeyPress={event => {
                    if (
                      !/[0-9]/.test(event.key) ||
                      event.target.value.length > 15
                    ) {
                      event.preventDefault()
                    }
                  }}
                />
                {validation.touched.mobile && validation.errors.mobile ? (
                  <FormFeedback type="invalid">
                    {validation.errors.mobile}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col md={4}>
              <div className="mb-3">
                <Label className="form-label">
                  Whatsapp <span style={{ color: "red" }}>*</span>
                </Label>
                <Input
                  name="wp"
                  id="wp"
                  className="form-control"
                  placeholder="Enter your Whatsapp Number"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.wp || ""}
                  invalid={
                    validation.touched.wp && validation.errors.wp ? true : false
                  }
                  onKeyPress={event => {
                    if (
                      !/[0-9]/.test(event.key) ||
                      event.target.value.length > 9
                    ) {
                      event.preventDefault()
                    }
                  }}
                />
                {validation.touched.wp && validation.errors.wp ? (
                  <FormFeedback type="invalid">
                    {validation.errors.wp}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={4}>
              <Label className="form-label">
                Pincode <span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                name="pincode"
                id="pincode"
                className="form-control"
                placeholder="Enter your pincode"
                type="text"
                onPaste={e => {
                  e.preventDefault()
                  return false
                }}
                onKeyPress={event => {
                  if (
                    !/[0-9]/.test(event.key) ||
                    event.target.value.length > 5
                  ) {
                    event.preventDefault()
                  }
                }}
                onChange={e => {
                  validation.handleChange(e)

                  var config = {
                    method: "get",
                    url: `${process.env.REACT_APP_BASE_URL}/pincodes/${e.target.value}`,
                    headers: {
                      "x-auth-token": props.userData.token,
                    },
                  }

                  axios(config)
                    .then(function (response) {
                      if (response.data === "") {
                        setCities([])
                        setShowEditData(false)
                        e.target.value.length > 5 ? setCity("") : setCity(" ")
                        e.target.value.length > 5 ? setState("") : setState(" ")
                      } else {
                        setCity(response.data.city.name)
                        setState(response.data.state.name)
                        setSelectedState({
                          value: response.data.state._id,
                          label: response.data.state.name,
                        })
                        setSelectedCity({
                          value: response.data.city._id,
                          label: response.data.city.name,
                        })
                      }
                    })
                    .catch(function (error) {
                      console.log(error)
                    })
                }}
                onBlur={validation.handleBlur}
                value={validation.values.pincode || ""}
                invalid={
                  validation.touched.pincode && validation.errors.pincode
                    ? true
                    : false
                }
              />
              {validation.touched.pincode && validation.errors.pincode ? (
                <FormFeedback type="invalid">
                  {validation.errors.pincode}
                </FormFeedback>
              ) : null}
            </Col>
            <Col md={4}>
              {state !== "" ? (
                <>
                  <Label className="form-label">
                    State <span style={{ color: "red" }}>*</span>
                  </Label>
                  <Input
                    name="city"
                    disabled
                    id="city"
                    className="form-control"
                    placeholder={state}
                    type="text"
                  />
                </>
              ) : (
                <Selector
                  label="States"
                  required
                  data={states !== null ? states : []}
                  onchange={event => setSelectedState(event)}
                  edit={
                    props.editData !== null &&
                    props.editData.pin !== null &&
                    showEditData && {
                      label: props.editData.pin.state.name,
                      value: props.editData.pin.state._id,
                    }
                  }
                />
              )}
            </Col>
            <Col md={4}>
              {city !== "" ? (
                <>
                  <Label className="form-label">
                    City <span style={{ color: "red" }}>*</span>
                  </Label>
                  <Input
                    name="city"
                    disabled
                    id="city"
                    className="form-control"
                    placeholder={city}
                    type="text"
                  />
                </>
              ) : (
                <Selector
                  label="Cities"
                  required
                  data={cities !== null ? cities : []}
                  onchange={event => setSelectedCity(event)}
                  edit={
                    props.editData !== null &&
                    props.editData.pin !== null &&
                    showEditData && {
                      label: props.editData.pin.city.name,
                      value: props.editData.pin.city._id,
                    }
                  }
                />
              )}
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={4}>
              <Label className="form-label">Linkedin</Label>
              <Input
                name="linkedin"
                id="linkedin"
                className="form-control"
                placeholder="Enter your linkedin url"
                type="text"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.linkedin || ""}
                invalid={
                  validation.touched.linkedin && validation.errors.linkedin
                    ? true
                    : false
                }
              />
              {validation.touched.linkedin && validation.errors.linkedin ? (
                <FormFeedback type="invalid">
                  {validation.errors.linkedin}
                </FormFeedback>
              ) : null}
            </Col>
            <Col md={4}>
              <Label className="form-label">Website</Label>
              <Input
                name="website"
                id="website"
                className="form-control"
                placeholder="Ex https://example.com"
                type="text"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.website || ""}
                invalid={
                  validation.touched.website && validation.errors.website
                    ? true
                    : false
                }
              />
              {validation.touched.website && validation.errors.website ? (
                <FormFeedback type="invalid">
                  {validation.errors.website}
                </FormFeedback>
              ) : null}
            </Col>
            <Col md={4}>
              <Label className="form-label">Google Maps Link</Label>
              <Input
                name="glink"
                id="glink"
                className="form-control"
                placeholder="Enter your google maps link"
                type="text"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.glink || ""}
                invalid={
                  validation.touched.glink && validation.errors.glink
                    ? true
                    : false
                }
              />
              {validation.touched.glink && validation.errors.glink ? (
                <FormFeedback type="invalid">
                  {validation.errors.glink}
                </FormFeedback>
              ) : null}
            </Col>
          </Row>
          <div className="mb-3">
            <Dropzone
              accept="image/png, image/jpeg"
              onDrop={acceptedFiles => {
                if (acceptedFiles.length < 6) {
                  setmodal_xlarge(true)
                  handleAcceptedFiles(acceptedFiles)
                } else {
                  toast.error("Select atmost 5 Images", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                  })
                }
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <div className="dropzone">
                  <div
                    className="dz-message needsclick mt-2"
                    {...getRootProps()}
                  >
                    <input {...getInputProps()} />
                    <div className="mb-3">
                      <i className="display-4 text-muted bx bxs-cloud-upload" />
                    </div>
                    <h4>Drop files here or click to upload.</h4>
                  </div>
                </div>
              )}
            </Dropzone>
            <div className="dropzone-previews mt-3" id="file-previews">
              {selectedFiles !== null &&
                selectedFiles.map((f, i) => {
                  return (
                    f.compressed && (
                      <Card
                        className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                        key={i + "-file"}
                      >
                        <div className="p-2">
                          <Row className="align-items-center">
                            <Col className="col-auto">
                              <img
                                className="avatar-sm rounded bg-light"
                                style={{
                                  objectFit: "contain",
                                  height: 70,
                                  width: 130,
                                }}
                                alt={f.name}
                                src={f.preview}
                              />
                            </Col>
                            <Col>
                              <Link
                                to="#"
                                className="text-muted font-weight-bold"
                              >
                                {f.name}
                              </Link>
                              <p className="mb-0">
                                <strong>{f.formattedSize}</strong>
                              </p>
                              <i
                                className="bx bx-no-entry"
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  right: 10,
                                  fontSize: 17,
                                  fontWeight: 800,
                                  cursor: "pointer",
                                }}
                                onClick={e => {
                                  e.stopPropagation()
                                  let data = selectedFiles.splice(i, 1)

                                  if (
                                    selectedFiles.length > 0 &&
                                    i == 0 &&
                                    data[0].bannerImage == true
                                  ) {
                                    selectedFiles[0].bannerImage = true
                                    setBannerImageFile(selectedFiles[0])
                                  }
                                  if (
                                    selectedFiles.length > 0 &&
                                    i > 0 &&
                                    data[0].bannerImage == true
                                  ) {
                                    selectedFiles[i - 1].bannerImage = true
                                    setBannerImageFile(selectedFiles[i - 1])
                                  }
                                  setselectedFiles([...selectedFiles])

                                  if (props.editData && f._id) {
                                    var dataa = JSON.stringify({
                                      dirId:
                                        props.editData && props.editData._id,
                                      picId: f._id,
                                    })

                                    var config = {
                                      method: "put",
                                      url: `${process.env.REACT_APP_BASE_URL}/directories/removeImage`,
                                      headers: {
                                        "Content-Type": "application/json",
                                        "x-auth-token": props.userData.token,
                                      },
                                      data: dataa,
                                    }

                                    axios(config)
                                      .then(function (response) {
                                        toast.success(response.data.message, {
                                          position: "top-right",
                                          autoClose: 5000,
                                          hideProgressBar: false,
                                          closeOnClick: true,
                                          pauseOnHover: true,
                                          draggable: true,
                                        })
                                      })
                                      .catch(function (error) {
                                        console.log(error)
                                        toast.error(
                                          error.response &&
                                            error.response.data.message,
                                          {
                                            position: "top-right",
                                            autoClose: 5000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: true,
                                          }
                                        )
                                      })
                                  }
                                }}
                              ></i>
                            </Col>
                          </Row>
                        </div>
                      </Card>
                    )
                  )
                })}
            </div>
          </div>

          <Row>
            <Col xs={12}>
              <CardBody>
                <CardTitle className="mb-4">Contact Details</CardTitle>
                <div>
                  {(formRows || []).map((formRow, key) => (
                    <Row key={key}>
                      <Col lg={3} className="mb-3">
                        <label htmlFor="name">Name</label>
                        <Input
                          type="text"
                          id={"name" + key}
                          className="form-control"
                          placeholder="Your Name"
                          onChange={e => (formRow.name = e.target.value)}
                        />
                      </Col>

                      <Col lg={3} className="mb-3">
                        <label htmlFor="about">Email</label>
                        <Input
                          type="email"
                          id={"email" + key}
                          className="form-control"
                          placeholder="Email Address"
                          onChange={e => (formRow.email = e.target.value)}
                        />
                      </Col>

                      <Col lg={2} className="mb-3">
                        <label htmlFor="about">Mobile</label>
                        <Input
                          type="phone"
                          id={"phone" + key}
                          className="form-control"
                          placeholder="Mobile"
                          onChange={e => (formRow.mobile = e.target.value)}
                          onKeyPress={event => {
                            if (
                              !/[0-9]/.test(event.key) ||
                              event.target.value.length > 15
                            ) {
                              event.preventDefault()
                            }
                          }}
                        />
                        <span style={{ fontSize: 8, color: "red" }}>
                          {formRow.mobileErr}
                        </span>
                      </Col>

                      <Col lg={3} className="mb-3">
                        <label style={{ marginRight: 10 }} htmlFor="subject">
                          Designation
                        </label>
                        <Input
                          type="text"
                          id={"designation" + key}
                          className="form-control"
                          disabled={formRow.sameAsContact}
                          placeholder="Designation"
                          onChange={e => (formRow.designation = e.target.value)}
                        />
                      </Col>

                      {formRow.id !== 1 && (
                        <Col lg={1} className="align-self-center">
                          <div className="d-grid">
                            <button
                              type="button"
                              className="btn btn-danger"
                              style={{ marginTop: 10 }}
                              onClick={() => onDeleteFormRow(formRow.id)}
                            >
                              {" "}
                              <i className="bx bx-trash"></i>{" "}
                            </button>
                          </div>
                        </Col>
                      )}
                    </Row>
                  ))}
                </div>
                <button
                  type="button"
                  className="btn btn-success mt-3 mt-lg-0"
                  onClick={() => onAddFormRow()}
                >
                  {" "}
                  <i className="bx bx-plus"></i>
                </button>
              </CardBody>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                alignContent: "center",
                marginLeft: 20,
              }}
              md={4}
            >
              <Input
                name="isVerified"
                id="isVerified"
                className="form-control"
                defaultChecked={props.editData && props.editData.isVerified}
                type="checkbox"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.linkedin || ""}
              />
              <Label
                className="form-label"
                style={{ marginTop: 12, marginLeft: 10 }}
              >
                Verified
              </Label>
            </Col>

            <Col
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                alignContent: "center",
                marginLeft: 20,
              }}
              md={4}
            >
              <Input
                name="isPremium"
                id="isPremium"
                className="form-control"
                defaultChecked={props.editData && props.editData.isPremium}
                type="checkbox"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.linkedin || ""}
              />
              <Label
                className="form-label"
                style={{ marginTop: 12, marginLeft: 10 }}
              >
                Premium
              </Label>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col className="text-start">
              <button
                className="btn btn-primary w-md "
                type="submit"
                style={{ marginBottom: 20 }}
              >
                Submit
              </button>
            </Col>
          </Row>
        </Form>
      </LayoutModal>

      <Modal
        size="xl"
        isOpen={modal_xlarge}
        toggle={() => {
          tog_xlarge()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">
            Crop & Compress Images
          </h5>
          {compressedAllFiles && (
            <button
              onClick={() => {
                if (compressedAllFiles) {
                  setmodal_xlarge(false)
                }
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          )}
        </div>
        <div
          className="modal-body"
          style={{
            height: 500,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {compressLoader ? (
            <Loader />
          ) : (
            <Cropper
              image={imgSrc.src}
              crop={crop}
              zoom={zoom}
              aspect={16 / 9}
              onCropChange={c => {
                setCrop(c)
              }}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          )}
        </div>

        <Button color="primary" onClick={showCroppedImage}>
          Submit
        </Button>

        <div
          className="modal-footer"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          {selectedFiles !== null &&
            selectedFiles.map((f, i) => {
              return (
                <Card
                  className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                  key={i + "-file"}
                >
                  <div
                    className="p-2"
                    style={{
                      border: !f.compressed
                        ? "2px solid #FF6A6A"
                        : "2px solid #2FDD92",
                      borderRadius: 15,
                    }}
                  >
                    <Row className="align-items-center">
                      <Col className="col-auto">
                        <img
                          onClick={() =>
                            setImgSrc({ src: f.preview, index: i })
                          }
                          data-dz-thumbnail=""
                          height="80"
                          className="avatar-sm rounded bg-light"
                          style={{
                            objectFit: "cover",
                          }}
                          alt={f.name}
                          src={f.preview}
                        />
                      </Col>
                    </Row>
                  </div>
                </Card>
              )
            })}
        </div>
      </Modal>
    </>
  )
}

Directory.propTypes = {
  history: PropTypes.object,
  userData: PropTypes.object,
  editData: PropTypes.object,
}

const mapStateToProps = state => {
  return {
    userData: state.userData,
    editData: state.userEdit,
  }
}

export default connect(mapStateToProps)(withRouter(Directory))
