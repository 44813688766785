import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import ReactStars from "react-rating-stars-component"
//redux
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import axios from "axios"
import { toast } from "react-toastify"
import Loader from "components/Custom/Loader"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import {
  Row,
  Col,
  Input,
  Label,
  Form,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  Button,
} from "reactstrap"

const AddReviews = props => {
  const [loader, setLoader] = useState(false)
  const [name, setName] = useState("")
  const [nameErr, setNameErr] = useState("")
  const [position, setPosition] = useState("")
  const [positionErr, setPositionErr] = useState("")
  const [review, setReview] = useState("")
  const [reviewErr, setReviewErr] = useState("")
  const [rating, setRating] = useState(0)
  const [ratingErr, setRatingErr] = useState("")
  const [reviewsData, setReviewsData] = useState([])
  const [rowIndexTable, setRowIndexTable] = useState([])
  const [reviewId, setReviewId] = useState("")
  const [editUser, setEditUser] = useState(false)

  useEffect(() => {
    if (props.editData) {
      getReviewData()
    }
  }, [props.editData])

  const getReviewData = () => {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/reviews/${
        props.editData ? props.editData._id : props.courseId
      }/0`,
      headers: {
        "x-auth-token": props.userData.token,
        "Content-Type": "application/json",
      },
    }
    axios(config)
      .then(function (response) {
        setReviewsData(response.data.reviews)
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const handleSubmit = () => {
    if (name == "") {
      return setNameErr("Please enter name")
    } else {
      setNameErr("")
    }
    if (position == "") {
      return setPositionErr("Please enter your position")
    } else {
      setPositionErr("")
    }
    if (review == "") {
      return setReviewErr("Please enter your review")
    } else {
      setReviewErr("")
    }
    if (rating == "") {
      return setRatingErr("Please enter rating")
    } else {
      setRatingErr("")
    }

    var data = JSON.stringify({
      name: name,
      review: review,
      position: position,
      rating: rating,
      course: props.courseId ? props.courseId : props.editData._id,
    })

    var config = {
      method: editUser ? "put" : "post",
      url: `${process.env.REACT_APP_BASE_URL}/reviews/${
        editUser ? reviewId : ""
      }`,
      headers: {
        "x-auth-token": props.userData.token,
        "Content-Type": "application/json",
      },
      data: data,
    }
    setLoader(true)

    axios(config)
      .then(function (response) {
        setEditUser(false)
        getReviewData()
        setName("")
        setPosition("")
        setReview("")
        setTimeout(() => {
          setLoader(false)
        }, 200)
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
      .catch(function (error) {
        console.log(error)
        setLoader(false)
        toast.error(error.response && error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
  }

  const changeStatus = data => {
    setRowIndexTable(rowIndexTable - 1)
    setLoader(true)

    var config = {
      method: "put",
      url: `${process.env.REACT_APP_BASE_URL}/reviews/${data._id}/${
        data.isActive ? "0" : "1"
      }`,
      headers: {
        "x-auth-token": props.userData.token,
        "Content-Type": "application/json",
      },
    }

    axios(config)
      .then(function (response) {
        setName("")
        setPosition("")
        setReview(0)
        setLoader(false)
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
        let values = reviewsData
        values[reviewsData.indexOf(data)].isActive = !data.isActive
        setReviewsData([...values])
      })
      .catch(function (error) {
        console.log(error)
        setLoader(false)
        toast.error(error.response && error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
  }

  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "position",
      text: "Position",
      sort: true,
    },
    {
      dataField: "review",
      text: "Review",
      sort: true,
    },
    {
      dataField: "df2",
      isDummyField: true,
      text: "Rating",
      disabled: true,
      formatter: (cellContent, row, rowIndex) => {
        return (
          <div
            id="rating"
            style={{
              display: "flex",
            }}
          >
            <ReactStars
              count={5}
              edit={false}
              value={Number(row.rating)}
              size={15}
              isHalf={true}
              emptyIcon={<i className="far fa-star"></i>}
              halfIcon={<i className="fa fa-star-half-alt"></i>}
              fullIcon={<i className="fa fa-star"></i>}
              activeColor="#ffd700"
            />
            <p
              style={{
                marginLeft: 5,
              }}
            >
              ({row.rating})
            </p>
          </div>
        )
      },
      events: {
        onMouseEnter: (e, column, columnIndex, row, rowIndex) => {
          setRowIndexTable(rowIndex)
        },
      },
    },
    {
      dataField: "isActive",
      text: "Status",
      sort: true,
      hidden: props.userData.isAdmin ? false : true,
      formatter: (cellContent, row, rowIndex, field) => {
        if (row.isActive) {
          return (
            <>
              <Button
                size="sm"
                type="button"
                id={rowIndex === rowIndexTable ? "PopoverLegacy" : ""}
                className="btn btn-success"
              >
                Active
              </Button>
              {rowIndex === rowIndexTable && (
                <UncontrolledPopover
                  placement="top"
                  target="PopoverLegacy"
                  trigger="legacy"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <PopoverHeader style={{ textAlign: "center" }}>
                    Are you sure, you want to deactive This review?
                  </PopoverHeader>
                  <PopoverBody>
                    <Button
                      size="sm"
                      style={{
                        marginRight: 5,
                      }}
                      onClick={() => changeStatus(row)}
                      color="primary"
                    >
                      Yes
                    </Button>
                    <Button
                      size="sm"
                      style={{
                        marginLeft: 5,
                      }}
                      onClick={() => setRowIndexTable(rowIndexTable - 1)}
                      color="danger"
                    >
                      No
                    </Button>
                  </PopoverBody>
                </UncontrolledPopover>
              )}
            </>
          )
        } else {
          return (
            <>
              {" "}
              <Button
                size="sm"
                type="button"
                id={rowIndex === rowIndexTable ? "PopoverLegacy" : ""}
                className="btn btn-danger"
              >
                Deactive
              </Button>
              {rowIndex === rowIndexTable && (
                <UncontrolledPopover
                  placement="top"
                  target="PopoverLegacy"
                  trigger="legacy"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <PopoverHeader style={{ textAlign: "center" }}>
                    Are you sure, you want to active This review?
                  </PopoverHeader>
                  <PopoverBody>
                    <Button
                      style={{
                        marginRight: 5,
                      }}
                      size="sm"
                      onClick={() => changeStatus(row)}
                      color="primary"
                    >
                      Yes
                    </Button>
                    <Button
                      style={{
                        marginLeft: 5,
                      }}
                      size="sm"
                      onClick={() => setRowIndexTable(rowIndexTable - 1)}
                      color="danger"
                    >
                      No
                    </Button>
                  </PopoverBody>
                </UncontrolledPopover>
              )}
            </>
          )
        }
      },
      events: {
        onMouseEnter: (e, column, columnIndex, row, rowIndex) => {
          setRowIndexTable(rowIndex)
        },
      },
    },
    {
      dataField: "df1",
      isDummyField: true,
      text: "Action",
      formatter: (cellContent, row, rowIndex) => {
        return (
          <div
            style={{
              display: "flex",
            }}
          >
            <Button
              size="sm"
              type="button"
              className="btn btn-warning"
              onClick={() => {
                setLoader(true)
                setEditUser(true)
                setReviewId(row._id)
                setName(row.name)
                setPosition(row.position)
                setReview(row.review)
                setRating(row.rating)
                setTimeout(() => {
                  setLoader(false)
                }, 100)
              }}
            >
              Edit
            </Button>
          </div>
        )
      },
      events: {
        onMouseEnter: (e, column, columnIndex, row, rowIndex) => {
          setRowIndexTable(rowIndex)
        },
      },
    },
  ]

  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange,
  }) => (
    <div className="btn-group" role="group">
      {options.map(option => {
        const isSelect = currSizePerPage === `${option.page}`
        return (
          <button
            key={option.text}
            type="button"
            onClick={() => onSizePerPageChange(option.page)}
            className={`btn ${isSelect ? "btn-secondary" : "btn-primary"}`}
          >
            {option.text}
          </button>
        )
      })}
    </div>
  )

  const defaultSorted = [
    {
      dataField: "name",
      order: "asc",
    },
  ]

  const pageOptions = {
    sizePerPage: 25,
    sizePerPageRenderer,
    totalSize: reviewsData.length, // replace later with size(customers),
  }
  const { SearchBar } = Search
  if (loader) {
    return <Loader />
  }

  return (
    <>
      <div className="h4 card-title mt-2 mb-4">
        {editUser ? "Edit Review" : "Add Review"}
      </div>
      <Form
        className="form-horizontal"
        id="form"
        onSubmit={e => {
          e.preventDefault()
          handleSubmit()
          return false
        }}
      >
        <Row>
          <Col lg={3}>
            <div className="mb-3">
              <Label className="form-label">
                Name <span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                name="name"
                id="name"
                className="form-control"
                placeholder="Enter your Name."
                type="text"
                onChange={e => setName(e.target.value)}
                value={name || ""}
              />
              {<span style={{ color: "red", fontSize: 10 }}>{nameErr}</span>}
            </div>
          </Col>
          <Col lg={3}>
            <div className="mb-3">
              <Label className="form-label">
                Position <span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                name="position"
                id="position"
                className="form-control"
                placeholder="Enter your Position."
                type="text"
                onChange={e => setPosition(e.target.value)}
                value={position || ""}
              />
              <span style={{ color: "red", fontSize: 10 }}>{positionErr}</span>
            </div>
          </Col>

          <Col lg={3}>
            <div className="mb-3">
              <Label htmlFor="formrow-email-Input">
                Review <span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                name="review"
                id="review"
                className="form-control"
                placeholder="Enter your Review."
                type="text"
                onChange={e => setReview(e.target.value)}
                value={review || ""}
              />
              <span style={{ color: "red", fontSize: 10 }}>{reviewErr}</span>
            </div>
          </Col>

          <Col lg={3}>
            <div className="mb-3">
              <Label htmlFor="formrow-email-Input">
                Rating <span style={{ color: "red" }}>*</span>
              </Label>
              <ReactStars
                count={5}
                id="ratingstart"
                onChange={e => setRating(e)}
                value={editUser ? Number(rating) : 0}
                size={30}
                isHalf={true}
                emptyIcon={<i className="far fa-star"></i>}
                halfIcon={<i className="fa fa-star-half-alt"></i>}
                fullIcon={<i className="fa fa-star"></i>}
                activeColor="#ffd700"
              />
              <span style={{ color: "red", fontSize: 10 }}>{ratingErr}</span>
            </div>
          </Col>
        </Row>
        <Row>
          <Row className="mb-3">
            <Col className="text-start">
              <button
                className="btn btn-primary w-md "
                type="submit"
                style={{ marginBottom: 20 }}
              >
                Submit
              </button>
            </Col>
          </Row>
        </Row>
      </Form>
      <div
        className="d-flex flex-column flex-sm-column flex-md-column flex-lg-row"
        style={{ flex: 1, minHeight: "100vh" }}
      >
        <div className="col-sm-12 col-lg-12 ">
          <div className="h4 card-title">Review List</div>
          <PaginationProvider
            pagination={paginationFactory(pageOptions)}
            keyField="_id"
            columns={columns}
            data={reviewsData}
          >
            {({ paginationProps, paginationTableProps }) => (
              <ToolkitProvider
                keyField="_id"
                columns={columns}
                data={reviewsData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-2">
                      <Col
                        md="4"
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          width: "100%",
                        }}
                      >
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <SearchBar {...toolkitProps.searchProps} />
                            <i className="bx bx-search-alt search-icon" />
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col xl="12">
                        <div className="table-responsive">
                          <BootstrapTable
                            responsive
                            bordered={false}
                            striped={true}
                            defaultSorted={defaultSorted}
                            hover
                            classes={"table align-middle table-nowrap"}
                            headerWrapperClasses={"thead-light"}
                            {...toolkitProps.baseProps}
                            {...paginationTableProps}
                          />
                        </div>
                      </Col>
                    </Row>
                  </React.Fragment>
                )}
              </ToolkitProvider>
            )}
          </PaginationProvider>
        </div>
      </div>
    </>
  )
}

AddReviews.propTypes = {
  history: PropTypes.object,
  userData: PropTypes.object,
  editData: PropTypes.object,
  courseId: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    userData: state.userData,
    editData: state.userEdit,
    courseId: state.courseId,
  }
}

export default connect(mapStateToProps)(withRouter(AddReviews))
