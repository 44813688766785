import React, { useState, useEffect } from "react"
import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  Label,
  Form,
  Input,
  FormFeedback,
  InputGroup,
  FormGroup,
} from "reactstrap"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { withRouter, Link } from "react-router-dom"
//Import Breadcrumb
import Multiselect from "multiselect-react-dropdown"
import PropTypes from "prop-types"
import { useDispatch, connect } from "react-redux"
import axios from "axios"
import Selector from "components/Custom/Selector"
import { useFormik } from "formik"
import * as Yup from "yup"
import { toast } from "react-toastify"
import Loader from "components/Custom/Loader"
import { userEdit } from "../../store/actions"
import { courseId } from "../../store/actions"

const AddDetails = props => {
  const [companyList, setCompanyList] = useState([])
  const [selectComapny, setSelectCompany] = useState(null)
  const [courseTypeList, setCourseTypeList] = useState([])
  const [selectCourseType, setSelectCourseType] = useState(null)
  const [languageList, setLanguageList] = useState([])
  const [selectLanguage, setSelectedLanguage] = useState([])
  const [durationTypes, setDurationTypes] = useState([])
  const [selectDuration, selectDurationTypes] = useState(null)
  const [cities, setCities] = useState(null)
  const [selectedCity, setSelectedCity] = useState(null)
  const [modesData, setMods] = useState(null)
  const [loader, setLoader] = useState(false)
  const [selectedMods, setSelectedMods] = useState(null)
  const [date, setDate] = useState(null)
  const [checkedNot, setCheckedNot] = useState(false)
  const [files, setFiles] = useState(null)
  const [showEditImage, setShowEditImage] = useState(true)
  const dispatch = useDispatch()

  useEffect(() => {
    if (props.editData) {
      setSelectCompany(props.editData.company._id)
      setSelectCourseType(props.editData.courseType._id)
      setSelectedLanguage(props.editData.languages)
      setSelectedMods(props.editData.mode._id)
      if (props.editData.durationType) {
        selectDurationTypes(props.editData.durationType._id)
      }
      setDate(props.editData.startDate)
      setCheckedNot(props.editData.excludingGST)
      if (props.editData.city) {
        setSelectedCity(props.editData.city._id)
      }
      document.getElementById("isVerified").checked =
        props.editData.excludingGST
    }
    return () => dispatch(userEdit(null))
  }, [props.editData])
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    // enableReinitialize: true,

    initialValues: {
      courseName: props.editData ? props.editData.name : "",
      duration: props.editData ? props.editData.duration : "",
      mrp: props.editData ? props.editData.mrp : "",
      price: props.editData ? props.editData.price : "",
      // discount: props.editData ? props.editData.discount : "",
      views: props.editData ? props.editData.views : "",
    },
    validationSchema: Yup.object({
      courseName: Yup.string().required("Please enter course name"),
      duration: Yup.string().required("Please enter duration"),
      mrp: Yup.string().required("Please enter mrp"),
      price: Yup.string().required("Please enter price"),
      // discount: Yup.string().required("Please enter discount"),
      views: Yup.string().required("Please enter views"),
    }),

    onSubmit: values => {
      if (selectComapny == null) {
        return toast.error("Please select a company", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      }
      if (selectCourseType == null) {
        return toast.error("Please select a cours Type", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      }

      if (selectDuration == null) {
        return toast.error("Please select a duration types", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      }
      if (date == null) {
        return toast.error("Please select starting date", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      }
      if (selectLanguage == "") {
        return toast.error("Please select languages", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      }
      if (selectedMods == null) {
        return toast.error("Please select mode", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      }
      if (selectedMods === "62a87d488d2a51c9b614b058") {
        if (selectedCity == null) {
          return toast.error("Please select city", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        }
      }
      if (!props.editData) {
        if (files == null) {
          return toast.error("Please select a file", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        }
      }

      var data = new FormData()
      data.append("name", values.courseName)
      data.append("company", selectComapny)
      data.append("courseType", selectCourseType)
      data.append("duration", values.duration)
      data.append("durationType", selectDuration)
      data.append("startDate", date)

      selectLanguage.map((d, i) => {
        data.append(`languages[${i}]`, d._id)
      })
      data.append("mrp", values.mrp)
      data.append("price", values.price)
      // data.append("discount", values.discount)
      data.append("excludingGST", checkedNot ? 1 : 0)
      data.append("mode", selectedMods)
      selectedCity !== null && data.append("city", selectedCity)
      data.append("views", values.views)
      files !== null && data.append("certificate", files)
      var config = {
        method: props.editData ? "put" : "post",
        url: `${process.env.REACT_APP_BASE_URL}/${
          props.editData ? "courses/details/" + props.editData._id : "courses"
        }`,
        headers: {
          "x-auth-token": props.userData.token,
          "Content-Type": "application/json",
        },
        data: data,
      }
      setLoader(true)
      axios(config)
        .then(function (response) {
          setLoader(false)
          dispatch(courseId(response.data.id))
          setTimeout(() => {
            toast.success(response.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            })
          }, 200)
        })
        .catch(function (error) {
          console.log(error)
          setLoader(false)
          toast.error(error.response && error.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        })
    },
  })

  // getting companies data
  useEffect(() => {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/formdata/companies`,
      headers: {
        "x-auth-token": props.userData.token,
        "Content-Type": "text/plain",
      },
    }
    axios(config)
      .then(function (response) {
        setCompanyList(response.data.company)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [])

  // getting coursetypes data
  useEffect(() => {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/formdata/coursetypes`,
      headers: {
        "x-auth-token": props.userData.token,
      },
    }

    axios(config)
      .then(function (response) {
        setCourseTypeList(response.data.course_types)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [])

  // getting languages data
  useEffect(() => {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/formdata/languages`,
      headers: {
        "x-auth-token": props.userData.token,
        "Content-Type": "application/json",
      },
    }

    axios(config)
      .then(function (response) {
        setLanguageList(response.data.languages)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [])

  // getting durationtypes data
  useEffect(() => {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/formdata/durationtypes`,
      headers: {
        "x-auth-token": props.userData.token,
      },
    }

    axios(config)
      .then(function (response) {
        setDurationTypes(response.data.duration_types)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [])

  // getting cities data

  useEffect(() => {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/cities/0`,
      headers: {
        "x-auth-token": props.userData.token,
      },
    }

    axios(config)
      .then(function (response) {
        setCities(response.data)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [])

  // getting modes data
  useEffect(() => {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/formdata/modes`,
      headers: {
        "x-auth-token": props.userData.token,
      },
    }

    axios(config)
      .then(function (response) {
        setMods(response.data.modes)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [])
  if (loader) {
    return <Loader />
  }

  return (
    <>
      <React.Fragment>
        <div>
          <Form
            className="form-horizontal"
            onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
              return false
            }}
          >
            <Container fluid={true}>
              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <Row>
                        <Col lg={6}>
                          <div className="mb-3">
                            <Label htmlFor="formrow-InputZip">
                              Course Name{" "}
                              <span style={{ color: "red" }}>*</span>
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="courseName"
                              name="courseName"
                              placeholder="Enter Course Name"
                              value={validation.values.courseName || ""}
                              onChange={validation.handleChange}
                              invalid={
                                validation.touched.courseName &&
                                validation.errors.courseName
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.courseName &&
                            validation.errors.courseName ? (
                              <FormFeedback type="invalid">
                                {validation.errors.courseName}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="mb-3">
                            <Selector
                              label="Course Type"
                              required
                              data={
                                courseTypeList !== null ? courseTypeList : []
                              }
                              onchange={event => {
                                setSelectCourseType(event.value)
                              }}
                              edit={
                                props.editData &&
                                props.editData.courseType && {
                                  label: props.editData.courseType.name,
                                  value: props.editData.courseType._id,
                                }
                              }
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={6}>
                          <div className="mb-3">
                            <Label htmlFor="formrow-InputZip">
                              Duration <span style={{ color: "red" }}>*</span>
                            </Label>
                            <Input
                              type="text"
                              id="duration"
                              name="duration"
                              className="form-control"
                              placeholder="Enter your Duration"
                              onChange={validation.handleChange}
                              value={validation.values.duration || ""}
                              invalid={
                                validation.touched.duration &&
                                validation.errors.duration
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.duration &&
                            validation.errors.duration ? (
                              <FormFeedback type="invalid">
                                {validation.errors.duration}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3">
                            <Selector
                              label="Duration Type"
                              required
                              data={durationTypes !== null ? durationTypes : []}
                              onchange={event => {
                                selectDurationTypes(event.value)
                              }}
                              edit={
                                props.editData &&
                                props.editData.durationType && {
                                  label: props.editData.durationType.name,
                                  value: props.editData.durationType._id,
                                }
                              }
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={6}>
                          <div className="mb-3">
                            <Selector
                              label="Company"
                              required
                              data={companyList !== null ? companyList : []}
                              onchange={event => {
                                setSelectCompany(event.value)
                              }}
                              edit={
                                props.editData &&
                                props.editData.company && {
                                  label: props.editData.company.name,
                                  value: props.editData.company._id,
                                }
                              }
                            />
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div>
                            <Label for="services">
                              Language <span style={{ color: "red" }}>*</span>
                            </Label>

                            <Multiselect
                              id="services"
                              style={{
                                chips: {
                                  color: "#fff",
                                },
                              }}
                              options={
                                languageList !== null ? languageList : []
                              } // Options to display in the dropdown
                              selectedValues={
                                props.editData
                                  ? props.editData.languages
                                  : selectLanguage
                              } // Preselected value to persist in dropdown
                              onSelect={event => setSelectedLanguage(event)}
                              onRemove={e => setSelectedLanguage(e)} // Function will trigger on remove event
                              displayValue="name" // Property name to display in the dropdown options
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup className="mb-4">
                            <Label>
                              Starting Date{" "}
                              <span style={{ color: "red" }}>*</span>
                            </Label>
                            <InputGroup>
                              <Flatpickr
                                className="form-control d-block"
                                placeholder="DD/MM/YYYY"
                                name="starting_date"
                                value={
                                  props.editData
                                    ? props.editData.startDate
                                    : date
                                }
                                options={{
                                  altInput: true,
                                  altFormat: "d/m/Y",
                                  dateFormat: "d-m-yyyy",
                                }}
                                onChange={e => setDate(e[0])}
                                invalid={
                                  validation.touched.starting_date &&
                                  validation.errors.starting_date
                                    ? "true"
                                    : "false"
                                }
                              />
                              {validation.touched.starting_date &&
                              validation.errors.starting_date ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.starting_date}
                                </FormFeedback>
                              ) : null}
                            </InputGroup>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={6}>
                          <div className="mb-3">
                            <Label htmlFor="formrow-InputZip">
                              MRP <span style={{ color: "red" }}>*</span>
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="mrp"
                              name="mrp"
                              placeholder="Enter MRP"
                              value={validation.values.mrp || ""}
                              onChange={validation.handleChange}
                              invalid={
                                validation.touched.mrp && validation.errors.mrp
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.mrp && validation.errors.mrp ? (
                              <FormFeedback type="invalid">
                                {validation.errors.mrp}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="mb-3">
                            <Label htmlFor="formrow-InputZip">
                              Price <span style={{ color: "red" }}>*</span>
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="price"
                              name="price"
                              placeholder="Enter Price"
                              onChange={validation.handleChange}
                              value={validation.values.price || ""}
                              invalid={
                                validation.touched.price &&
                                validation.errors.price
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.price &&
                            validation.errors.price ? (
                              <FormFeedback type="invalid">
                                {validation.errors.price}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                       
                        {/* <Col lg={6}>
                          <div className="mb-3">
                            <Label htmlFor="formrow-InputZip">
                              Discount <span style={{ color: "red" }}>*</span>
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="discount"
                              name="discount"
                              placeholder="Enter Discount"
                              onChange={validation.handleChange}
                              value={validation.values.discount || ""}
                              invalid={
                                validation.touched.discount &&
                                validation.errors.discount
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.discount &&
                            validation.errors.discount ? (
                              <FormFeedback type="invalid">
                                {validation.errors.discount}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col> */}
                      </Row>
                      <div className="mb-3">
                        <div className="form-check">
                          <Input
                            type="checkbox"
                            id="isVerified"
                            name="isVerified"
                            className="form-check-Input"
                            onChange={e => setCheckedNot(!checkedNot)}
                            onBlur={validation.handleBlur}
                            value={checkedNot}
                          />
                          <Label
                            className="form-check-Label"
                            htmlFor="isVerified"
                          >
                            Excluding GST
                          </Label>
                        </div>
                      </div>
                      <Row>
                        <Col lg={6}>
                          <div className="mb-3">
                            <Selector
                              label="Mode"
                              required
                              data={modesData !== null ? modesData : []}
                              onchange={event => {
                                setSelectedMods(event.value)
                                setSelectedCity(null)
                              }}
                              edit={
                                props.editData &&
                                props.editData.mode.name && {
                                  label: props.editData.mode.name,
                                  value: props.editData.mode._id,
                                }
                              }
                            />
                          </div>
                        </Col>
                        {selectedMods === "62a87d488d2a51c9b614b058" && (
                          <Col lg={6}>
                            <div className="mb-3">
                              <Selector
                                label="City"
                                required
                                data={cities !== null ? cities : []}
                                onchange={event => {
                                  setSelectedCity(event.value)
                                }}
                                edit={
                                  props.editData &&
                                  props.editData.city && {
                                    label: props.editData.city.name,
                                    value: props.editData.city._id,
                                  }
                                }
                              />
                            </div>
                          </Col>
                        )}
                      </Row>
                      <Row>
                        <Col lg={6}>
                          <div className="mb-6">
                            <Label className="form-label">
                              Upload certificate
                            </Label>{" "}
                            <span style={{ color: "red" }}>*</span>
                            <Input
                              name="file"
                              id="file"
                              className="form-control"
                              type="file"
                              onChange={e => {
                                setFiles(e.target.files[0])
                              }}
                              value={validation.file || ""}
                              invalid={
                                (validation.touched.file &&
                                  files !== null &&
                                  files.size >
                                    Number(process.env.REACT_APP_FILE_SIZE)) ||
                                validation.errors.file
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.file &&
                            files !== null &&
                            files.size >
                              Number(process.env.REACT_APP_FILE_SIZE) ? (
                              <FormFeedback type="invalid">
                                File must be less than 2 MB
                              </FormFeedback>
                            ) : null}
                            {validation.touched.file &&
                            validation.errors.file ? (
                              <FormFeedback type="invalid">
                                {validation.errors.file}
                              </FormFeedback>
                            ) : null}
                            {files !== null && (
                              <Card
                                className="mt-2 mb-10 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                style={{
                                  backgroundColor:
                                    files.size >
                                    Number(process.env.REACT_APP_FILE_SIZE)
                                      ? "#ff6a6a"
                                      : "#2FDD92",
                                  color:
                                    files.size >
                                    Number(process.env.REACT_APP_FILE_SIZE)
                                      ? "#ffffff"
                                      : "#131313",
                                  width: "97%",
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                }}
                              >
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      <img
                                        data-dz-thumbnail=""
                                        height="80"
                                        className="avatar-sm rounded bg-light"
                                        style={{
                                          objectFit: "cover",
                                        }}
                                        alt={files.name}
                                        src={URL.createObjectURL(files)}
                                      />
                                    </Col>
                                    <Col>
                                      <Link
                                        to="#"
                                        style={{
                                          color:
                                            files.size >
                                            Number(
                                              process.env.REACT_APP_FILE_SIZE
                                            )
                                              ? "#ffffff"
                                              : "#131313",
                                        }}
                                      >
                                        {files.name}
                                      </Link>
                                      <p className="mb-0">
                                        {(files.size / 1048576).toFixed(2) +
                                          " MB"}
                                      </p>
                                      <i
                                        className="bx bx-no-entry"
                                        style={{
                                          position: "absolute",
                                          top: 0,
                                          right: 10,
                                          color:
                                            files.size >
                                            Number(
                                              process.env.REACT_APP_FILE_SIZE
                                            )
                                              ? "#ffffff"
                                              : "#131313",
                                          fontSize: 17,
                                          fontWeight: 800,
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          setFiles(null)
                                          document.getElementById(
                                            "file"
                                          ).value = ""
                                        }}
                                      ></i>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            )}
                            {props.editData && showEditImage && files == null && (
                              <Card
                                className="mt-2 mb-10 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                style={{
                                  backgroundColor:
                                    props.editData.certificate.fileSize >
                                    Number(process.env.REACT_APP_FILE_SIZE)
                                      ? "#ff6a6a"
                                      : "#2FDD92",
                                  color:
                                    props.editData.certificate.fileSize >
                                    Number(process.env.REACT_APP_FILE_SIZE)
                                      ? "#ffffff"
                                      : "#131313",
                                  width: "97%",
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                }}
                              >
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      <img
                                        data-dz-thumbnail=""
                                        height="80"
                                        className="avatar-sm rounded bg-light"
                                        style={{
                                          objectFit: "cover",
                                        }}
                                        alt={
                                          props.editData.certificate
                                            .originalname
                                        }
                                        src={`${process.env.REACT_APP_IMAGE_URL}/course/${props.editData.certificate.filename}`}
                                      />
                                    </Col>
                                    <Col>
                                      <Link
                                        to="#"
                                        style={{
                                          color:
                                            props.editData.certificate
                                              .fileSize >
                                            Number(
                                              process.env.REACT_APP_FILE_SIZE
                                            )
                                              ? "#ffffff"
                                              : "#131313",
                                        }}
                                      >
                                        {
                                          props.editData.certificate
                                            .originalname
                                        }
                                      </Link>
                                      <p className="mb-0">
                                        {(
                                          props.editData.certificate.fileSize /
                                          1048576
                                        ).toFixed(2) + " MB"}
                                      </p>
                                      <i
                                        className="bx bx-no-entry"
                                        style={{
                                          position: "absolute",
                                          top: 0,
                                          right: 10,
                                          color:
                                            props.editData.certificate
                                              .fileSize >
                                            Number(
                                              process.env.REACT_APP_FILE_SIZE
                                            )
                                              ? "#ffffff"
                                              : "#131313",
                                          fontSize: 17,
                                          fontWeight: 800,
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          setShowEditImage(false)
                                        }}
                                      ></i>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            )}
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="mb-3">
                            <Label htmlFor="formrow-InputZip">
                              Views <span style={{ color: "red" }}>*</span>
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="views"
                              name="views"
                              placeholder="Enter Views"
                              value={validation.values.views || ""}
                              onChange={validation.handleChange}
                              invalid={
                                validation.touched.views &&
                                validation.errors.views
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.views &&
                            validation.errors.views ? (
                              <FormFeedback type="invalid">
                                {validation.errors.views}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                      <div>
                        <button type="submit" className="btn btn-primary w-md">
                          Submit
                        </button>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </Form>
        </div>
      </React.Fragment>
    </>
  )
}
AddDetails.propTypes = {
  history: PropTypes.object,
  userData: PropTypes.object,
  editData: PropTypes.object,
}

const mapStateToProps = state => {
  return {
    userData: state.userData,
    editData: state.userEdit,
  }
}

export default connect(mapStateToProps)(withRouter(AddDetails))
