import React, { useState, useEffect } from "react"
import axios from "axios"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import LayoutModal from "../../components/Custom/LayoutModel"
import { withRouter } from "react-router-dom"
import { Row, Col, Input, Label } from "reactstrap"
import Loader from "components/Custom/Loader"
import { toast } from "react-toastify"
import Tostify from "components/Custom/Tostify"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
// import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import "../DatatableScss/datatables.scss"
function AddNotification(props) {
  // Use this state
  const [title, setTitle] = useState("")
  const [message, setMessage] = useState("")
  const [doc, setDoc] = useState(null)
  const [editDoc, setEditDoc] = useState(null)
  const [titleErr, setTitleErr] = useState("")
  const [messageErr, setMessageErr] = useState("")
  const [notificationList, setNotificationList] = useState([])
  const [loader, setLoader] = useState(false)
  const getNotificationData = () => {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/notifications`,
      headers: {
        "x-auth-token": props.userData.token,
      },
    }
    axios(config)
      .then(function (response) {
        setNotificationList(response.data.notifications)
      })
      .catch(function (error) {
        console.log(error)
      })
  }
  useEffect(() => {
    getNotificationData()
  }, [])

  const handleSubmit = () => {
    if (title === "") {
      return setTitleErr("Please Enter Title")
    }
    setTitleErr("")
    if (message === "") {
      return setMessageErr("Please Enter Message")
    }
    setMessageErr("")

    setLoader(true)
    var data = new FormData()
    data.append("title", title)
    data.append("message", message)
    data.append("isSendToAll", 1)
    doc !== null && data.append("file", doc)

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/notifications`,
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": props.userData.token,
      },
      data: data,
    }
    axios(config)
      .then(function (response) {
        setDoc(null)
        getNotificationData()
        setLoader(false)
        setTitle("")
        setMessage("")
        setTimeout(() => {
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        }, 200)
      })
      .catch(function (error) {
        setLoader(false)
        toast.error(error.response && error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
        console.log(error)
      })
  }

  const columns = [
    {
      dataField: "title",
      text: "Title",
      sort: true,
    },
    {
      dataField: "message",
      text: "Message",
      sort: true,
    },
    {
      dataField: "df0",
      isDummyField: true,
      text: "Image",
      formatter: (cellContent, row, rowIndex) => {
        return (
          <div
            style={{
              display: "flex",
            }}
          >
            {row.file && (
              <img
                data-dz-thumbnail=""
                height="80"
                className="avatar-sm rounded bg-light"
                style={{
                  objectFit: "cover",
                }}
                src={`${process.env.REACT_APP_IMAGE_URL}notification_images/${row.file.filename}`}
              />
            )}
          </div>
        )
      },
    },
  ]

  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChangeno,
  }) => (
    <div className="btn-group" role="group">
      {options.map(option => {
        const isSelect = currSizePerPage === `${option.page}`
        return (
          <button
            key={option.text}
            type="button"
            onClick={() => onSizePerPageChange(option.page)}
            className={`btn ${isSelect ? "btn-secondary" : "btn-primary"}`}
          >
            {option.text}
          </button>
        )
      })}
    </div>
  )

  const defaultSorted = [
    {
      dataField: "title",
      order: "asc",
    },
  ]

  const pageOptions = {
    sizePerPage: 10,
    sizePerPageRenderer,
    totalSize: notificationList.length, // replace later with size(customers),
  }

  const { SearchBar } = Search
  if (loader) {
    return <Loader />
  }
  return (
    <>
      <Tostify />
      <div
        className="d-flex flex-column flex-sm-column flex-md-column flex-lg-row"
        style={{ flex: 1, minHeight: "100vh" }}
      >
        <div className="col-sm-12 col-lg-4">
          <LayoutModal
            button={false}
            title={props.editData ? "Edit Notification" : "Add Notification"}
            btnTitle={props.editData ? "Add Notification" : "Notification List"}
          >
            <div className="mb-3">
              <Label className="form-label">
                Title <span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                name="title"
                id="title"
                className="form-control"
                placeholder="Enter Title"
                type="text"
                onChange={e => {
                  setTitle(e.target.value)
                  setTitleErr("")
                }}
                value={title || ""}
              />
              <span style={{ color: "red", fontSize: 10 }}>{titleErr}</span>
            </div>
            <div className="mb-3">
              <Label className="form-label">
                Message <span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                name="message"
                id="message"
                className="form-control"
                placeholder="Enter Message"
                type="text"
                onChange={e => setMessage(e.target.value)}
                value={message || ""}
              />
              <span style={{ color: "red", fontSize: 10 }}>{messageErr}</span>
            </div>
            <div className="mb-3">
              <div
                style={{
                  height: 100,
                  width: 100,
                  backgroundColor: "#f2f2f5",
                  marginBottom: 20,
                  border:
                    doc !== null ? "2px dashed #1D9Bf0" : "2px dashed #1D9Bf0",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <input
                  style={{
                    opacity: 0,
                    height: 100,
                    width: 100,
                    position: "absolute",
                  }}
                  onChange={e => {
                    if (typeof e.target.files[0] !== "undefined") {
                      setEditDoc(null)
                      setDoc(e.target.files[0])
                    }
                  }}
                  type="file"
                />
                {doc !== null || editDoc ? (
                  <img
                    src={
                      editDoc
                        ? `${process.env.REACT_APP_IMAGE_URL}notification_images/${editDoc.filename}`
                        : URL.createObjectURL(doc)
                    }
                    alt="img"
                    style={{
                      height: 98,
                      width: 98,
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <Label
                    style={{
                      textAlign: "center",
                      fontSize: 10,
                      marginTop: 12,
                    }}
                    className="form-label"
                  >
                    Upload Image
                  </Label>
                )}
              </div>
            </div>
            <Row className="mb-3">
              <Col className="text-start">
                <button
                  className="btn btn-primary w-md "
                  type="button"
                  onClick={() => handleSubmit()}
                >
                  Submit
                </button>
              </Col>
            </Row>
          </LayoutModal>
        </div>
        <div className="col-sm-12 col-lg-8">
          <LayoutModal
            title="Notification List"
            sideTitle="Notification"
            button={false}
          >
            <PaginationProvider
              pagination={paginationFactory(pageOptions)}
              keyField="_id"
              columns={columns}
              data={notificationList}
            >
              {({ paginationProps, paginationTableProps }) => (
                <ToolkitProvider
                  keyField="_id"
                  columns={columns}
                  data={notificationList}
                  search
                >
                  {toolkitProps => (
                    <React.Fragment>
                      <Row className="mb-2">
                        <Col
                          md="4"
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            width: "100%",
                          }}
                        >
                          <div className="search-box me-2 mb-2 d-inline-block">
                            <div className="position-relative">
                              <SearchBar {...toolkitProps.searchProps} />
                              <i className="bx bx-search-alt search-icon" />
                            </div>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col xl="12">
                          <div className="table-responsive">
                            <BootstrapTable
                              responsive
                              bordered={false}
                              striped={true}
                              defaultSorted={defaultSorted}
                              hover
                              classes={"table align-middle table-nowrap"}
                              headerWrapperClasses={"thead-light"}
                              {...toolkitProps.baseProps}
                              {...paginationTableProps}
                            />
                          </div>
                        </Col>
                      </Row>
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              )}
            </PaginationProvider>
          </LayoutModal>
        </div>
      </div>
    </>
  )
}

AddNotification.propTypes = {
  userData: PropTypes.object,
  editData: PropTypes.object,
  history: PropTypes.object,
}

const mapStateToProps = state => {
  return {
    userData: state.userData,
    editData: state.userEdit,
  }
}

export default connect(mapStateToProps)(withRouter(AddNotification))
