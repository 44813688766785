import React, { useState, useEffect } from "react"
import axios from "axios"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import LayoutModal from "../../components/Custom/LayoutModel"
import {
  Row,
  Col,
  Input,
  Label,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  Button,
  Card,
} from "reactstrap"
import { withRouter, Link } from "react-router-dom"
import Loader from "components/Custom/Loader"
import { toast } from "react-toastify"
import Tostify from "components/Custom/Tostify"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import "../DatatableScss/datatables.scss"
function AddCategory(props) {
  const [category, setCategory] = useState("")
  const [categoryErr, setCategoryErr] = useState("")
  const [categoryList, setCategoryList] = useState([])
  const [files, setFiles] = useState(null)
  const [showEditImage, setShowEditImage] = useState(true)
  const [editCategory, setEditCategory] = useState(null)
  const [rowIndexTable, setRowIndexTable] = useState()
  const [loader, setLoader] = useState(false)

  const getCategoryDate = () => {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/templateCategories/0`,
      headers: {
        "x-auth-token": props.userData.token,
      },
    }

    axios(config)
      .then(function (response) {
        setCategoryList(response.data.categories)
      })
      .catch(function (error) {
        console.log(error)
      })
  }
  useEffect(() => {
    getCategoryDate()
  }, [])
  const handleSubmit = () => {
    if (category === "") {
      return setCategoryErr("Please Enter category name")
    }

    if (!editCategory) {
      if (files == null) {
        return toast.error("Please select a file", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      }
    }

    setCategoryErr("")
    setLoader(true)
    var data = new FormData()
    data.append("name", category)
    files !== null && data.append("picture", files)
    var config = {
      method: editCategory !== null ? "put" : "post",
      url: `${process.env.REACT_APP_BASE_URL}/templateCategories${
        editCategory !== null ? "/" + editCategory._id : ""
      }`,
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": props.userData.token,
      },
      data: data,
    }
    axios(config)
      .then(function (response) {
        setLoader(false)
        setCategory("")
        setFiles(null)
        if (editCategory) {
          setEditCategory(null)
        }
        setTimeout(() => {
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
          getCategoryDate()
        }, 100)
      })
      .catch(function (error) {
        setLoader(false)
        toast.error(error.response && error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
        console.log(error)
      })
  }

  const changeStatus = data => {
    setRowIndexTable(rowIndexTable - 1)
    setLoader(true)
    var config = {
      method: "put",
      url: `${process.env.REACT_APP_BASE_URL}/templateCategories/${data._id}/${
        data.isActive ? "0" : "1"
      }`,
      headers: {
        "x-auth-token": props.userData.token,
        "Content-Type": "application/json",
      },
    }
    axios(config)
      .then(function (response) {
        setLoader(false)
        setTimeout(() => {
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        }, 200)
        let values = categoryList
        values[categoryList.indexOf(data)].isActive = !data.isActive
        setCategoryList([...values])
      })
      .catch(function (error) {
        console.log(error)
        setLoader(false)
        toast.error(error.response && error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
  }
  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "df0",
      isDummyField: true,
      text: "Image",
      formatter: (cellContent, row, rowIndex) => {
        return (
          <div
            style={{
              display: "flex",
            }}
          >
            {row.picture && (
              <img
                data-dz-thumbnail=""
                height="80"
                className="avatar-sm rounded bg-light"
                style={{
                  objectFit: "cover",
                }}
                src={`${process.env.REACT_APP_IMAGE_URL}/template_category_images/${row.picture.filename}`}
              />
            )}
          </div>
        )
      },
    },
    {
      dataField: "isActive",
      text: "Status",
      sort: true,
      hidden: props.userData.isAdmin ? false : true,
      formatter: (cellContent, row, rowIndex, field) => {
        if (row.isActive) {
          return (
            <>
              <Button
                size="sm"
                type="button"
                id={rowIndex === rowIndexTable ? "PopoverLegacy" : ""}
                className="btn btn-success"
              >
                Active
              </Button>
              {rowIndex === rowIndexTable && (
                <UncontrolledPopover
                  placement="top"
                  target="PopoverLegacy"
                  trigger="legacy"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <PopoverHeader style={{ textAlign: "center" }}>
                    {" "}
                    Are you sure, you want to deactivate this category ?
                  </PopoverHeader>
                  <PopoverBody>
                    <Button
                      size="sm"
                      style={{
                        marginRight: 5,
                      }}
                      onClick={() => changeStatus(row)}
                      color="primary"
                    >
                      Yes
                    </Button>
                    <Button
                      size="sm"
                      style={{
                        marginLeft: 5,
                      }}
                      onClick={() => setRowIndexTable(rowIndexTable - 1)}
                      color="danger"
                    >
                      No
                    </Button>
                  </PopoverBody>
                </UncontrolledPopover>
              )}
            </>
          )
        } else {
          return (
            <>
              {" "}
              <Button
                size="sm"
                type="button"
                id={rowIndex === rowIndexTable ? "PopoverLegacy" : ""}
                className="btn btn-danger"
              >
                Deactive
              </Button>
              {rowIndex === rowIndexTable && (
                <UncontrolledPopover
                  placement="top"
                  target="PopoverLegacy"
                  trigger="legacy"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <PopoverHeader style={{ textAlign: "center" }}>
                    {" "}
                    Are you sure, you want to active this category ?
                  </PopoverHeader>
                  <PopoverBody>
                    <Button
                      style={{
                        marginRight: 5,
                      }}
                      size="sm"
                      onClick={() => changeStatus(row)}
                      color="primary"
                    >
                      Yes
                    </Button>
                    <Button
                      style={{
                        marginLeft: 5,
                      }}
                      size="sm"
                      onClick={() => setRowIndexTable(rowIndexTable - 1)}
                      color="danger"
                    >
                      No
                    </Button>
                  </PopoverBody>
                </UncontrolledPopover>
              )}
            </>
          )
        }
      },
      events: {
        onMouseEnter: (e, column, columnIndex, row, rowIndex) => {
          setRowIndexTable(rowIndex)
        },
      },
    },
    {
      dataField: "df1",
      isDummyField: true,
      text: "Action",
      formatter: (cellContent, row, rowIndex) => {
        return (
          <div
            style={{
              display: "flex",
            }}
          >
            <Button
              size="sm"
              type="button"
              className="btn btn-warning"
              onClick={() => {
                setCategoryErr("")
                setCategory(row.name)
                setEditCategory(row)
                setLoader(true)
                setTimeout(() => {
                  setLoader(false)
                }, 300)
              }}
            >
              Edit
            </Button>
          </div>
        )
      },
      events: {
        onMouseEnter: (e, column, columnIndex, row, rowIndex) => {
          setRowIndexTable(rowIndex)
        },
      },
    },
  ]

  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChangeno,
  }) => (
    <div className="btn-group" role="group">
      {options.map(option => {
        const isSelect = currSizePerPage === `${option.page}`
        return (
          <button
            key={option.text}
            type="button"
            onClick={() => onSizePerPageChange(option.page)}
            className={`btn ${isSelect ? "btn-secondary" : "btn-primary"}`}
          >
            {option.text}
          </button>
        )
      })}
    </div>
  )

  const defaultSorted = [
    {
      dataField: "name",
      order: "asc",
    },
  ]

  const pageOptions = {
    sizePerPage: 50,
    sizePerPageRenderer,
    totalSize: categoryList.length, // replace later with size(customers),
  }
  const { SearchBar } = Search
  if (loader) {
    return <Loader />
  }
  return (
    <>
      <Tostify />
      <div
        className="d-flex flex-column flex-sm-column flex-md-column flex-lg-row"
        style={{ flex: 1, minHeight: "100vh" }}
      >
        <div className="col-sm-12 col-lg-4">
          <LayoutModal
            button={false}
            title={editCategory !== null ? "Edit Category" : "Add Category"}
          >
            <div className="mb-3">
              <Label className="form-label">
                Category <span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                name="category"
                id="category"
                className="form-control"
                placeholder="Category"
                type="text"
                onChange={e => setCategory(e.target.value)}
                value={category || ""}
              />
              <span style={{ color: "red", fontSize: 10 }}>{categoryErr}</span>
            </div>
            <div className="mb-6">
              <Label className="form-label">Upload Picture</Label>{" "}
              <span style={{ color: "red" }}>*</span>
              <Input
                name="file"
                id="file"
                className="form-control"
                type="file"
                onChange={e => {
                  setFiles(e.target.files[0])
                }}
              />
              {files !== null && (
                <Card
                  className="mt-2 mb-10 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                  style={{
                    backgroundColor:
                      files.size > Number(process.env.REACT_APP_FILE_SIZE)
                        ? "#ff6a6a"
                        : "#2FDD92",
                    color:
                      files.size > Number(process.env.REACT_APP_FILE_SIZE)
                        ? "#ffffff"
                        : "#131313",
                    width: "97%",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  <div className="p-2">
                    <Row className="align-items-center">
                      <Col className="col-auto">
                        <img
                          data-dz-thumbnail=""
                          height="80"
                          className="avatar-sm rounded bg-light"
                          style={{
                            objectFit: "cover",
                          }}
                          alt={files.name}
                          src={URL.createObjectURL(files)}
                        />
                      </Col>
                      <Col>
                        <Link
                          to="#"
                          style={{
                            color:
                              files.size >
                              Number(process.env.REACT_APP_FILE_SIZE)
                                ? "#ffffff"
                                : "#131313",
                          }}
                        >
                          {files.name}
                        </Link>
                        <p className="mb-0">
                          <strong>
                            {(files.size / 1048576).toFixed(2) + " MB"}
                          </strong>
                        </p>
                        <i
                          className="bx bx-no-entry"
                          style={{
                            position: "absolute",
                            top: 0,
                            right: 10,
                            color:
                              files.size >
                              Number(process.env.REACT_APP_FILE_SIZE)
                                ? "#ffffff"
                                : "#131313",
                            fontSize: 17,
                            fontWeight: 800,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setFiles(null)
                            document.getElementById("file").value = ""
                          }}
                        ></i>
                      </Col>
                    </Row>
                  </div>
                </Card>
              )}
              {editCategory &&
                editCategory.picture &&
                showEditImage &&
                files == null && (
                  <Card
                    className="mt-2 mb-10 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                    style={{
                      backgroundColor:
                        editCategory.picture.fileSize >
                        Number(process.env.REACT_APP_FILE_SIZE)
                          ? "#ff6a6a"
                          : "#2FDD92",
                      color:
                        editCategory.picture.fileSize >
                        Number(process.env.REACT_APP_FILE_SIZE)
                          ? "#ffffff"
                          : "#131313",
                      width: "97%",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    <div className="p-2">
                      <Row className="align-items-center">
                        <Col className="col-auto">
                          <img
                            data-dz-thumbnail=""
                            height="80"
                            className="avatar-sm rounded bg-light"
                            style={{
                              objectFit: "cover",
                            }}
                            alt={editCategory.picture.originalname}
                            src={`${process.env.REACT_APP_IMAGE_URL}/template_category_images/${editCategory.picture.filename}`}
                          />
                        </Col>
                        <Col>
                          <Link
                            to="#"
                            style={{
                              color:
                                editCategory.picture.fileSize >
                                Number(process.env.REACT_APP_FILE_SIZE)
                                  ? "#ffffff"
                                  : "#131313",
                            }}
                          >
                            {editCategory.picture.originalname}
                          </Link>
                          <p className="mb-0">
                            <strong>
                              {(
                                editCategory.picture.fileSize / 1048576
                              ).toFixed(2) + " MB"}
                            </strong>
                          </p>
                          <i
                            className="bx bx-no-entry"
                            style={{
                              position: "absolute",
                              top: 0,
                              right: 10,
                              color:
                                editCategory.picture.fileSize >
                                Number(process.env.REACT_APP_FILE_SIZE)
                                  ? "#ffffff"
                                  : "#131313",
                              fontSize: 17,
                              fontWeight: 800,
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setShowEditImage(false)
                            }}
                          ></i>
                        </Col>
                      </Row>
                    </div>
                  </Card>
                )}
            </div>

            <Row className="mb-3">
              <Col className="text-start">
                <button
                  className="btn btn-primary w-md mt-4"
                  type="button"
                  onClick={() => handleSubmit()}
                >
                  Submit
                </button>
              </Col>
            </Row>
          </LayoutModal>
        </div>
        <div className="col-sm-12 col-lg-8">
          <LayoutModal
            title="Category List"
            sideTitle="Category"
            button={false}
          >
            <PaginationProvider
              pagination={paginationFactory(pageOptions)}
              keyField="_id"
              columns={columns}
              data={categoryList}
            >
              {({ paginationProps, paginationTableProps }) => (
                <ToolkitProvider
                  keyField="_id"
                  columns={columns}
                  data={categoryList}
                  search
                >
                  {toolkitProps => (
                    <React.Fragment>
                      <Row className="mb-2">
                        <Col
                          md="4"
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            width: "100%",
                          }}
                        >
                          <div className="search-box me-2 mb-2 d-inline-block">
                            <div className="position-relative">
                              <SearchBar {...toolkitProps.searchProps} />
                              <i className="bx bx-search-alt search-icon" />
                            </div>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col xl="12">
                          <div className="table-responsive">
                            <BootstrapTable
                              responsive
                              bordered={false}
                              striped={true}
                              defaultSorted={defaultSorted}
                              hover
                              classes={"table align-middle table-nowrap"}
                              headerWrapperClasses={"thead-light"}
                              {...toolkitProps.baseProps}
                              {...paginationTableProps}
                            />
                          </div>
                        </Col>
                      </Row>
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              )}
            </PaginationProvider>
          </LayoutModal>
        </div>
      </div>
    </>
  )
}

AddCategory.propTypes = {
  userData: PropTypes.object,
  editData: PropTypes.object,
  history: PropTypes.object,
}

const mapStateToProps = state => {
  return {
    userData: state.userData,
    editData: state.userEdit,
  }
}

export default connect(mapStateToProps)(withRouter(AddCategory))
