import React, { useState, useEffect } from "react"
import { Row, Col, Input, Form, Label, FormGroup } from "reactstrap"
import { useDispatch, connect } from "react-redux"
import PropTypes from "prop-types"
import axios from "axios"
import { toast } from "react-toastify"
import Tostify from "components/Custom/Tostify"
import Loader from "components/Custom/Loader"
import LayoutModal from "../../components/Custom/LayoutModel"
import Selector from "components/Custom/Selector"
import { userEdit } from "../../store/actions"
import { withRouter } from "react-router-dom"
function AddFaq(props) {
  const [loader, setLoader] = useState(false)
  const [question, setQuestion] = useState("")
  const [questionErr, setQuestionErr] = useState("")
  const [answer, setAnswer] = useState("")
  const [answerErr, setAnswerErr] = useState("")
  const [courseTypeList, setCourseTypeList] = useState([])
  const [selectCourseType, setSelectCourseType] = useState([])
  const [courseErr, setCourseErr] = useState("")
  const [courseCheckBox, setCourseCheckBox] = useState(true)
  const [webinarCheckBox, setWebinarCheckBox] = useState(false)
  const [isCourse, setIsCourse] = useState(1)
  const dispatch = useDispatch()
  useEffect(() => {
    document.getElementById("course").checked = true
  }, [])

  useEffect(() => {
    if (props.editData) {
      let data = props.editData
      setQuestion(data.question)
      setAnswer(data.ans)
      if (data.courseType) {
        setSelectCourseType(data.courseType._id)
      } else {
        document.getElementById("webinar").checked = true
        setCourseCheckBox(false)
        setIsCourse(0)
      }
    }

    return () => dispatch(userEdit(null))
  }, [props.editData])

  const handleSubmit = () => {
    if (question == "") {
      setQuestionErr("Please enter your question")
    } else {
      setQuestionErr("")
    }
    if (answer == "") {
      setAnswerErr("Please enter your answer")
    } else {
      setAnswerErr("")
    }
    if (isCourse == 1) {
      if (selectCourseType == "") {
        return setCourseErr("Please select course type")
      } else {
        setCourseErr("")
      }
    }
    if (isCourse == 1) {
      var data = JSON.stringify({
        question: question,
        ans: answer,
        courseType: selectCourseType ? selectCourseType : null,
        isCourse: isCourse,
      })
    } else {
      var data = JSON.stringify({
        question: question,
        ans: answer,
        isCourse: isCourse,
      })
    }

    var config = {
      method: props.editData ? "put" : "post",
      url: `${process.env.REACT_APP_BASE_URL}/faq/${
        props.editData ? props.editData._id : ""
      }`,
      headers: {
        "x-auth-token": props.userData.token,
        "Content-Type": "application/json",
      },
      data: data,
    }
    setLoader(true)
    axios(config)
      .then(function (response) {
        setLoader(false)
        setTimeout(() => {
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        }, 200)
        props.history.push("/faq-list")
      })
      .catch(function (error) {
        console.log(error)
        setLoader(false)
        toast.error(error.response && error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
  }

  useEffect(() => {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/formdata/coursetypes`,
      headers: {
        "x-auth-token": props.userData.token,
      },
    }

    axios(config)
      .then(function (response) {
        setCourseTypeList(response.data.course_types)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [])

  if (loader) {
    return <Loader />
  }
  return (
    <>
      <Tostify />
      <LayoutModal
        button={true}
        size={8}
        title={props.editData ? "Edit FAQ" : "Add FAQ"}
        btnTitle={"Faq List"}
        onclick={() => {
          props.history.push("/faq-list")
        }}
      >
        <Form
          className="form-horizontal"
          id="form"
          onSubmit={e => {
            e.preventDefault()
            handleSubmit()
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: 10,
            }}
          >
            <Label className="form-label">
              Select Type <span style={{ color: "red" }}>*</span>
            </Label>
            <FormGroup style={{ marginLeft: 30 }} check>
              <Label check>
                <Input
                  type="radio"
                  name="radio1"
                  id="course"
                  disabled={props.editData ? true : false}
                  onChange={e => {
                    setCourseCheckBox(true)
                    setWebinarCheckBox(false)
                    setIsCourse(1)
                    setQuestion("")
                    setAnswer("")
                  }}
                />
                Course
              </Label>
            </FormGroup>
            <FormGroup style={{ marginLeft: 20 }} check>
              <Label check>
                <Input
                  type="radio"
                  name="radio1"
                  id="webinar"
                  disabled={props.editData ? true : false}
                  onChange={e => {
                    setCourseCheckBox(false)
                    setWebinarCheckBox(false)
                    setIsCourse(0)
                    setQuestion("")
                    setAnswer("")
                  }}
                />
                Webinar
              </Label>
            </FormGroup>
          </div>
          <Row>
            <Col lg={12}>
              <div className="mb-3">
                <Label className="form-label">
                  Question <span style={{ color: "red" }}>*</span>
                </Label>
                <Input
                  name="question"
                  id="question"
                  className="form-control"
                  placeholder="Enter your question."
                  type="text"
                  value={question || ""}
                  onChange={e => {
                    setQuestion(e.target.value)
                  }}
                />
                <span style={{ color: "red", fontSize: 10 }}>
                  {questionErr}
                </span>
              </div>
              <div className="mb-6">
                <Label htmlFor="formrow-email-Input">
                  Ans <span style={{ color: "red" }}>*</span>
                </Label>
                <Input
                  style={{ height: 160 }}
                  type="textarea"
                  className="form-control"
                  id="form-control"
                  placeholder="Enter Your answer"
                  value={answer || ""}
                  onChange={e => {
                    setAnswer(e.target.value)
                  }}
                />
                <span style={{ color: "red", fontSize: 10 }}>{answerErr}</span>
              </div>
              {courseCheckBox && (
                <div className="mb-6 mt-3">
                  <Selector
                    label="Course Type"
                    required
                    data={courseTypeList !== null ? courseTypeList : []}
                    onchange={event => {
                      setSelectCourseType(event.value)
                    }}
                    edit={
                      props.editData &&
                      props.editData.courseType && {
                        label: props.editData.courseType.name,
                        value: props.editData.courseType._id,
                      }
                    }
                  />
                  <span style={{ color: "red", fontSize: 10 }}>
                    {courseErr}
                  </span>
                </div>
              )}
            </Col>
          </Row>
          <Row className="mb-3" style={{ marginTop: 20 }}>
            <Col className="text-start">
              <button
                className="btn btn-primary w-md "
                type="submit"
                style={{ marginBottom: 20 }}
              >
                Submit
              </button>
            </Col>
          </Row>
        </Form>
      </LayoutModal>
    </>
  )
}

AddFaq.propTypes = {
  history: PropTypes.object,
  userData: PropTypes.object,
  editData: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    userData: state.userData,
    editData: state.userEdit,
  }
}

export default connect(mapStateToProps)(withRouter(AddFaq))
