import React, { useState, useEffect } from "react"
import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Input
} from "reactstrap"
import Switch from "react-switch"
import axios from "axios"

//Import Breadcrumb
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { useDispatch,connect } from "react-redux"
import Tostify from "components/Custom/Tostify"
import classnames from "classnames"
import AddDetails from "./AddDetails"
import Features from "./AddFeatures"
import AddOns from "./AddOns"
import Professor from "./AddProfessor"
import { toast } from "react-toastify"
import { webinarId } from "../../store/actions"
function AddWebinar(props) {
  const [customActiveTab, setcustomActiveTab] = useState("1")
  const [toggleSwitch, setToggleSwitch] = useState(false)
  const [loader, setLoader] = useState(false)
  const [premium, setPremium] = useState(false)
  const dispatch = useDispatch()
  useEffect(() => {}, [props.webinarId])

  useEffect(() => {
    if (props.editData) {
      if (props.editData.isActive) {
        setToggleSwitch(true)
      }
    setPremium(props.editData.isPremium)
      if (props.editData.isPremium) {
        setTimeout(() => {
          try {
            document.getElementById("premium").checked = true
          } catch (e) {
            console.log(e)
          }
        }, 200)
      }
    }
    return () => dispatch(webinarId(null))
  }, [props.editData])

  const setCoursePremium = data => {
    setLoader(true)
    var config = {
      method: "put",
      url: `${process.env.REACT_APP_BASE_URL}/webinars/premium/${data ?  data :props.editData._id }/${premium  ? "0" : "1"}`,
      headers: {
        "x-auth-token": props.userData.token,
        "Content-Type": "application/json",
      },
    }

    axios(config)
      .then(function (response) {
        setLoader(false)       
        setTimeout(() => {
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        }, 200)
      })
      .catch(function (error) {
        console.log(error)
        setLoader(false)
        toast.error(error.response && error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
  }

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }
  const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
          borderRadius: 50,
        }}
      ></div>
    )
  }

  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          borderRadius: 50,
          paddingRight: 2,
        }}
      ></div>
    )
  }

  const toggle = () => {
    if (toggleSwitch) {
      setToggleSwitch(false)
      var config = {
        method: "put",
        url: `${process.env.REACT_APP_BASE_URL}/webinars/status/${
          props.webinarId ? props.webinarId : props.editData._id
        }/0`,
        headers: {
          "x-auth-token": props.userData.token,
          "Content-Type": "application/json",
        },
      }
      axios(config)
        .then(function (response) {
          setLoader(false)
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        })
        .catch(function (error) {
          console.log(error)
          setLoader(false)
          toast.error(error.response && error.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        })
    } else {
      setToggleSwitch(true)
      var config = {
        method: "put",
        url: `${process.env.REACT_APP_BASE_URL}/webinars/status/${
          props.webinarId ? props.webinarId : props.editData._id
        }/1`,
        headers: {
          "x-auth-token": props.userData.token,
          "Content-Type": "application/json",
        },
      }
      axios(config)
        .then(function (response) {
          setLoader(false)
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        })
        .catch(function (error) {
          console.log(error)
          setLoader(false)
          toast.error(error.response && error.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        })
    }
  }

  return (
    <>
      <Tostify />
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>  <CardTitle className="h4">
                        {props.editData ? "EDIT WEBINAR" : "ADD WEBINAR"}
                      </CardTitle></div>
                      <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignContent: "center",
                        alignItems: "center",
                      }}
                      >
                        <label>Premium</label>
                    <Input   
                    style={{
                      marginRight: 20,
                      marginLeft: 5,
                      marginBottom: 10,
                    }}             
                  type="checkbox"
                  disabled={
                    props.editData || props.webinarId ? false : true
                  }
                  id="premium"
                  // checked={row.isPremium}
                  className="form-control"
                  placeholder="Link"
                  onChange={e => {                           
                    setPremium(e.target.checked)
                    setCoursePremium(props.webinarId)
                    // console.log(e.target.checked,"wwww");
                  }}
              />
                                <Switch
                                  disabled={
                                    props.editData || props.webinarId ? false : true
                                  }
                                  uncheckedIcon={<Offsymbol />}
                                  checkedIcon={<OnSymbol />}
                                  className="me-2 mb-sm-8 mb-2"
                                  onColor="#34c38f"
                                  offColor="#74788d"
                                  height={18}
                                  width={40}
                                  onChange={() => {
                                    toggle()
                                  }}
                                  checked={toggleSwitch}
                                />
                      </div>                  
                    </div>

                    <Nav tabs className="nav-tabs-custom nav-justified">
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: customActiveTab === "1",
                          })}
                          onClick={() => {
                            toggleCustom("1")
                          }}
                        >
                          <span className="d-block d-sm-none">
                            <i className="fas fa-home"></i>
                          </span>
                          <span className="d-none d-sm-block">Details</span>
                        </NavLink>
                      </NavItem>

                      <NavItem>
                        <NavLink
                          disabled={
                            props.editData || props.webinarId ? false : true
                          }
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: customActiveTab === "3",
                          })}
                          onClick={() => {
                            toggleCustom("3")
                          }}
                        >
                          <span className="d-block d-sm-none">
                            <i className="far fa-envelope"></i>
                          </span>
                          <span className="d-none d-sm-block">Features</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          disabled={
                            props.editData || props.webinarId ? false : true
                          }
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: customActiveTab === "4",
                          })}
                          onClick={() => {
                            toggleCustom("4")
                          }}
                        >
                          <span className="d-block d-sm-none">
                            <i className="fas fa-cog"></i>
                          </span>
                          <span className="d-none d-sm-block">Add-ons</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          disabled={
                            props.editData || props.webinarId ? false : true
                          }
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: customActiveTab === "5",
                          })}
                          onClick={() => {
                            toggleCustom("5")
                          }}
                        >
                          <span className="d-block d-sm-none">
                            <i className="fas fa-cog"></i>
                          </span>
                          <span className="d-none d-sm-block">Professors</span>
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent
                      activeTab={customActiveTab}
                      className="p-3 text-muted"
                    >
                      <TabPane tabId="1">
                        <Row>
                          <Col sm="12">
                            <CardText className="mb-0">
                              <AddDetails />
                            </CardText>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="3">
                        <Row>
                          <Col sm="12">
                            <CardText className="mb-0">
                              <Features />
                            </CardText>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="4">
                        <Row>
                          <Col sm="12">
                            <CardText className="mb-0">
                              <AddOns />
                            </CardText>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="5">
                        <Row>
                          <Col sm="12">
                            <CardText className="mb-0">
                              <Professor />
                            </CardText>
                          </Col>
                        </Row>
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    </>
  )
}

AddWebinar.propTypes = {
  history: PropTypes.object,
  userData: PropTypes.object,
  editData: PropTypes.object,
  webinarId: PropTypes.any,
}
const mapStateToProps = state => {
  return {
    userData: state.userData,
    editData: state.userEdit,
    webinarId: state.webinarId,
  }
}

export default connect(mapStateToProps)(withRouter(AddWebinar))
