import React, { useState, useEffect, useRef, useCallback } from "react"
import { useDispatch, connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"
import PropTypes from "prop-types"
import { toast } from "react-toastify"
import Loader from "components/Custom/Loader"
import Cropper from "react-easy-crop"
import axios from "axios"
import { userEdit } from "../../store/actions"
import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  Label,
  Button,
  Form,
  Input,
  Modal,
} from "reactstrap"
import "flatpickr/dist/themes/material_blue.css"
import Dropzone from "react-dropzone"
import getCroppedImg from "pages/directory/getCroppedImg"
import imageCompression from "browser-image-compression"
const AddOverView = props => {
  const [videoLink, setVideoLink] = useState("")
  const [videoErr, setvideoErr] = useState("")
  const [overview, setOverview] = useState("")
  const [overviewErr, setOverviewErr] = useState("")
  const [modal_xlarge, setmodal_xlarge] = useState(false)
  const [loader, setLoader] = useState(false)
  const [selectedFiles, setselectedFiles] = useState(null)
  const [imgSrc, setImgSrc] = useState({ src: "", index: "" })
  const [compressedAllFiles, setCompressAllFiles] = useState(false)
  const [bannerImageFile, setBannerImageFile] = useState()
  const [compressLoader, setCompressLoader] = useState(false)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [filesImage, setFilesImage] = useState(null)
  const [isBannerImage, setIsBannerImage] = useState(false)
  const [editSelectedFile, setEditSelectedFiles] = useState(null)
  const [fileErr, setFileErr] = useState("")
  const dispatch = useDispatch()
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  useEffect(() => {
    if (props.editData) {
      const data = props.editData
      if (data.introVideo) {
        setVideoLink(data.introVideo)
      }
      if (data.overview) {
        setOverview(data.overview)
      }

      const files = []
      data.pics.map((d, i) => {
        Object.assign(d, {
          preview: process.env.REACT_APP_IMAGE_URL + "course/" + d.filename,
          formattedSize: formatBytes(d.fileSize),
          compressed: true,
          name: d.originalname,
        })
        files.push(d)
      })
      setselectedFiles([...files])
      setEditSelectedFiles(files.length)
    }
    return () => dispatch(userEdit(null))
  }, [props.editData])

  const handleSubmit = () => {
    if (videoLink == "") {
      return setvideoErr("Please enter a video link")
    } else {
      setvideoErr("")
    }
    if (overview == "") {
      return setOverviewErr("Please enter overview")
    } else {
      setOverviewErr("")
    }

    // if (selectedFiles == "") {
    //   return setFileErr("Please select file")
    // } else {
    //   setFileErr("")
    // }

    const getId= (url)  => {
      const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
      const match = url.match(regExp);
  
      return (match && match[2].length === 11)
        ? match[2]
        : null;
  }
      
  const videoId = getId(videoLink);
  const embedVideoLink = "https://www.youtube.com/embed/" + videoId

    var data = new FormData()
    data.append("introVideo", embedVideoLink)
    data.append("overview", overview)

    selectedFiles !== null &&
      selectedFiles.map((d, i) => {
        if (d._id) {
          return
        }
        data.append("pics", d, d.name)
      })

    var config = {
      method: "put",
      url: `${process.env.REACT_APP_BASE_URL}/courses/overview/${
        props.courseId ? props.courseId : props.editData._id
      }`,
      headers: {
        "x-auth-token": props.userData.token,
        "Content-Type": "application/json",
      },
      data: data,
    }

    setLoader(true)
    axios(config)
      .then(function (response) {
        setLoader(false)
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
      .catch(function (error) {
        console.log(error)
        setLoader(false)
        toast.error(error.response && error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
  }

  useEffect(() => {
    if (modal_xlarge) {
      setCompressAllFiles(false)
    }
  }, [modal_xlarge])

  const showCroppedImage = useCallback(async () => {
    setCompressLoader(true)

    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    }

    let filterBackendImages = []
    let filterUploadedImages = []

    selectedFiles !== null &&
      selectedFiles.map((d, i) => {
        if (!d._id) {
          filterUploadedImages.push(d)
        } else {
          filterBackendImages.push(d)
        }
      })

    let fileCompressed =
      selectedFiles !== null && !isBannerImage ? filterUploadedImages : []
    try {
      const croppedImage = await getCroppedImg(
        imgSrc.src,
        croppedAreaPixels,
        rotation
      )
      const compressFile = async file => {
        try {
          const data = await imageCompression(file, options).then(f => {
            Object.assign(f, {
              preview: URL.createObjectURL(f),
              originalPreview: URL.createObjectURL(file),
              formattedSize: formatBytes(f.size),
              originalSize: formatBytes(
                isBannerImage
                  ? filesImage.size
                  : selectedFiles[imgSrc.index].size
              ),
              name: isBannerImage
                ? filesImage.name
                : selectedFiles[imgSrc.index].name,
              compressed: true,
              bannerImage: false,
            })
            fileCompressed.splice(imgSrc.index, 1, f)
            return f
          })
          fileCompressed.length - 1 > Number(imgSrc.index)
            ? setImgSrc({
                src: fileCompressed[Number(imgSrc.index) + 1].preview,
                index: Number(imgSrc.index) + 1,
              })
            : setmodal_xlarge(!modal_xlarge)
          setCompressLoader(false)
          let result = true
          fileCompressed.map((d, i) => {
            if (i < fileCompressed.length - 1) {
              result = d.compressed && fileCompressed[i + 1].compressed
            }
          })
          setCompressAllFiles(result)
          if (!isBannerImage) {
            setselectedFiles([...fileCompressed, ...filterBackendImages])
          } else {
            setFilesImage(fileCompressed[0])
          }
        } catch (e) {
          console.log(e)
        }
      }
      compressFile(croppedImage)
    } catch (e) {
      console.error(e)
    }
  }, [croppedAreaPixels, rotation, imgSrc])

  function tog_xlarge() {
    if (compressedAllFiles) {
      setmodal_xlarge(!modal_xlarge)
    }
  }

  function handleAcceptedFiles(files, isBanner) {
    const options = {
      maxSizeMB: 1.5,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    }

    const filesLength = Number(files.length)
    const prevFilesLength = Number(
      selectedFiles !== null ? selectedFiles.length : 0
    )

    if (filesLength + prevFilesLength > 5) {
      toast.error("Upload atmost 5 images", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      })
      setmodal_xlarge(false)
      return
    }

    let fileCompressed = []

    if (isBanner) {
      files.map(file => {
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          originalPreview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
          originalSize: formatBytes(file.size),
          compressed: false,
          bannerImage: false,
        })
      })
      setFilesImage(files)
      setImgSrc({ src: files[0].preview, index: 0 })
    } else {
      if (selectedFiles !== null && selectedFiles.length > 0) {
        files.map(file => {
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            originalPreview: URL.createObjectURL(file),
            formattedSize: formatBytes(file.size),
            originalSize: formatBytes(file.size),
            compressed: false,
            bannerImage: false,
          })
          selectedFiles.unshift(file)
          fileCompressed = selectedFiles
          setselectedFiles([...selectedFiles])
          setImgSrc({ src: selectedFiles[0].preview, index: 0 })
        })
      } else {
        files.map(file => {
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            originalPreview: URL.createObjectURL(file),
            formattedSize: formatBytes(file.size),
            originalSize: formatBytes(file.size),
            compressed: false,
            bannerImage: false,
          })
        })
        setselectedFiles(files)
        setImgSrc({ src: files[0].preview, index: 0 })
      }
    }
  }
  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  if (loader) {
    return <Loader />
  }
  return (
    <React.Fragment>
      <div>
        <Container fluid={true}>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Form
                    onSubmit={e => {
                      e.preventDefault()
                      handleSubmit()
                    }}
                  >
                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-email-Input">
                            Introduction Video{" "}
                            <span style={{ color: "red" }}>*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="introductionvideo"
                            placeholder="Enter YouTube Video URL"
                            value={videoLink || ""}
                            onChange={e => {
                              setVideoLink(e.target.value)
                            }}
                          />
                          <span style={{ color: "red", fontSize: 10 }}>
                            {videoErr}
                          </span>
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-email-Input">
                            Overview <span style={{ color: "red" }}>*</span>
                          </Label>
                          <Input
                            style={{ height: "85px" }}
                            type="textarea"
                            className="form-control"
                            value={overview || ""}
                            id="overview"
                            placeholder="Enter your overview "
                            onChange={e => {
                              setOverview(e.target.value)
                            }}
                          />
                          <span style={{ color: "red", fontSize: 10 }}>
                            {overviewErr}
                          </span>
                        </div>
                      </Col>
                    </Row>
                    <div className="mb-3">
                      <Dropzone
                        accept="image/png, image/jpeg"
                        onDrop={acceptedFiles => {
                          if (acceptedFiles.length < 6) {
                            let isBanner = false
                            setIsBannerImage(false)
                            setmodal_xlarge(true)
                            handleAcceptedFiles(acceptedFiles, isBanner)
                          } else {
                            toast.error("Select atmost 5 Images", {
                              position: "top-right",
                              autoClose: 5000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                            })
                          }
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div className="dropzone">
                            <div
                              className="dz-message needsclick mt-2"
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />
                              <div className="mb-3">
                                <i className="display-4 text-muted bx bxs-cloud-upload" />
                              </div>
                              <h4>
                                Drop files here or click to upload. (1440 X 810)
                              </h4>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                      <span style={{ color: "red", fontSize: 10 }}>
                        {fileErr}
                      </span>
                      <div
                        className="dropzone-previews mt-3"
                        id="file-previews"
                      >
                        {selectedFiles !== null &&
                          selectedFiles.map((f, i) => {
                            return (
                              f.compressed && (
                                <Card
                                  className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                  key={i + "-file"}
                                >
                                  <div className="p-2">
                                    <Row className="align-items-center">
                                      <Col className="col-auto">
                                        <img
                                          className="avatar-sm rounded bg-light"
                                          style={{
                                            objectFit: "contain",
                                            height: 70,
                                            width: 130,
                                          }}
                                          alt={f.name}
                                          src={f.preview}
                                        />
                                      </Col>
                                      <Col>
                                        <Link
                                          to="#"
                                          className="text-muted font-weight-bold"
                                        >
                                          {f.name}
                                        </Link>
                                        <p className="mb-0">
                                          {f.formattedSize}
                                        </p>
                                        <i
                                          className="bx bx-no-entry"
                                          style={{
                                            position: "absolute",
                                            top: 0,
                                            right: 10,
                                            fontSize: 17,
                                            fontWeight: 800,
                                            cursor: "pointer",
                                          }}
                                          onClick={e => {
                                            e.stopPropagation()
                                            let data = selectedFiles.splice(
                                              i,
                                              1
                                            )

                                            if (
                                              selectedFiles.length > 0 &&
                                              i == 0 &&
                                              data[0].bannerImage == true
                                            ) {
                                              selectedFiles[0].bannerImage = true
                                              setBannerImageFile(
                                                selectedFiles[0]
                                              )
                                            }
                                            if (
                                              selectedFiles.length > 0 &&
                                              i > 0 &&
                                              data[0].bannerImage == true
                                            ) {
                                              selectedFiles[
                                                i - 1
                                              ].bannerImage = true
                                              setBannerImageFile(
                                                selectedFiles[i - 1]
                                              )
                                            }
                                            setselectedFiles([...selectedFiles])
                                            if (props.editData && f._id) {
                                              var dataa = JSON.stringify({
                                                courseId:
                                                  props.editData &&
                                                  props.editData._id,
                                                picId: f._id,
                                              })
                                              var config = {
                                                method: "put",
                                                url: `${process.env.REACT_APP_BASE_URL}/courses/removeImage`,
                                                headers: {
                                                  "Content-Type":
                                                    "application/json",
                                                  "x-auth-token":
                                                    props.userData.token,
                                                },
                                                data: dataa,
                                              }

                                              axios(config)
                                                .then(function (response) {
                                                  toast.success(
                                                    response.data.message,
                                                    {
                                                      position: "top-right",
                                                      autoClose: 5000,
                                                      hideProgressBar: false,
                                                      closeOnClick: true,
                                                      pauseOnHover: true,
                                                      draggable: true,
                                                    }
                                                  )
                                                })
                                                .catch(function (error) {
                                                  console.log(error)
                                                  toast.error(
                                                    error.response &&
                                                      error.response.data
                                                        .message,
                                                    {
                                                      position: "top-right",
                                                      autoClose: 5000,
                                                      hideProgressBar: false,
                                                      closeOnClick: true,
                                                      pauseOnHover: true,
                                                      draggable: true,
                                                    }
                                                  )
                                                })
                                            }
                                          }}
                                        ></i>
                                      </Col>
                                    </Row>
                                  </div>
                                </Card>
                              )
                            )
                          })}
                      </div>
                    </div>
                    <Row className="mb-3">
                      <Col className="text-start">
                        <button
                          className="btn btn-primary w-md "
                          type="submit"
                          style={{ marginBottom: 20 }}
                        >
                          Submit
                        </button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal
        size="xl"
        isOpen={modal_xlarge}
        toggle={() => {
          tog_xlarge()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">
            Crop & Compress Images
          </h5>
          {compressedAllFiles && (
            <button
              onClick={() => {
                if (compressedAllFiles) {
                  setmodal_xlarge(false)
                }
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          )}
        </div>
        <div
          className="modal-body"
          style={{
            height: 500,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {compressLoader ? (
            <Loader />
          ) : (
            <Cropper
              image={imgSrc.src}
              crop={crop}
              zoom={zoom}
              aspect={isBannerImage ? 1 : 16 / 9}
              onCropChange={c => {
                setCrop(c)
              }}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          )}
        </div>
        <Button color="primary" onClick={showCroppedImage}>
          Submit
        </Button>
        <div
          className="modal-footer"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          {!isBannerImage &&
            selectedFiles !== null &&
            selectedFiles.map((f, i) => {
              return (
                !f._id && (
                  <Card
                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                    key={i + "-file"}
                  >
                    <div
                      className="p-2"
                      style={{
                        border: !f.compressed
                          ? "2px solid #FF6A6A"
                          : "2px solid #2FDD92",
                        borderRadius: 15,
                      }}
                    >
                      <Row className="align-items-center">
                        <Col className="col-auto">
                          <img
                            onClick={() =>
                              setImgSrc({ src: f.preview, index: i })
                            }
                            data-dz-thumbnail=""
                            height="80"
                            className="avatar-sm rounded bg-light"
                            style={{
                              objectFit: "cover",
                            }}
                            alt={f.name}
                            src={f.preview}
                          />
                        </Col>
                      </Row>
                    </div>
                  </Card>
                )
              )
            })}
        </div>
      </Modal>
    </React.Fragment>
  )
}
AddOverView.propTypes = {
  history: PropTypes.object,
  userData: PropTypes.object,
  editData: PropTypes.object,
  courseId: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    userData: state.userData,
    editData: state.userEdit,
    courseId: state.courseId,
  }
}

export default connect(mapStateToProps)(withRouter(AddOverView))
