import { COURSEID } from "./actionTypes"

// const Data = JSON.parse(localStorage.getItem("editData"))
const initialState = null

const courseId = (state = initialState, action) => {
  switch (action.type) {
    case COURSEID:
      return action.payload
    default:
      return state
  }
}

export default courseId
