import React, { useState, useRef, useEffect } from "react"
import axios from "axios"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import LayoutModal from "../../components/Custom/LayoutModel"
import { withRouter } from "react-router-dom"
import { Row, Col, Input, Label } from "reactstrap"
import Loader from "components/Custom/Loader"
import { toast } from "react-toastify"
import Tostify from "components/Custom/Tostify"
function UpdateCurrency(props) {
  // Use this state
  const [currencyList, setCurrency] = useState([])
  const [loader, setLoader] = useState(false)
  useEffect(() => {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/currencies`,
      headers: {
        "x-auth-token": props.userData.token,
      },
    }

    axios(config)
      .then(function (response) {
        setCurrency(response.data)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [])
  const handleSubmit = () => {
    var postData = []
    currencyList.map((d, i) => {
      var Dummy = {
        rate: d.rate,
        _id: d._id,
      }
      postData.push(Dummy)
    })
    setLoader(true)
    var config = {
      method: "put",
      url: `${process.env.REACT_APP_BASE_URL}/currencies`,
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": props.userData.token,
      },
      data: postData,
    }
    axios(config)
      .then(function (response) {
        setLoader(false)
        setTimeout(() => {
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        }, 200)
      })
      .catch(function (error) {
        setLoader(false)
        toast.error(error.response && error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
        console.log(error)
      })
  }
  if (loader) {
    return <Loader />
  }
  return (
    <>
      <Tostify />
      <div
        className="d-flex flex-column flex-sm-column flex-md-column flex-lg-row"
        style={{ flex: 1, minHeight: "100vh" }}
      >
        <div className="col-sm-12 col-lg-6 mb-4">
          <LayoutModal button={false} title="Country Rates">
            <Row>
              {currencyList.map((data, key) => (
                <Col lg={6} key={key}>
                  <div className="mb-3">
                    <Label className="form-label">
                      {data.name} <span style={{ color: "red" }}>*</span>
                    </Label>
                    <Input
                      name="currency"
                      id="currency"
                      className="form-control"
                      placeholder={data.name}
                      type="text"
                      onChange={e => {
                        currencyList[key].rate = e.target.value
                        setCurrency([...currencyList])
                      }}
                      value={data.rate}
                    />
                  </div>
                </Col>
              ))}
            </Row>
            <Row className="mb-3">
              <Col className="text-start">
                <button
                  className="btn btn-primary w-md "
                  type="button"
                  onClick={() => handleSubmit()}
                >
                  Submit
                </button>
              </Col>
            </Row>
          </LayoutModal>
        </div>
      </div>
    </>
  )
}

UpdateCurrency.propTypes = {
  userData: PropTypes.object,
  history: PropTypes.object,
}

const mapStateToProps = state => {
  return {
    userData: state.userData,
  }
}

export default connect(mapStateToProps)(withRouter(UpdateCurrency))
