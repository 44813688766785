import React, { useState, useRef, useEffect } from "react"
import axios from "axios"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import LayoutModal from "../../components/Custom/LayoutModel"
import {
  Row,
  Col,
  Input,
  Label,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  Button,
  Card,
} from "reactstrap"
import { withRouter, Link } from "react-router-dom"
import Loader from "components/Custom/Loader"
import { toast } from "react-toastify"
import Tostify from "components/Custom/Tostify"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import "../DatatableScss/datatables.scss"
import Selector from "components/Custom/Selector"
import pdfImage from "../../assets/images/pdfdemo.png"
function AddTemplates(props) {
  // Use this state
  const [template, setTemplate] = useState("")
  const [templateErr, setTemplateErr] = useState("")
  const [selectedCategoryErr, setSelectedCategoryErr] = useState("")
  const [templateList, setTemplateList] = useState([])
  const [editTemplate, setEditTemplate] = useState(null)
  const [rowIndexTable, setRowIndexTable] = useState()
  const [loader, setLoader] = useState(false)
  const [category, setCategory] = useState(null)
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [pdfFile, setPdfFile] = useState(null)
  useEffect(() => {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/templateCategories/0`,
      headers: {
        "x-auth-token": props.userData.token,
      },
    }
    axios(config)
      .then(function (response) {
        setCategory(response.data.categories)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [])

  useEffect(() => {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/templates/0`,
      headers: {
        "x-auth-token": props.userData.token,
      },
    }

    axios(config)
      .then(function (response) {
        setTemplateList(response.data.template)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [])
  const handleSubmit = () => {
    if (template === "") {
      return setTemplateErr("Please Enter template name")
    }
    setTemplateErr("")
    if (selectedCategory === null) {
      return setSelectedCategoryErr("Please select a category")
    }
    setSelectedCategoryErr("")

    if (!editTemplate) {
      if (pdfFile == null) {
        return toast.error("Please upload PDF file", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      }
    }

    setLoader(true)
    var data = new FormData()
    data.append("template", template)
    data.append("category", selectedCategory.value)
    pdfFile !== null && data.append("pdf", pdfFile)
    var config = {
      method: editTemplate !== null ? "put" : "post",
      url: `${process.env.REACT_APP_BASE_URL}/templates${
        editTemplate !== null ? "/" + editTemplate._id : ""
      }`,
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": props.userData.token,
      },
      data: data,
    }
    axios(config)
      .then(function (response) {
        setLoader(false)
        let values = templateList
        if (editTemplate) {
          let index = templateList.indexOf(editTemplate)
          values.splice(index, 1, {
            pdf: {
              fileSize: pdfFile ? pdfFile.size : editTemplate.pdf.fileSize,
              filename: pdfFile ? pdfFile.name : editTemplate.pdf.filename,
              originalname: pdfFile
                ? pdfFile.name
                : editTemplate.pdf.originalname,
              _id: pdfFile ? "" : editTemplate.pdf._id,
            },
            template: template,
            isActive: editTemplate.isActive,
            category: {
              _id: selectedCategory
                ? selectedCategory.value
                : editTemplate.category._id,
              name: selectedCategory
                ? selectedCategory.label
                : editTemplate.category.name,
            },
            _id: editTemplate ? editTemplate._id : response.data.id,
          })
          setTemplateList([])
          setEditTemplate(null)
          setTimeout(() => {
            setTemplateList([...values])
          }, 100)
        } else {
          values.push({
            template: template,
            pdf: {
              fileSize: pdfFile.size,
              filename: pdfFile.name,
              originalname: pdfFile.name,
            },
            isActive: true,
            category: {
              _id: selectedCategory.value,
              name: selectedCategory.label,
            },
            _id: response.data.id,
          })

          setTemplateList([])
          setTimeout(() => {
            setTemplateList([...values])
          }, 100)
        }
        setPdfFile(null)
        setTemplate("")
        setEditTemplate(null)
        setTimeout(() => {
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        }, 100)
      })
      .catch(function (error) {
        setLoader(false)
        toast.error(error.response && error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
        console.log(error)
      })
  }

  const changeStatus = data => {
    setRowIndexTable(rowIndexTable - 1)
    setLoader(true)
    var config = {
      method: "put",
      url: `${process.env.REACT_APP_BASE_URL}/templates/${data._id}/${
        data.isActive ? "0" : "1"
      }`,
      headers: {
        "x-auth-token": props.userData.token,
        "Content-Type": "application/json",
      },
    }
    axios(config)
      .then(function (response) {
        setLoader(false)
        setTimeout(() => {
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        }, 200)
        let values = templateList
        values[templateList.indexOf(data)].isActive = !data.isActive
        setTemplateList([...values])
      })
      .catch(function (error) {
        console.log(error)
        setLoader(false)
        toast.error(error.response && error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
  }
  const columns = [
    {
      dataField: "template",
      text: "Name",
      sort: true,
    },
    {
      dataField: "category.name",
      text: "Category",
      sort: true,
    },
    {
      dataField: "isActive",
      text: "Status",
      sort: true,
      hidden: props.userData.isAdmin ? false : true,
      formatter: (cellContent, row, rowIndex, field) => {
        if (row.isActive) {
          return (
            <>
              <Button
                size="sm"
                type="button"
                id={rowIndex === rowIndexTable ? "PopoverLegacy" : ""}
                className="btn btn-success"
              >
                Active
              </Button>
              {rowIndex === rowIndexTable && (
                <UncontrolledPopover
                  placement="top"
                  target="PopoverLegacy"
                  trigger="legacy"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <PopoverHeader style={{ textAlign: "center" }}>
                    Are you sure, you want to deactive This template?
                  </PopoverHeader>
                  <PopoverBody>
                    <Button
                      size="sm"
                      style={{
                        marginRight: 5,
                      }}
                      onClick={() => changeStatus(row)}
                      color="primary"
                    >
                      Yes
                    </Button>
                    <Button
                      size="sm"
                      style={{
                        marginLeft: 5,
                      }}
                      onClick={() => setRowIndexTable(rowIndexTable - 1)}
                      color="danger"
                    >
                      No
                    </Button>
                  </PopoverBody>
                </UncontrolledPopover>
              )}
            </>
          )
        } else {
          return (
            <>
              {" "}
              <Button
                size="sm"
                type="button"
                id={rowIndex === rowIndexTable ? "PopoverLegacy" : ""}
                className="btn btn-danger"
              >
                Deactive
              </Button>
              {rowIndex === rowIndexTable && (
                <UncontrolledPopover
                  placement="top"
                  target="PopoverLegacy"
                  trigger="legacy"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <PopoverHeader style={{ textAlign: "center" }}>
                    Are you sure, you want to active This template?
                  </PopoverHeader>
                  <PopoverBody>
                    <Button
                      style={{
                        marginRight: 5,
                      }}
                      size="sm"
                      onClick={() => changeStatus(row)}
                      color="primary"
                    >
                      Yes
                    </Button>
                    <Button
                      style={{
                        marginLeft: 5,
                      }}
                      size="sm"
                      onClick={() => setRowIndexTable(rowIndexTable - 1)}
                      color="danger"
                    >
                      No
                    </Button>
                  </PopoverBody>
                </UncontrolledPopover>
              )}
            </>
          )
        }
      },
      events: {
        onMouseEnter: (e, column, columnIndex, row, rowIndex) => {
          setRowIndexTable(rowIndex)
        },
      },
    },
    {
      dataField: "df1",
      isDummyField: true,
      text: "Action",
      formatter: (cellContent, row, rowIndex) => {
        return (
          <div
            style={{
              display: "flex",
            }}
          >
            <Button
              size="sm"
              type="button"
              className="btn btn-warning"
              onClick={() => {
                setTemplate(row.template)
                setEditTemplate(row)
                setSelectedCategory({
                  value: row.category._id,
                  label: row.category.name,
                })
                setLoader(true)
                setTimeout(() => {
                  setLoader(false)
                }, 300)
              }}
            >
              Edit
            </Button>
          </div>
        )
      },
      events: {
        onMouseEnter: (e, column, columnIndex, row, rowIndex) => {
          setRowIndexTable(rowIndex)
        },
      },
    },
  ]

  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChangeno,
  }) => (
    <div className="btn-group" role="group">
      {options.map(option => {
        const isSelect = currSizePerPage === `${option.page}`
        return (
          <button
            key={option.text}
            type="button"
            onClick={() => onSizePerPageChange(option.page)}
            className={`btn ${isSelect ? "btn-secondary" : "btn-primary"}`}
          >
            {option.text}
          </button>
        )
      })}
    </div>
  )

  const defaultSorted = [
    {
      dataField: "name",
      order: "asc",
    },
  ]

  const pageOptions = {
    sizePerPage: 50,
    sizePerPageRenderer,
    totalSize: templateList.length, // replace later with size(customers),
  }
  const { SearchBar } = Search
  if (loader) {
    return <Loader />
  }
  return (
    <>
      <Tostify />
      <div
        className="d-flex flex-column flex-sm-column flex-md-column flex-lg-row"
        style={{ flex: 1, minHeight: "100vh" }}
      >
        <div className="col-sm-12 col-lg-4">
          <LayoutModal
            button={false}
            title={editTemplate !== null ? "Edit Template" : "Add Template"}
          >
            <div className="mb-3">
              <Label className="form-label">
                Template Name <span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                name="template"
                id="template"
                className="form-control"
                placeholder="Template"
                type="text"
                onChange={e => setTemplate(e.target.value)}
                value={template || ""}
              />
              <span style={{ color: "red", fontSize: 10 }}>{templateErr}</span>
            </div>
            <div className="mb-3">
              <Selector
                label="Category"
                required
                data={category !== null ? category : []}
                onchange={event => setSelectedCategory(event)}
                edit={
                  editTemplate !== null && {
                    label: editTemplate.category.name,
                    value: editTemplate.category._id,
                  }
                }
              />
            </div>
            <span
              style={{
                color: "red",
                fontSize: 10,
                marginTop: -20,
                marginBottom: 10,
              }}
            >
              {selectedCategoryErr}
            </span>
            <div className="mb-3">
              <Label className="form-label">
                PDF File <span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                name="pdfupload"
                id="pdfupload"
                className="form-control"
                placeholder="Enter your Video Link"
                type="file"
                accept=".pdf"
                onChange={e => {
                  setPdfFile(e.target.files[0])
                }}
              />
            </div>
            {pdfFile !== null && (
              <Card
                className="mt-1 mb-10 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                style={{
                  backgroundColor:
                    pdfFile.size > Number(process.env.REACT_APP_FILE_SIZE)
                      ? "#ff6a6a"
                      : "#2FDD92",
                  color:
                    pdfFile.size > Number(process.env.REACT_APP_FILE_SIZE)
                      ? "#ffffff"
                      : "#131313",
                  width: "97%",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <div className="p-2" id="addpdf">
                  <Row className="align-items-center">
                    <Col className="col-auto">
                      <img
                        data-dz-thumbnail=""
                        height="80"
                        className="avatar-sm rounded bg-light"
                        style={{
                          objectFit: "cover",
                        }}
                        alt={pdfFile.name}
                        src={pdfImage}
                      />
                    </Col>
                    <Col>
                      <Link
                        to="#"
                        className="text-muted font-weight-bold"
                        style={{
                          color:
                            pdfFile.size >
                            Number(process.env.REACT_APP_FILE_SIZE)
                              ? "#ffffff"
                              : "#131313",
                        }}
                      >
                        {pdfFile.name}
                      </Link>
                      <p className="mb-0">
                        <strong>
                          {(pdfFile.size / 1048576).toFixed(2) + " MB"}
                        </strong>
                      </p>
                      <i
                        className="bx bx-no-entry"
                        style={{
                          position: "absolute",
                          top: 0,
                          right: 10,
                          color:
                            pdfFile.size >
                            Number(process.env.REACT_APP_FILE_SIZE)
                              ? "#ffffff"
                              : "#131313",
                          fontSize: 17,
                          fontWeight: 800,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setPdfFile(null)
                          document.getElementById("pdfupload").value = ""
                        }}
                      ></i>
                    </Col>
                  </Row>
                </div>
              </Card>
            )}
            {editTemplate && editTemplate.pdf && pdfFile == null && (
              <Card
                className="mt-1 mb-10 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                style={{
                  backgroundColor: "#2FDD92",
                  color: "#131313",
                  width: "97%",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <div className="p-2" id="editpdf">
                  <Row className="align-items-center">
                    <Col className="col-auto">
                      <img
                        data-dz-thumbnail=""
                        height="80"
                        className="avatar-sm rounded bg-light"
                        style={{
                          objectFit: "cover",
                        }}
                        src={pdfImage}
                      />
                    </Col>
                    <Col>
                      <Link
                        to="#"
                        className="text-muted font-weight-bold"
                        style={{
                          color: "#ffffff",
                        }}
                      >
                        {editTemplate.pdf.originalname}
                      </Link>
                      <p className="mb-0">
                        <strong>
                          {(editTemplate.pdf.fileSize / 1048576).toFixed(2) +
                            " MB"}
                        </strong>
                      </p>
                      <i
                        className="bx bx-no-entry"
                        style={{
                          position: "absolute",
                          top: 0,
                          right: 10,
                          color: "#131313",
                          fontSize: 17,
                          fontWeight: 800,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setTimeout(() => {
                            setPdfFile(null)
                          }, 100)
                          document.getElementById("editpdf").style.display =
                            "none"
                          document.getElementById("pdfupload").value = ""
                        }}
                      ></i>
                    </Col>
                  </Row>
                </div>
              </Card>
            )}
            <Row className="mb-3">
              <Col className="text-start">
                <button
                  className="btn btn-primary w-md "
                  type="button"
                  onClick={() => handleSubmit()}
                >
                  Submit
                </button>
              </Col>
            </Row>
          </LayoutModal>
        </div>
        <div className="col-sm-12 col-lg-8">
          <LayoutModal
            title="Template List"
            sideTitle="Template"
            button={false}
          >
            <PaginationProvider
              pagination={paginationFactory(pageOptions)}
              keyField="_id"
              columns={columns}
              data={templateList}
            >
              {({ paginationProps, paginationTableProps }) => (
                <ToolkitProvider
                  keyField="_id"
                  columns={columns}
                  data={templateList}
                  search
                >
                  {toolkitProps => (
                    <React.Fragment>
                      <Row className="mb-2">
                        <Col
                          md="4"
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            width: "100%",
                          }}
                        >
                          <div className="search-box me-2 mb-2 d-inline-block">
                            <div className="position-relative">
                              <SearchBar {...toolkitProps.searchProps} />
                              <i className="bx bx-search-alt search-icon" />
                            </div>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col xl="12">
                          <div className="table-responsive">
                            <BootstrapTable
                              responsive
                              bordered={false}
                              striped={true}
                              defaultSorted={defaultSorted}
                              hover
                              classes={"table align-middle table-nowrap"}
                              headerWrapperClasses={"thead-light"}
                              {...toolkitProps.baseProps}
                              {...paginationTableProps}
                            />
                          </div>
                        </Col>
                      </Row>
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              )}
            </PaginationProvider>
          </LayoutModal>
        </div>
      </div>
    </>
  )
}

AddTemplates.propTypes = {
  userData: PropTypes.object,
  editData: PropTypes.object,
  history: PropTypes.object,
}

const mapStateToProps = state => {
  return {
    userData: state.userData,
    editData: state.userEdit,
  }
}

export default connect(mapStateToProps)(withRouter(AddTemplates))
