import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
//i18n
import { withTranslation } from "react-i18next"
//redux
import { connect } from "react-redux"
import axios from "axios"
const Dashboard = props => {
  const [activateCount, setActivateCount] = useState(0)
  const [deactivateCount, setDeactivateCount] = useState(0)
  useEffect(() => {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/dashboard`,
      headers: {
        "x-auth-token": props.userData.token,
      },
    }
    axios(config)
      .then(function (response) {
        setActivateCount(response.data.activeDir)
        setDeactivateCount(response.data.deActiveDir)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            Dashboard | Indiaport - All in one mobile data input system
          </title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Dashboard")}
          />
          <Row>
            <Col xl="8">
              <Row>
                <Col md="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">Activated</p>
                          <h4 className="mb-0">{activateCount}</h4>
                        </div>
                        <div className="avatar-sm rounded-circle bg-success align-self-center mini-stat-icon">
                          <span className="avatar-title rounded-circle bg-success">
                            <i
                              className={
                                "bx bxs-check-shield" + " font-size-24"
                              }
                            ></i>
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">Deactivated</p>
                          <h4 className="mb-0">{deactivateCount}</h4>
                        </div>
                        <div className="avatar-sm rounded-circle bg-danger align-self-center mini-stat-icon">
                          <span className="avatar-title rounded-circle bg-danger">
                            <i
                              className={"bx bxs-shield-x" + " font-size-24"}
                            ></i>
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
  userData: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    userData: state.userData,
  }
}

export default connect(mapStateToProps)(withTranslation()(Dashboard))
