import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { Row, Col, Input, Form } from "reactstrap"
//redux
import { useDispatch, connect } from "react-redux"
import { withRouter } from "react-router-dom"
import axios from "axios"
import { toast } from "react-toastify"
import Loader from "components/Custom/Loader"
import { userEdit } from "../../store/actions"
const AddFeatures = props => {
  const dispatch = useDispatch()
  const [loader, setLoader] = useState(false)
  const [errorMsg, seErrorMsg] = useState("")
  const [formRows, setFormRows] = useState([
    {
      id: 1,
      name: "",
      error: "",
    },
  ])
  useEffect(() => {
    if (props.editData) {
      const data = props.editData
      const rows = []

      if (data.features) {
        data.features.map((d, i) => {
          const demoRows = {
            id: i + 1,
            name: d,
          }
          rows.push(demoRows)
        })

        if (rows.length > 0) {
          setFormRows(rows)
          data.features.map((d, i) => {
            setTimeout(() => {
              document.getElementById("name" + i).value = d
            }, 100)
          })
        }
      }
    }
    return () => dispatch(userEdit(null))
  }, [props.editData])

  const handleSubmit = () => {
    let checkError = false
    let featuresName = []
    formRows.map((d, i) => {
      if (d.name === "") {
        d.error = "Please enter feature "
        checkError = true
        setTimeout(() => {
          seErrorMsg("Please enter feature")
        }, 300)
      } else {
        d.error = ""
        featuresName.push(d.name)
        setTimeout(() => {
          seErrorMsg("")
        }, 300)
      }
    })
    if (checkError) {
      return setTimeout(() => {
        seErrorMsg("Please enter feature")
      }, 500)
    }
    var data = JSON.stringify({
      features: featuresName,
    })
    var config = {
      method: "put",
      url: `${process.env.REACT_APP_BASE_URL}/webinars/features/${
        props.webinarId ? props.webinarId : props.editData._id
      }`,
      headers: {
        "x-auth-token": props.userData.token,
        "Content-Type": "application/json",
      },
      data: data,
    }
    axios(config)
      .then(function (response) {
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
      .catch(function (error) {
        console.log(error)
        toast.error(error.response && error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
  }

  const onAddFormRow = () => {
    const modifiedRows = [...formRows]
    let lastname = modifiedRows.length
    seErrorMsg("")
    if (modifiedRows[modifiedRows.length - 1].name) {
      modifiedRows.push({
        id: modifiedRows.length + 1,
        name: "",
        error: "",
      })
    } else {
      toast.error("Please insert value", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      })
    }
    setFormRows(modifiedRows)
  }

  const onDeleteFormRow = id => {
    if (id !== 0) {
      var modifiedRows = [...formRows]
      modifiedRows.splice(id, 1)
      setFormRows(modifiedRows)
    }
  }
  if (loader) {
    return <Loader />
  }
  return (
    <>
      <Form
        className="form-horizontal"
        id="form"
        onSubmit={e => {
          e.preventDefault()
          handleSubmit()
        }}
      >
        <Row>
          <Col>
            <div>
              {(formRows || []).map((formRow, key) => (
                <Row key={key}>
                  <Col lg={3} className="mb-3">
                    {key === 0 ? (
                      <label htmlFor="name">
                        Feature <span style={{ color: "red" }}>*</span>
                      </label>
                    ) : (
                      <div style={{ marginTop: 20 }} />
                    )}
                    <Input
                      type="text"
                      id={"name" + key}
                      className="form-control"
                      placeholder="Enter Feature"
                      onChange={e => {
                        formRow.name = e.target.value
                        setFormRows([...formRows])
                      }}
                      value={formRow.name}
                    />
                    {errorMsg && formRow.error && (
                      <span style={{ color: "red" }}>{errorMsg}</span>
                    )}
                  </Col>
                  {formRow.id !== 1 && (
                    <Col lg={1} className="align-self-center">
                      <div className="d-grid">
                        <button
                          type="button"
                          className="btn btn-danger btn-sm"
                          style={{ marginTop: 10 }}
                          onClick={() => onDeleteFormRow(key)}
                        >
                          <i className="bx bx-trash"></i>
                        </button>
                      </div>
                    </Col>
                  )}
                </Row>
              ))}
            </div>
            <button
              type="button"
              className="btn btn-success mt-3 mb-3 mt-lg-0"
              onClick={() => onAddFormRow()}
            >
              <i className="bx bx-plus"></i>
            </button>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col className="text-start">
            <button
              className="btn btn-primary w-md "
              type="submit"
              style={{ marginBottom: 20 }}
            >
              Submit
            </button>
          </Col>
        </Row>
      </Form>
    </>
  )
}

AddFeatures.propTypes = {
  history: PropTypes.object,
  userData: PropTypes.object,
  editData: PropTypes.object,
  webinarId: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    userData: state.userData,
    editData: state.userEdit,
    webinarId: state.webinarId,
  }
}

export default connect(mapStateToProps)(withRouter(AddFeatures))
