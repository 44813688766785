import React, { useState, useEffect } from "react"
import axios from "axios"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import PropTypes from "prop-types"
import Selector from "components/Custom/Selector"
import { toast } from "react-toastify"
import {
  Col,
  Row,
  Label,
  Button,
  Form,
  Input,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
} from "reactstrap"
import "flatpickr/dist/themes/material_blue.css"
import Loader from "components/Custom/Loader"
const AddLessons = props => {
  const [loader, setLoader] = useState(false)
  const [sectionName, setSectionName] = useState("")
  const [sectionErr, setSectionErr] = useState("")
  const [videoLink, setVideoLinkName] = useState("")
  const [sectionData, setSectionData] = useState([])
  const [selectedSection, setSelectedSection] = useState([])
  const [selectedSectionErr, setSelectedSectionErr] = useState("")
  const [title, setTitle] = useState("")
  const [titleErr, setTitleErr] = useState("")
  const [detail, setDetail] = useState("")
  const [detailErr, setDetailErr] = useState("")
  const [lessonData, setLessionData] = useState([])
  const [editData, setEditData] = useState(false)
  const [statusPopoverSet, setstatusPopoverSet] = useState(false)
  const [editSection, setEditSection] = useState(false)
  const [editSectionId, setEditSectionId] = useState("")
  const [editLesson, setEditLesson] = useState(false)
  const [editLessonId, setEditLessonId] = useState("")
  const [sectionStatusChange, setSectionstatusChange] = useState("")
  const [lessonStatusChange, setLessonstatusChange] = useState("")
  const [lessonDeleteId, setLessonsDeleteId] = useState("")
  const [sectionDeleteId, setSectionsDeleteId] = useState("")
  const [rowIndex, setRowIndex] = useState("")
  const [lessonIndex, setLessonIndex] = useState("")
  const [sectionIndex, setSectonIndex] = useState("")

  var num = 1

  const getSectionDropdownData = () => {
    // getting section data and set in dropdown
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/l_sections/${
        props.editData ? props.editData._id : props.courseId
      }/0`,
      headers: {
        "x-auth-token": props.userData.token,
        "Content-Type": "application/json",
      },
    }

    axios(config)
      .then(function (response) {
        setSectionData(response.data.l_sections)
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const statusChangeSection = data => {
    var config = {
      method: "put",
      url: `${process.env.REACT_APP_BASE_URL}/l_sections/status/${data._id}/${
        data.isActive ? "0" : "1"
      }`,
      headers: {
        "x-auth-token": props.userData.token,
        "Content-Type": "application/json",
      },
    }
    setLoader(true)
    axios(config)
      .then(function (response) {
        let values = sectionData
        values[sectionData.indexOf(data)].isActive = !data.isActive
        setSectionData([...values])
        setLoader(false)
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
      .catch(function (error) {
        console.log(error)
        setLoader(false)
        toast.error(error.response && error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
  }

  const statusChangeLesson = data => {
    var config = {
      method: "put",
      url: `${process.env.REACT_APP_BASE_URL}/lessons/status/${data._id}/${
        data.isActive ? "0" : "1"
      }`,
      headers: {
        "x-auth-token": props.userData.token,
        "Content-Type": "application/json",
      },
    }
    setLoader(true)
    axios(config)
      .then(function (response) {
        let values = lessonData
        values[lessonData.indexOf(data)].isActive = !data.isActive
        setLessionData([...values])
        setLoader(false)
        getLessionData()
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
      .catch(function (error) {
        console.log(error)
        setLoader(false)
        toast.error(error.response && error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
  }

  const deleteLesson = data => { 
    var config = {
      method: "put",
      url: `${process.env.REACT_APP_BASE_URL}/lessons/delete/${data._id}`,
      headers: {
        "x-auth-token": props.userData.token,
        "Content-Type": "application/json",
      },
    }
    setLoader(true)
    axios(config)
      .then(function (response) {   
        setLoader(false)
        setTimeout(() => {          
          getSectionDropdownData()
          getLessionData()
        }, 200);
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
      .catch(function (error) {
        console.log(error)
        setLoader(false)
        toast.error(error.response && error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
  }

  const deleteSection = data => {   
    var config = {
      method: "put",
      url: `${process.env.REACT_APP_BASE_URL}/l_sections/delete/${data._id}`,
      headers: {
        "x-auth-token": props.userData.token,
        "Content-Type": "application/json",
      },
    }
    setLoader(true)
    axios(config)
      .then(function (response) {   
        setLoader(false)
        getLessionData()
        getSectionDropdownData()
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
      .catch(function (error) {
        console.log(error)
        setLoader(false)
        toast.error(error.response && error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
  }
  const getLessionData = () => {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/lessons/${
        props.editData ? props.editData._id : props.courseId
      }/0`,
      headers: {
        "x-auth-token": props.userData.token,
        "Content-Type": "application/json",
      },
    }

    axios(config)
      .then(function (response) {
        setLessionData(response.data.lessons)
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  useEffect(() => {
    if (props.editData || props.courseId) {
      getSectionDropdownData()
      getLessionData()
    }
  }, [])

  const sectionHandleSubmit = () => {
   
      const getId= (url)  => {
      const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
      const match = url.match(regExp);
  
      return (match && match[2].length === 11)
        ? match[2]
        : null;
  }
      
  const videoId = getId(videoLink);
  const embedVideoLink = "https://www.youtube.com/embed/" + videoId

    if (sectionName == "") {
      return setSectionErr("Please enter section ")
    } else {
      setSectionErr("")
    }

    var data = JSON.stringify({
      name: sectionName,
      url: videoLink !== "" ? embedVideoLink : "",
      course: props.courseId ? props.courseId : props.editData._id,
    })

    var config = {
      method: editSection ? "put" : "post",
      url: `${process.env.REACT_APP_BASE_URL}/l_sections${
        editSectionId ? "/" + editSectionId : ""
      }`,
      headers: {
        "x-auth-token": props.userData.token,
        "Content-Type": "application/json",
      },
      data: data,
    }
    setLoader(true)
    axios(config)
      .then(function (response) {
        setEditSection(false)
        let values = sectionData
        if (editSection) {
          values.splice(rowIndex, 1, {
            _id: editSectionId,
            name: sectionName,
            url: videoLink,
            course: props.courseId ? props.courseId : props.editData._id,
            isActive: true,
          })
          setSectionData([])
          setTimeout(() => {
            setSectionData([...values])
          }, 100)
        } else {
          const temsectionData = {
            _id: response.data.id,
            name: sectionName,
            url: videoLink,
            course: props.courseId ? props.courseId : props.editData._id,
            isActive: true,
          }
          values.push(temsectionData)
          setSectionData(values)
        }
        setSectionName("")
        setVideoLinkName("")
        setLoader(false)
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
      .catch(function (error) {
        console.log(error)
        setLoader(false)
        toast.error(error.response && error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
  }

  const lessonHandleSubmit = () => {
    if (selectedSection == "") {
      return setSelectedSectionErr("Please select section")
    } else {
      setSelectedSectionErr("")
    }
    if (title == "") {
      return setTitleErr("Please enter title")
    } else {
      setTitleErr("")
    }
    if (detail == "") {
      return setDetailErr("Please enter detail")
    } else {
      setDetailErr("")
    }
    var data = JSON.stringify({
      lSection: selectedSection._id
        ? selectedSection._id
        : selectedSection.value,
      title: title,
      details: detail,
      id: props.courseId ? props.courseId : props.editData._id,
    })

    var config = {
      method: editLesson ? "put" : "post",
      url: `${process.env.REACT_APP_BASE_URL}/lessons${
        editLessonId ? "/" + editLessonId : ""
      }`,
      headers: {
        "x-auth-token": props.userData.token,
        "Content-Type": "application/json",
      },
      data: data,
    }
    setLoader(true)
    axios(config)
      .then(function (response) {
        setEditLesson(false)
        let value = lessonData
        if (editLesson) {
          let index = lessonData.indexOf(lessonIndex)
          value.splice(index, 1, {
            _id: editLessonId,
            lSection: {
              _id: selectedSection._id
                ? selectedSection._id
                : selectedSection.value,
              name: selectedSection.name
                ? selectedSection.name
                : selectedSection.label,
            },
            title: title,
            details: detail,
            course: props.courseId ? props.courseId : props.editData._id,
            isActive: true,
          })
          setLessionData([])
          setTimeout(() => {
            setLessionData([...value])
          }, 100)
        } else {
          const templesson = {
            _id: response.data.id,
            lSection: {
              _id: selectedSection.value,
              name: selectedSection.label,
            },
            title: title,
            details: detail,
            course: props.courseId ? props.courseId : props.editData._id,
            isActive: true,
          }
          value.push(templesson)
          setLessionData(value)
        }
        setTitle("")
        setDetail("")
        setEditData(false)
        setLoader(false)
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
      .catch(function (error) {
        console.log(error)
        setLoader(false)
        toast.error(error.response && error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
  }
  if (loader) {
    return <Loader />
  }
  return (
    <>
      <div
        className="d-flex flex-column flex-sm-column flex-md-column flex-lg-row"
        style={{ flex: 1, minHeight: "100vh" }}
      >
        <div className="col-sm-12 col-lg-5">
          <div className="h4 card-title mt-2 mb-4">
            {editSection ? "Edit Section" : "Add Section"}
          </div>
          <Row>
            <Form
              onSubmit={e => {
                e.preventDefault()
                sectionHandleSubmit()
              }}
            >
              <Col md={8}>
                <div className="mb-3">
                  <Label htmlFor="formrow-email-Input">
                    Section Name <span style={{ color: "red" }}>*</span>
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="form-control"
                    placeholder="Enter Section Name"
                    onChange={e => setSectionName(e.target.value)}
                    value={sectionName}
                  />
                  <span style={{ color: "red", fontSize: 10 }}>
                    {sectionErr}
                  </span>
                </div>
              </Col>
              <Col md={8}>
                <div className="mb-3">
                  <Label htmlFor="formrow-email-Input">Video</Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="videolink"
                    placeholder="Enter Video link"
                    onChange={e => setVideoLinkName(e.target.value)}
                    value={videoLink}
                  />
                </div>
              </Col>
              <Row>
                <div>
                  <button type="submit" className="btn btn-primary w-md">
                    Submit
                  </button>
                </div>
              </Row>
            </Form>
          </Row>
          <Row>
            <div className="h4 card-title mt-4 mb-4">
              {editLesson ? "Edit Lesson" : "Add Lesson"}
            </div>
            <Form
              onSubmit={e => {
                e.preventDefault()
                lessonHandleSubmit()
              }}
            >
              <Row>
                <Col>
                  <div>
                    <Row>
                      <Col md={8}>
                        <div className="mb-3">
                          <Selector
                            label="Section"
                            required
                            data={sectionData !== null ? sectionData : []}
                            onchange={event => {
                              setSelectedSection(event)
                            }}
                            edit={
                              editData && {
                                label: selectedSection.name,
                                value: selectedSection._id,
                              }
                            }
                          />
                          <span style={{ color: "red", fontSize: 10 }}>
                            {selectedSectionErr}
                          </span>
                        </div>
                      </Col>
                      <Col md={8}>
                        <div className="mb-3">
                          <label htmlFor="about">Title</label>
                          <Input
                            type="title"
                            id="title"
                            className="form-control"
                            placeholder="Enter Title"
                            onChange={e => setTitle(e.target.value)}
                            value={title}
                          />
                          <span style={{ color: "red", fontSize: 10 }}>
                            {titleErr}
                          </span>
                        </div>
                      </Col>

                      <Col md={8}>
                        <div className="mb-3">
                          <label htmlFor="about">Detail</label>
                          <Input
                            style={{ height: "80px" }}
                            type="textarea"
                            id="detail"
                            name="detail"
                            className="form-control"
                            placeholder="Enter Detail"
                            onChange={e => setDetail(e.target.value)}
                            value={detail}
                          />
                          <span style={{ color: "red", fontSize: 10 }}>
                            {detailErr}
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
              <div>
                <button type="submit" className="btn btn-primary w-md">
                  Submit
                </button>
              </div>
            </Form>
          </Row>
        </div>

        <div className="col-sm-12 col-lg-7">
          {sectionData.map((d, i) => {
            return (
              <div
                key={i}
                className="card"
                style={{
                  backgroundColor: "#e3eaef",
                  height: "auto",
                  padding: "24px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <h5
                    style={{
                      color: "#495057",
                      fontSize: "15px",
                      marginLeft: 10,
                    }}
                  >
                    Section  :{d.name.substring(0, 15) + "..."}
                  </h5>
                  <div
                  style={{
                    marginRight: 20,
                    
                  }}
                  >
                     {d.isActive == true ? (
                      <button
                        style={{
                          marginLeft: 5,
                          marginRight: 5,
                          backgroundColor: "#34c38f",
                          borderBlockColor: "#34c38f",
                          color: "white",
                        }}
                        type="button"
                        id={sectionIndex === i ? "PopoverLegacy" : ""}
                        className="btn  btn-rounded btn-sm ml-1 "
                        name="button"
                        onMouseEnter={() => {
                          setSectonIndex(i)
                        }}
                        onClick={() => {
                          setstatusPopoverSet(true)
                          setSectionstatusChange(d)
                        }}
                      >
                        Active
                      </button>
                    ) : (
                      <button
                        style={{
                          marginLeft: 5,
                          marginRight: 5,
                        }}
                        type="button"
                        id={sectionIndex === i ? "PopoverLegacy" : ""}
                        className="btn btn-danger btn-rounded btn-sm ml-1 "
                        name="button"
                        onMouseEnter={() => {
                          setSectonIndex(i)
                        }}
                        onClick={() => {
                          setstatusPopoverSet(true)
                          setSectionstatusChange(d)
                        }}
                      >
                        Deactive
                      </button>
                    )}
                    <button
                      type="button"
                      className="btn btn-outline-secondary btn-rounded btn-sm ml-1"
                      name="button"
                      onClick={() => {
                        setLoader(true)
                        setSectionName(d.name)
                        setVideoLinkName(d.url)
                        setEditSectionId(d._id)
                        setEditSection(true)
                        setRowIndex(i)
                        setTimeout(() => {
                          setLoader(false)
                        }, 100)
                      }}
                    >
                            Edit
                    </button>
                    <button
                              type="button"
                              className="btn btn-outline-danger btn-rounded btn-sm ml-1"
                              name="button"
                              id={
                                sectionIndex === i ? "PopoverLegacySectionDelete" : ""
                              }
                              style={{
                                marginLeft: 5,
                              }}
                              onMouseEnter={() => {
                                setSectonIndex(i)
                              }}
                              onClick={() => {
                                // setLoader(true)
                                setstatusPopoverSet(true)
                                setSectionsDeleteId(d)
                              
                              }}
                            >
                              Delete
                            </button>
                            {sectionIndex === i && (
                              <UncontrolledPopover
                                placement="top"
                                target="PopoverLegacySectionDelete"
                                trigger="legacy"
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flexDirection: "column",
                                }}
                              >
                                 <PopoverHeader
                                    style={{ textAlign: "center" }}
                                  >
                                    Are you sure, you want to delete this
                                    section ?
                                  </PopoverHeader>

                                <PopoverBody>
                                  <Button
                                    size="sm"
                                    style={{
                                      marginRight: 5,
                                    }}
                                    onClick={() => {
                                      deleteSection(sectionDeleteId)
                                    }}
                                    color="primary"
                                  >
                                    Yes
                                  </Button>
                                  <Button
                                    size="sm"
                                    style={{
                                      marginLeft: 5,
                                    }}
                                    onClick={() => {
                                      setstatusPopoverSet(false)
                                      setSectonIndex("")
                                    }}
                                    color="danger"
                                  >
                                    No
                                  </Button>
                                </PopoverBody>
                              </UncontrolledPopover>
                            )}
                   
                            
                    {sectionIndex === i && (
                      <UncontrolledPopover
                        placement="top"
                        target="PopoverLegacy"
                        trigger="legacy"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        {sectionStatusChange.isActive ? (
                          <PopoverHeader style={{ textAlign: "center" }}>
                            Are you sure, you want to deactive this section ?
                          </PopoverHeader>
                        ) : (
                          <PopoverHeader style={{ textAlign: "center" }}>
                            Are you sure, you want to active this section ?
                          </PopoverHeader>
                        )}
                        <PopoverBody>
                          <Button
                            size="sm"
                            style={{
                              marginRight: 5,
                            }}
                            onClick={() => {
                              statusChangeSection(sectionStatusChange)
                            }}
                            color="primary"
                          >
                            Yes
                          </Button>
                          <Button
                            size="sm"
                            style={{
                              marginLeft: 5,
                            }}
                            onClick={() => {
                              setstatusPopoverSet(false)
                              setSectonIndex("")
                            }}
                            color="danger"
                          >
                            No
                          </Button>
                        </PopoverBody>
                      </UncontrolledPopover>
                    )}
                  </div>
                </div>

                {lessonData.map((l, li) => {
                  if (d._id == l.lSection._id) {
                    return (
                      <div key={li}>
                        <div
                          className="card-body"
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            backgroundColor: "white",
                            marginTop: 20,
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <h5>Lesson :</h5>
                            <h5
                              style={{
                                marginLeft: 5,
                              }}
                            >
                              {l.title.substring(0, 10) + "..."}
                            </h5>
                          </div>
                          <div
                          style={{
                            marginLeft: 100,
                          }}
                          >                          
                            {l.isActive ? (
                              <button
                                style={{
                                  marginLeft: 5,
                                  marginRight: 5,
                                  backgroundColor: "#34c38f",
                                  borderBlockColor: "#34c38f",
                                  color: "white",
                                }}
                                type="button"
                                id={
                                  sectionIndex === li ? "PopoverLegacy123" : ""
                                }
                                className="btn  btn-rounded btn-sm ml-1 "
                                name="button"
                                onMouseEnter={() => {
                                  setSectonIndex(li)
                                }}
                                onClick={() => {
                                  setstatusPopoverSet(true)
                                  setLessonstatusChange(l)
                                }}
                              >
                                Active
                              </button>
                            ) : (
                              <button
                                style={{
                                  marginLeft: 5,
                                  marginRight: 5,
                                }}
                                type="button"
                                id={
                                  sectionIndex === li ? "PopoverLegacy123" : ""
                                }
                                className="btn btn-danger btn-rounded btn-sm ml-1 "
                                name="button"
                                onMouseEnter={() => {
                                  setSectonIndex(li)
                                }}
                                onClick={() => {
                                  setstatusPopoverSet(true)
                                  setLessonstatusChange(l)
                                }}
                              >
                                Deactive
                              </button>                              
                            )}                            
                            {sectionIndex === li && (
                              <UncontrolledPopover
                                placement="top"
                                target="PopoverLegacy123"
                                trigger="legacy"
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flexDirection: "column",
                                }}
                              >
                                {lessonStatusChange.isActive ? (
                                  <PopoverHeader
                                    style={{ textAlign: "center" }}
                                  >
                                    Are you sure, you want to deactive this
                                    lesson ?
                                  </PopoverHeader>
                                ) : (
                                  <PopoverHeader
                                    style={{ textAlign: "center" }}
                                  >
                                    Are you sure, you want to active this lesson
                                    ?
                                  </PopoverHeader>
                                )}

                                <PopoverBody>
                                  <Button
                                    size="sm"
                                    style={{
                                      marginRight: 5,
                                    }}
                                    onClick={() => {
                                      statusChangeLesson(lessonStatusChange)
                                    }}
                                    color="primary"
                                  >
                                    Yes
                                  </Button>
                                  <Button
                                    size="sm"
                                    style={{
                                      marginLeft: 5,
                                    }}
                                    onClick={() => {
                                      setstatusPopoverSet(false)
                                      setSectonIndex("")
                                    }}
                                    color="danger"
                                  >
                                    No
                                  </Button>
                                </PopoverBody>
                              </UncontrolledPopover>
                            )}
                              <button
                              type="button"
                              className="btn btn-outline-secondary btn-rounded btn-sm ml-1"
                              name="button"
                              onClick={() => {
                                setLoader(true)
                                setEditData(true)
                                setTitle(l.title)
                                setDetail(l.details)
                                setSelectedSection(l.lSection)
                                setEditLesson(true)
                                setEditLessonId(l._id)
                                setLessonIndex(l)
                                setTimeout(() => {
                                  setLoader(false)
                                }, 100)
                              }}
                            >
                              Edit
                            </button>

                            <button
                              type="button"
                              className="btn btn-outline-danger btn-rounded btn-sm ml-1"
                              name="button"
                              id={
                                sectionIndex === li ? "PopoverLegacyDelete" : ""
                              }
                              style={{
                                marginLeft: 5,
                              }}
                              onMouseEnter={() => {
                                setSectonIndex(li)
                              }}
                              onClick={() => {
                                setstatusPopoverSet(true)
                                setLessonsDeleteId(l)
                              
                              }}
                            >
                              <i className="bi bi-trash"></i>Delete 
                            </button>
                            {sectionIndex === li && (
                              <UncontrolledPopover
                                placement="top"
                                target="PopoverLegacyDelete"
                                trigger="legacy"
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flexDirection: "column",
                                }}
                              >
                                 <PopoverHeader
                                    style={{ textAlign: "center" }}
                                  >
                                    Are you sure, you want to delete this
                                    lesson ?
                                  </PopoverHeader>

                                <PopoverBody>
                                  <Button
                                    size="sm"
                                    style={{
                                      marginRight: 5,
                                    }}
                                    onClick={() => {
                                      deleteLesson(lessonDeleteId)
                                    }}
                                    color="primary"
                                  >
                                    Yes
                                  </Button>
                                  <Button
                                    size="sm"
                                    style={{
                                      marginLeft: 5,
                                    }}
                                    onClick={() => {
                                      setstatusPopoverSet(false)
                                      setSectonIndex("")
                                    }}
                                    color="danger"
                                  >
                                    No
                                  </Button>
                                </PopoverBody>
                              </UncontrolledPopover>
                            )}
                          </div>
                        </div>
                      </div>
                    )
                  }
                })}
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

AddLessons.propTypes = {
  history: PropTypes.object,
  userData: PropTypes.object,
  editData: PropTypes.object,
  courseId: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    userData: state.userData,
    editData: state.userEdit,
    courseId: state.courseId,
  }
}

export default connect(mapStateToProps)(withRouter(AddLessons))
