import React, { useState, useRef, useEffect } from "react"
import axios from "axios"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import LayoutModal from "../../components/Custom/LayoutModel"
import { withRouter } from "react-router-dom"
import {
  Row,
  Col,
  Input,
  Label,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  Button,
} from "reactstrap"
import Loader from "components/Custom/Loader"
import { toast } from "react-toastify"
import Tostify from "components/Custom/Tostify"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import "../DatatableScss/datatables.scss"
import Selector from "components/Custom/Selector"
function AddCity(props) {
  // Use this state
  const [city, setCity] = useState("")
  const [cityErr, setCityErr] = useState("")
  const [selectedStateErr, setSelectedStateErr] = useState("")
  const [cityList, setCityList] = useState([])
  const [editCity, setEditCity] = useState(null)
  const [rowIndexTable, setRowIndexTable] = useState()
  const [loader, setLoader] = useState(false)
  const [states, setStates] = useState(null)
  const [selectedState, setSelectedState] = useState(null)
  useEffect(() => {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/states`,
      headers: {
        "x-auth-token": props.userData.token,
      },
    }
    axios(config)
      .then(function (response) {
        setStates(response.data)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [])

  useEffect(() => {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/cities/0`,
      headers: {
        "x-auth-token": props.userData.token,
      },
    }

    axios(config)
      .then(function (response) {
        setCityList(response.data)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [])
  const handleSubmit = () => {
    if (city === "") {
      return setCityErr("Please Enter city name")
    }
    if (selectedState === null) {
      return setSelectedStateErr("Please select a state")
    }
    setSelectedStateErr("")
    setCityErr("")
    setLoader(true)
    var data = JSON.stringify({
      name: city,
      state: selectedState.value,
    })
    var config = {
      method: editCity !== null ? "put" : "post",
      url: `${process.env.REACT_APP_BASE_URL}/cities${
        editCity !== null ? "/" + editCity._id : ""
      }`,
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": props.userData.token,
      },
      data: data,
    }
    axios(config)
      .then(function (response) {
        let values = cityList
        if (editCity !== null) {
          let index = cityList.indexOf(editCity)
          values.splice(index, 1, {
            name: city,
            isActive: editCity.isActive,
            state: { _id: selectedState.value, name: selectedState.label },
            _id: response.data.id,
          })
          setCityList([])
          setEditCity(null)
          setTimeout(() => {
            setCityList([...values])
          }, 100)
        } else {
          values.push({
            name: city,
            isActive: true,
            state: { _id: selectedState.value, name: selectedState.label },
            _id: response.data.id,
          })
          setCityList([])
          setTimeout(() => {
            setCityList([...values])
          }, 100)
        }
        setLoader(false)
        setCity("")
        setTimeout(() => {
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        }, 200)
      })
      .catch(function (error) {
        setLoader(false)
        toast.error(error.response && error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
        console.log(error)
      })
  }

  const changeStatus = data => {
    setRowIndexTable(rowIndexTable - 1)
    setLoader(true)
    var config = {
      method: "put",
      url: `${process.env.REACT_APP_BASE_URL}/cities/${data._id}/${
        data.isActive ? "0" : "1"
      }`,
      headers: {
        "x-auth-token": props.userData.token,
        "Content-Type": "application/json",
      },
    }

    axios(config)
      .then(function (response) {
        setLoader(false)
        setTimeout(() => {
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        }, 200)
        let values = cityList
        values[cityList.indexOf(data)].isActive = !data.isActive
        setCityList([...values])
      })
      .catch(function (error) {
        console.log(error)
        setLoader(false)
        toast.error(error.response && error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
  }
  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "state.name",
      text: "State",
      sort: true,
    },
    {
      dataField: "isActive",
      text: "Status",
      sort: true,
      hidden: props.userData.isAdmin ? false : true,
      formatter: (cellContent, row, rowIndex, field) => {
        if (row.isActive) {
          return (
            <>
              <Button
                size="sm"
                type="button"
                id={rowIndex === rowIndexTable ? "PopoverLegacy" : ""}
                className="btn btn-success"
              >
                Active
              </Button>
              {rowIndex === rowIndexTable && (
                <UncontrolledPopover
                  placement="top"
                  target="PopoverLegacy"
                  trigger="legacy"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <PopoverHeader style={{ textAlign: "center" }}>
                    Are you sure, you want to deactive this city?
                  </PopoverHeader>
                  <PopoverBody>
                    <Button
                      size="sm"
                      style={{
                        marginRight: 5,
                      }}
                      onClick={() => changeStatus(row)}
                      color="primary"
                    >
                      Yes
                    </Button>
                    <Button
                      size="sm"
                      style={{
                        marginLeft: 5,
                      }}
                      onClick={() => setRowIndexTable(rowIndexTable - 1)}
                      color="danger"
                    >
                      No
                    </Button>
                  </PopoverBody>
                </UncontrolledPopover>
              )}
            </>
          )
        } else {
          return (
            <>
              {" "}
              <Button
                size="sm"
                type="button"
                id={rowIndex === rowIndexTable ? "PopoverLegacy" : ""}
                className="btn btn-danger"
              >
                Deactive
              </Button>
              {rowIndex === rowIndexTable && (
                <UncontrolledPopover
                  placement="top"
                  target="PopoverLegacy"
                  trigger="legacy"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <PopoverHeader style={{ textAlign: "center" }}>
                    Are you sure, you want to active this city?
                  </PopoverHeader>
                  <PopoverBody>
                    <Button
                      style={{
                        marginRight: 5,
                      }}
                      size="sm"
                      onClick={() => changeStatus(row)}
                      color="primary"
                    >
                      Yes
                    </Button>
                    <Button
                      style={{
                        marginLeft: 5,
                      }}
                      size="sm"
                      onClick={() => setRowIndexTable(rowIndexTable - 1)}
                      color="danger"
                    >
                      No
                    </Button>
                  </PopoverBody>
                </UncontrolledPopover>
              )}
            </>
          )
        }
      },
      events: {
        onMouseEnter: (e, column, columnIndex, row, rowIndex) => {
          setRowIndexTable(rowIndex)
        },
      },
    },
    {
      dataField: "df1",
      isDummyField: true,
      text: "Action",
      formatter: (cellContent, row, rowIndex) => {
        return (
          <div
            style={{
              display: "flex",
            }}
          >
            <Button
              size="sm"
              type="button"
              className="btn btn-warning"
              onClick={() => {
                setCity(row.name)
                setEditCity(row)
                setSelectedState({
                  value: row.state._id,
                  label: row.state.name,
                })
                setLoader(true)
                setTimeout(() => {
                  setLoader(false)
                }, 300)
              }}
            >
              Edit
            </Button>
          </div>
        )
      },
      events: {
        onMouseEnter: (e, column, columnIndex, row, rowIndex) => {
          setRowIndexTable(rowIndex)
        },
      },
    },
  ]

  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChangeno,
  }) => (
    <div className="btn-group" role="group">
      {options.map(option => {
        const isSelect = currSizePerPage === `${option.page}`
        return (
          <button
            key={option.text}
            type="button"
            onClick={() => onSizePerPageChange(option.page)}
            className={`btn ${isSelect ? "btn-secondary" : "btn-primary"}`}
          >
            {option.text}
          </button>
        )
      })}
    </div>
  )

  const defaultSorted = [
    {
      dataField: "name",
      order: "asc",
    },
  ]

  const pageOptions = {
    sizePerPage: 10,
    sizePerPageRenderer,
    totalSize: cityList.length, // replace later with size(customers),
  }
  const { SearchBar } = Search
  if (loader) {
    return <Loader />
  }
  return (
    <>
      <Tostify />
      <div
        className="d-flex flex-column flex-sm-column flex-md-column flex-lg-row"
        style={{ flex: 1, minHeight: "100vh" }}
      >
        <div className="col-sm-12 col-lg-4">
          <LayoutModal
            button={false}
            title={editCity !== null ? "Edit City" : "Add City"}
          >
            <div className="mb-3">
              <Label className="form-label">
                City <span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                name="city"
                id="city"
                className="form-control"
                placeholder="City"
                type="text"
                onChange={e => setCity(e.target.value)}
                value={city || ""}
              />
              <span style={{ color: "red", fontSize: 10 }}>{cityErr}</span>
            </div>
            <div className="mb-3">
              <Selector
                label="States"
                required
                data={states !== null ? states : []}
                onchange={event => setSelectedState(event)}
                edit={
                  editCity !== null && {
                    label: editCity.state.name,
                    value: editCity.state._id,
                  }
                }
              />
            </div>
            <span
              style={{
                color: "red",
                fontSize: 10,
                marginTop: -20,
                marginBottom: 10,
              }}
            >
              {selectedStateErr}
            </span>
            <Row className="mb-3">
              <Col className="text-start">
                <button
                  className="btn btn-primary w-md "
                  type="button"
                  onClick={() => handleSubmit()}
                >
                  Submit
                </button>
              </Col>
            </Row>
          </LayoutModal>
        </div>
        <div className="col-sm-12 col-lg-8">
          <LayoutModal title="City List" sideTitle="City" button={false}>
            <PaginationProvider
              pagination={paginationFactory(pageOptions)}
              keyField="name"
              columns={columns}
              data={cityList}
            >
              {({ paginationProps, paginationTableProps }) => (
                <ToolkitProvider
                  keyField="name"
                  columns={columns}
                  data={cityList}
                  search
                >
                  {toolkitProps => (
                    <React.Fragment>
                      <Row className="mb-2">
                        <Col
                          md="4"
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            width: "100%",
                          }}
                        >
                          <div className="search-box me-2 mb-2 d-inline-block">
                            <div className="position-relative">
                              <SearchBar {...toolkitProps.searchProps} />
                              <i className="bx bx-search-alt search-icon" />
                            </div>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col xl="12">
                          <div className="table-responsive">
                            <BootstrapTable
                              responsive
                              bordered={false}
                              striped={true}
                              defaultSorted={defaultSorted}
                              hover
                              classes={"table align-middle table-nowrap"}
                              headerWrapperClasses={"thead-light"}
                              {...toolkitProps.baseProps}
                              {...paginationTableProps}
                            />
                          </div>
                        </Col>
                      </Row>
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              )}
            </PaginationProvider>
          </LayoutModal>
        </div>
      </div>
    </>
  )
}

AddCity.propTypes = {
  userData: PropTypes.object,
  editData: PropTypes.object,
  history: PropTypes.object,
}

const mapStateToProps = state => {
  return {
    userData: state.userData,
    editData: state.userEdit,
  }
}

export default connect(mapStateToProps)(withRouter(AddCity))
