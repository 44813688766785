import React from "react"
import { useState, useEffect } from "react"
import Select from "react-select"
import PropTypes from "prop-types"

function Selector(props) {
  const [selectData, setSelectData] = useState([])

  useEffect(() => {
    let arrayOfData = []
    props.data &&
      props.data.map((d, i) => {
        var val = { value: d._id, label: d.name }
        arrayOfData.push(val)
      })
    setSelectData(arrayOfData)
  }, [props])

  const customStyles = {
    container: provided => ({
      ...provided,
      width: 300,
    }),

    menu: (provided, state) => ({
      ...provided,
      maxHeight: 150,
      width: 300,
    }),
    menuList: provided => ({
      ...provided,
      maxHeight: 150,
    }),
  }

  return (
    <div
      className="col-12"
      style={{
        marginBottom: 10,
      }}
    >
      <label>
        {props.label}{" "}
        {props.required && <span style={{ color: "red" }}>*</span>}
      </label>
      <Select
        isDisabled={props.isDisable}
        value={props.valueNull}
        ref={props.refValue}
        defaultValue={props.edit != null && props.edit}
        theme={theme => ({
          ...theme,
          borderRadius: 5,

          colors: {
            ...theme.colors,
          },
        })}
        isMulti={props.isMulti}
        onChange={props.onchange}
        options={selectData}
      />
    </div>
  )
}

Selector.propTypes = {
  edit: PropTypes.any,
  data: PropTypes.any,
  label: PropTypes.string,
  onchange: PropTypes.any,
  fullWidth: PropTypes.any,
  required: PropTypes.bool,
  classname: PropTypes.any,
  isMulti: PropTypes.bool,
  key: PropTypes.any,
  isDisable: PropTypes.bool,
  valueNull: PropTypes.any,
  refValue: PropTypes.any,
}

export default Selector
