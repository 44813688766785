import React, { useState, useEffect, useCallback } from "react"
import axios from "axios"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import LayoutModal from "../../components/Custom/LayoutModel"
import Dropzone from "react-dropzone"
import { withRouter, Link } from "react-router-dom"
import Cropper from "react-easy-crop"
import getCroppedImg from "pages/directory/getCroppedImg"
import imageCompression from "browser-image-compression"
import { Row, Col, Card, Input, Label, Button, Modal } from "reactstrap"
import Loader from "components/Custom/Loader"
import { toast } from "react-toastify"
import Tostify from "components/Custom/Tostify"
import { useDispatch } from "react-redux"
import { userEdit } from "../../store/actions"
import "../DatatableScss/datatables.scss"
function AddCompany(props) {
  const [name, setName] = useState("")
  const [tagline, setTagline] = useState("")
  const [GSTnumber, setGSTnumber] = useState("")
  const [nameErr, setNameErr] = useState("")
  const [taglineErr, setTaglineErr] = useState("")
  const [GSTErr, setGSTErr] = useState("")
  const [modal_xlarge, setmodal_xlarge] = useState(false)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [rotation, setRotation] = useState(0)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [imgSrc, setImgSrc] = useState({ src: "", index: "" })
  const [selectedFiles, setselectedFiles] = useState(null)
  const [isBannerImage, setIsBannerImage] = useState(false)
  const [compressedAllFiles, setCompressAllFiles] = useState(false)
  const [loader, setLoader] = useState(false)
  const [bannerImageFile, setBannerImageFile] = useState()
  const [fileErr, setFileErr] = useState("")
  const [compressLoader, setCompressLoader] = useState(false)
  const [zoom, setZoom] = useState(1)
  const [filesImage, setFilesImage] = useState(null)
  const dispatch = useDispatch()
  const [editSelectedFile, setEditSelectedFiles] = useState(null)
  const [doc, setDoc] = useState(null)

  useEffect(() => {
    const data = props.editData

    if (props.editData) {
      const files = []
      data.pics.map((d, i) => {
        Object.assign(d, {
          preview: process.env.REACT_APP_IMAGE_URL + "company/" + d.filename,
          formattedSize: formatBytes(d.fileSize),
          compressed: true,
          name: d.originalname,
        })
        files.push(d)
      })
      setselectedFiles([...files])
      setEditSelectedFiles(files.length)
      setName(data.name)
      setTagline(data.tagLine)
      setGSTnumber(data.gst)
      setTimeout(() => {
        document.getElementById("name").value =
          data.name !== null ? data.name : ""
        document.getElementById("tagLine").value =
          data.tagLine !== null ? data.tagLine : ""
        document.getElementById("gstnumber").value =
          data.gst !== null ? data.gst : ""
      }, 100)
    } else {
      setTimeout(() => {
        document.getElementById("name").value = ""
        document.getElementById("tagLine").value = ""
        document.getElementById("gstnumber").value = ""
      }, 100)
    }

    return () => dispatch(userEdit(null))
  }, [props.editData])

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  useEffect(() => {
    if (modal_xlarge) {
      setCompressAllFiles(false)
    }
  }, [modal_xlarge])

  const showCroppedImage = useCallback(async () => {
    setCompressLoader(true)

    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    }

    let filterBackendImages = []
    let filterUploadedImages = []

    selectedFiles !== null &&
      selectedFiles.map((d, i) => {
        if (!d._id) {
          filterUploadedImages.push(d)
        } else {
          filterBackendImages.push(d)
        }
      })

    let fileCompressed =
      selectedFiles !== null && !isBannerImage ? filterUploadedImages : []
    try {
      const croppedImage = await getCroppedImg(
        imgSrc.src,
        croppedAreaPixels,
        rotation
      )
      const compressFile = async file => {
        try {
          const data = await imageCompression(file, options).then(f => {
            Object.assign(f, {
              preview: URL.createObjectURL(f),
              originalPreview: URL.createObjectURL(file),
              formattedSize: formatBytes(f.size),
              originalSize: formatBytes(
                isBannerImage
                  ? filesImage.size
                  : selectedFiles[imgSrc.index].size
              ),
              name: isBannerImage
                ? filesImage.name
                : selectedFiles[imgSrc.index].name,
              compressed: true,
              bannerImage: false,
            })
            fileCompressed.splice(imgSrc.index, 1, f)
            return f
          })
          fileCompressed.length - 1 > Number(imgSrc.index)
            ? setImgSrc({
                src: fileCompressed[Number(imgSrc.index) + 1].preview,
                index: Number(imgSrc.index) + 1,
              })
            : setmodal_xlarge(!modal_xlarge)
          setCompressLoader(false)
          let result = true
          fileCompressed.map((d, i) => {
            if (i < fileCompressed.length - 1) {
              result = d.compressed && fileCompressed[i + 1].compressed
            }
          })
          setCompressAllFiles(result)
          if (!isBannerImage) {
            setselectedFiles([...fileCompressed, ...filterBackendImages])
          } else {
            setFilesImage(fileCompressed[0])
          }
        } catch (e) {
          console.log(e)
        }
      }
      compressFile(croppedImage)
    } catch (e) {
      console.error(e)
    }
  }, [croppedAreaPixels, rotation, imgSrc])

  function tog_xlarge() {
    if (compressedAllFiles) {
      setmodal_xlarge(!modal_xlarge)
    }
  }

  function companyLogoAcceptedFiles(files, isBanner) {
    try {
      files.map(file => {
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          originalPreview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
          originalSize: formatBytes(file.size),
          compressed: false,
          bannerImage: false,
        })
      })
      setImgSrc({ src: files[0].preview, index: 0 })
    } catch (e) {
      console.log(e)
    }
  }

  function handleAcceptedFiles(files, isBanner) {
    const options = {
      maxSizeMB: 1.5,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    }

    const filesLength = Number(files.length)
    const prevFilesLength = Number(
      selectedFiles !== null ? selectedFiles.length : 0
    )

    if (filesLength + prevFilesLength > 5) {
      toast.error("Upload atmost 5 images", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      })
      setmodal_xlarge(false)
      return
    }
    let fileCompressed = []
    if (isBanner) {
      files.map(file => {
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          originalPreview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
          originalSize: formatBytes(file.size),
          compressed: false,
          bannerImage: false,
        })
      })
      setFilesImage(files)
      setImgSrc({ src: files[0].preview, index: 0 })
    } else {
      if (selectedFiles !== null && selectedFiles.length > 0) {
        files.map(file => {
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            originalPreview: URL.createObjectURL(file),
            formattedSize: formatBytes(file.size),
            originalSize: formatBytes(file.size),
            compressed: false,
            bannerImage: false,
          })
          selectedFiles.unshift(file)
          fileCompressed = selectedFiles
          setselectedFiles([...selectedFiles])
          setImgSrc({ src: selectedFiles[0].preview, index: 0 })
        })
      } else {
        files.map(file => {
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            originalPreview: URL.createObjectURL(file),
            formattedSize: formatBytes(file.size),
            originalSize: formatBytes(file.size),
            compressed: false,
            bannerImage: false,
          })
        })
        setselectedFiles(files)
        setImgSrc({ src: files[0].preview, index: 0 })
      }
    }
  }
  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const handleSubmit = () => {
    if (name === "") {
      return setNameErr("Please Enter Name")
    } else {
      setNameErr("")
    }
    if (tagline === "") {
      return setTaglineErr("Please Enter Tagline")
    } else {
      setTaglineErr("")
    }
    if (GSTnumber === "") {
      return setGSTErr("Please Enter GST Number")
    } else {
      setGSTErr("")
    }
    if (!props.editData) {
      if (doc === null) {
        return toast.error("Please upload company logo", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      }
    }
    if (selectedFiles === null) {
      return toast.error("Please upload images", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      })
    }

    setNameErr("")
    setGSTErr("")
    setTaglineErr("")

    setLoader(true)

    var data = new FormData()
    data.append("name", name)
    data.append("tagLine", tagline)
    data.append("gst", GSTnumber)

    if (doc !== null) {
      let fileImage = new File([doc], doc.name)
      !doc._id && data.append("logo", fileImage)
    }

    selectedFiles !== null &&
      selectedFiles.map((d, i) => {
        if (d._id) {
          return
        }
        data.append("pics", d, d.name)
      })

    var config = {
      method: props.editData ? "put" : "post",
      url: `${process.env.REACT_APP_BASE_URL}/companies${
        props.editData ? "/update/" + props.editData._id : ""
      }`,
      headers: {
        "x-auth-token": props.userData.token,
        "Content-Type": "application/json",
      },
      data: data,
    }

    axios(config)
      .then(function (response) {
        setLoader(false)
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
        setDoc(null)
        setName("")
        setTagline("")
        setGSTnumber("")
        setTimeout(() => {
          props.history.push("/company-list")
        }, 2000)
      })
      .catch(function (error) {
        setLoader(false)
        console.log(error)
        toast.error(error.response && error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
  }

  return (
    <>
      <Tostify />
      <div
        className="d-flex flex-column flex-sm-column flex-md-column flex-lg-row"
        style={{ flex: 1, minHeight: "100vh" }}
      >
        <div className="col-sm-12 col-lg-6">
          <LayoutModal
            button={true}
            title={props.editData ? "Edit Compnany" : "Add Compnany"}
            btnTitle={"Company List"}
            onclick={() => {
              props.history.push("/company-list")
            }}
          >
            <div className="mb-3">
              <Label className="form-label">
                Name <span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                name="name"
                id="name"
                className="form-control"
                placeholder="Enter your Name."
                type="text"
                onChange={e => setName(e.target.value)}
                value={name || ""}
              />
              <span style={{ color: "red", fontSize: 10 }}>{nameErr}</span>
            </div>
            <div className="mb-3">
              <Label className="form-label">
                Tagline <span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                name="tagLine"
                id="tagLine"
                className="form-control"
                placeholder="Enter your Tagline."
                type="text"
                onChange={e => setTagline(e.target.value)}
                value={tagline || ""}
              />
              <span style={{ color: "red", fontSize: 10 }}>{taglineErr}</span>
            </div>
            <div className="mb-3">
              <Label className="form-label">
                GST <span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                name="gstnumber"
                id="gstnumber"
                className="form-control"
                placeholder="Enter GST Number."
                type="text"
                onChange={e => setGSTnumber(e.target.value)}
                value={GSTnumber || ""}
              />
              <span style={{ color: "red", fontSize: 10 }}>{GSTErr}</span>
            </div>
            <div className="mb-3">
              <div
                style={{
                  height: 100,
                  width: 100,
                  backgroundColor: "#f2f2f5",
                  // marginLeft:"auto",
                  marginBottom: 20,
                  border:
                    doc !== null ? "2px dashed #1D9Bf0" : "2px dashed #1D9Bf0",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <input
                  style={{
                    opacity: 0,
                    height: 100,
                    width: 100,
                    position: "absolute",
                  }}
                  onChange={e => {
                    if (typeof e.target.files[0] !== "undefined") {
                      companyLogoAcceptedFiles([e.target.files[0]])
                      setDoc(e.target.files[0])
                    }
                  }}
                  type="file"
                />
                {doc !== null || props.editData ? (
                  <img
                    src={
                      doc !== null
                        ? doc.preview
                        : `${process.env.REACT_APP_IMAGE_URL}company/${props.editData.file.filename}`
                    }
                    alt="img"
                    style={{
                      height: 98,
                      width: 98,
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <Label
                    style={{
                      textAlign: "center",
                      fontSize: 10,
                      marginTop: 12,
                    }}
                    className="form-label"
                  >
                    Upload Company Logo <span style={{ color: "red" }}>*</span>
                  </Label>
                )}
              </div>
            </div>
            <div className="mb-3">
              <Dropzone
                accept="image/png, image/jpeg"
                onDrop={acceptedFiles => {
                  if (acceptedFiles.length < 6) {
                    let isBanner = false
                    setIsBannerImage(false)
                    setmodal_xlarge(true)
                    handleAcceptedFiles(acceptedFiles, isBanner)
                  } else {
                    toast.error("Select atmost 5 Images", {
                      position: "top-right",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                    })
                  }
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <div className="dropzone">
                    <div
                      className="dz-message needsclick mt-2"
                      {...getRootProps()}
                    >
                      <input {...getInputProps()} />
                      <div className="mb-3">
                        <i className="display-4 text-muted bx bxs-cloud-upload" />
                      </div>
                      <h4>Drop files here or click to upload.</h4>
                    </div>
                  </div>
                )}
              </Dropzone>
              <span style={{ color: "red", fontSize: 10 }}>{fileErr}</span>
              <div className="dropzone-previews mt-3" id="file-previews">
                {selectedFiles !== null &&
                  selectedFiles.map((f, i) => {
                    return (
                      f.compressed && (
                        <Card
                          className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                          key={i + "-file"}
                        >
                          <div className="p-2">
                            <Row className="align-items-center">
                              <Col className="col-auto">
                                <img
                                  className="avatar-sm rounded bg-light"
                                  style={{
                                    objectFit: "contain",
                                    height: 70,
                                    width: 130,
                                  }}
                                  alt={f.name}
                                  src={f.preview}
                                />
                              </Col>
                              <Col>
                                <Link
                                  to="#"
                                  className="text-muted font-weight-bold"
                                >
                                  {f.name}
                                </Link>
                                <p className="mb-0">
                                  <strong>{f.formattedSize}</strong>
                                </p>
                                <i
                                  className="bx bx-no-entry"
                                  style={{
                                    position: "absolute",
                                    top: 0,
                                    right: 10,
                                    fontSize: 17,
                                    fontWeight: 800,
                                    cursor: "pointer",
                                  }}
                                  onClick={e => {
                                    e.stopPropagation()
                                    let data = selectedFiles.splice(i, 1)

                                    if (
                                      selectedFiles.length > 0 &&
                                      i == 0 &&
                                      data[0].bannerImage == true
                                    ) {
                                      selectedFiles[0].bannerImage = true
                                      setBannerImageFile(selectedFiles[0])
                                    }
                                    if (
                                      selectedFiles.length > 0 &&
                                      i > 0 &&
                                      data[0].bannerImage == true
                                    ) {
                                      selectedFiles[i - 1].bannerImage = true
                                      setBannerImageFile(selectedFiles[i - 1])
                                    }
                                    setselectedFiles([...selectedFiles])
                                    if (props.editData && f._id) {
                                      var dataa = JSON.stringify({
                                        companyId:
                                          props.editData && props.editData._id,
                                        picId: f._id,
                                      })

                                      var config = {
                                        method: "put",
                                        url: `${process.env.REACT_APP_BASE_URL}/companies/removeImage`,
                                        headers: {
                                          "Content-Type": "application/json",
                                          "x-auth-token": props.userData.token,
                                        },
                                        data: dataa,
                                      }

                                      axios(config)
                                        .then(function (response) {
                                          toast.success(response.data.message, {
                                            position: "top-right",
                                            autoClose: 5000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: true,
                                          })
                                        })
                                        .catch(function (error) {
                                          console.log(error)
                                          toast.error(
                                            error.response &&
                                              error.response.data.message,
                                            {
                                              position: "top-right",
                                              autoClose: 5000,
                                              hideProgressBar: false,
                                              closeOnClick: true,
                                              pauseOnHover: true,
                                              draggable: true,
                                            }
                                          )
                                        })
                                    }
                                  }}
                                ></i>
                              </Col>
                            </Row>
                          </div>
                        </Card>
                      )
                    )
                  })}
              </div>
            </div>
            <Row className="mb-3">
              <Col className="text-start">
                <button
                  className="btn btn-primary w-md "
                  type="button"
                  style={{ marginBottom: 20 }}
                  onClick={() => handleSubmit()}
                >
                  Submit
                </button>
              </Col>
            </Row>
          </LayoutModal>
        </div>
      </div>
      <Modal
        size="xl"
        isOpen={modal_xlarge}
        toggle={() => {
          tog_xlarge()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">
            Crop & Compress Images
          </h5>
          {compressedAllFiles && (
            <button
              onClick={() => {
                if (compressedAllFiles) {
                  setmodal_xlarge(false)
                }
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          )}
        </div>
        <div
          className="modal-body"
          style={{
            height: 500,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {compressLoader ? (
            <Loader />
          ) : (
            <Cropper
              image={imgSrc.src}
              crop={crop}
              zoom={zoom}
              aspect={isBannerImage ? 1 : 16 / 9}
              onCropChange={c => {
                setCrop(c)
              }}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          )}
        </div>
        <Button color="primary" onClick={showCroppedImage}>
          Submit
        </Button>
        <div
          className="modal-footer"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          {!isBannerImage &&
            selectedFiles !== null &&
            selectedFiles.map((f, i) => {
              return (
                !f._id && (
                  <Card
                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                    key={i + "-file"}
                  >
                    <div
                      className="p-2"
                      style={{
                        border: !f.compressed
                          ? "2px solid #FF6A6A"
                          : "2px solid #2FDD92",
                        borderRadius: 15,
                      }}
                    >
                      <Row className="align-items-center">
                        <Col className="col-auto">
                          <img
                            onClick={() =>
                              setImgSrc({ src: f.preview, index: i })
                            }
                            data-dz-thumbnail=""
                            height="80"
                            className="avatar-sm rounded bg-light"
                            style={{
                              objectFit: "cover",
                            }}
                            alt={f.name}
                            src={f.preview}
                          />
                        </Col>
                      </Row>
                    </div>
                  </Card>
                )
              )
            })}
        </div>
      </Modal>
    </>
  )
}

AddCompany.propTypes = {
  companyData: PropTypes.object,
  editCompany: PropTypes.any,
  history: PropTypes.any,
  editData: PropTypes.any,
  userData: PropTypes.any,
}
const mapStateToProps = state => {
  return {
    companyData: state.companyData,
    editData: state.userEdit,
    userData: state.userData,
  }
}
export default connect(mapStateToProps)(withRouter(AddCompany))
