import { connect } from "react-redux"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import React, { useState, useEffect } from "react"
import axios from "axios"
import BootstrapTable from "react-bootstrap-table-next"
import {
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  Button,
  Modal,
} from "reactstrap"
import Loader from "components/Custom/Loader"
import { toast } from "react-toastify"
import Tostify from "components/Custom/Tostify"
import LayoutModal from "../../components/Custom/LayoutModel"
import ReactStars from "react-rating-stars-component"
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import SecondaryTable from "pages/Suggestions/SecondaryTable"
import { Row, Col } from "../imports/import"
import { useDispatch } from "react-redux"
import { userEdit } from "../../store/actions"
function SuggestionsList(props) {
  const [suggestionList, setSuggestionList] = useState([])
  const [loader, setLoader] = useState(false)
  const [rowIndexTable, setRowIndexTable] = useState()
  const [modal_xlarge, setmodal_xlarge] = useState(false)
  const [editSectionOne, setEditSectionOne] = useState(null)
  const [editSectionTwo, setEditSectionTwo] = useState(null)
  const dispatch = useDispatch()
  function tog_xlarge() {
    setmodal_xlarge(!modal_xlarge)
  }
  useEffect(() => {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/suggestions/0`,
      headers: {
        "x-auth-token": props.userData.token,
      },
    }
    axios(config)
      .then(function (response) {
        setSuggestionList(response.data.suggestions)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [])
  const changeStatus = data => {
    setRowIndexTable(rowIndexTable - 1)
    setLoader(true)
    var config = {
      method: "put",
      url: `${process.env.REACT_APP_BASE_URL}/suggestions/status/${data._id}/${
        data.isActive ? "0" : "1"
      }`,
      headers: {
        "x-auth-token": props.userData.token,
        "Content-Type": "application/json",
      },
    }

    axios(config)
      .then(function (response) {
        let values = suggestionList
        values[suggestionList.indexOf(data)].isActive = !data.isActive
        setSuggestionList([...values])
        setLoader(false)
        setTimeout(() => {
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        }, 200)
      })
      .catch(function (error) {
        console.log(error)
        setLoader(false)
        toast.error(error.response && error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
  }

  const editUserData = data => {
    try {
      dispatch(userEdit(data))
      props.history.push("/add-suggestions")
    } catch (e) {
      console.log("user list redux editdata  fail")
    }
  }
  const columns = [
    {
      dataField: "title",
      text: "Title",
      sort: true,
    },
    {
      dataField: "sales",
      text: "Sales",
      sort: true,
    },
    {
      dataField: "df2",
      isDummyField: true,
      text: "Rating",
      disabled: true,
      formatter: (cellContent, row, rowIndex) => {
        return (
          <div
            id="rating"
            style={{
              display: "flex",
            }}
          >
            <ReactStars
              count={5}
              edit={false}
              value={Number(row.ratting)}
              size={15}
              isHalf={true}
              emptyIcon={<i className="far fa-star"></i>}
              halfIcon={<i className="fa fa-star-half-alt"></i>}
              fullIcon={<i className="fa fa-star"></i>}
              activeColor="#ffd700"
            />
            <p
              style={{
                marginLeft: 5,
              }}
            >
              ({row.ratting})
            </p>
          </div>
        )
      },
    },
    {
      dataField: "mrp",
      text: "MRP",
      sort: true,
    },
    {
      dataField: "price",
      text: "Price",
      sort: true,
    },
    {
      dataField: "dummy01",
      text: "Types",
      isDummyField: true,
      formatter: (cellContent, row, rowIndex) => {
        return (
          <div
            style={{
              display: "flex",
            }}
          >
           <p>{row.type ? "Webinar":"Course"}</p>
          </div>
        )
      },
      events: {
        onMouseEnter: (e, column, columnIndex, row, rowIndex) => {
          setRowIndexTable(rowIndex)
        },
      },
    },
    {
      dataField: "isActive",
      text: "Status",
      sort: true,
      formatter: (cellContent, row, rowIndex, field) => {
      
        if (row.isActive) {
          return (
            <>
              <Button
                size="sm"
                type="button"
                id={rowIndex === rowIndexTable ? "PopoverLegacy" : ""}
                className="btn btn-success"
                onClick={() => {}}
              >
                Active
              </Button>
              {rowIndex === rowIndexTable && (
                <UncontrolledPopover
                  placement="top"
                  target="PopoverLegacy"
                  trigger="legacy"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <PopoverHeader style={{ textAlign: "center" }}>
                    Are you sure, you want to deactivate this suggestion ?
                  </PopoverHeader>
                  <PopoverBody>
                    <Button
                      size="sm"
                      style={{
                        marginRight: 5,
                      }}
                      onClick={() => changeStatus(row)}
                      color="success"
                    >
                      Yes
                    </Button>
                    <Button
                      size="sm"
                      style={{
                        marginLeft: 5,
                      }}
                      onClick={() => setRowIndexTable(rowIndexTable - 1)}
                      color="danger"
                    >
                      No
                    </Button>
                  </PopoverBody>
                </UncontrolledPopover>
              )}
            </>
          )
        } else {
          return (
            <>
              <Button
                size="sm"
                type="button"
                id={rowIndex === rowIndexTable ? "PopoverLegacy" : ""}
                className="btn btn-danger"
              >
                Deactive
              </Button>
              {rowIndex === rowIndexTable && (
                <UncontrolledPopover
                  placement="top"
                  target="PopoverLegacy"
                  trigger="legacy"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <PopoverHeader style={{ textAlign: "center" }}>
                    Are you sure, you want to activate this suggestion ?
                  </PopoverHeader>
                  <PopoverBody>
                    <Button
                      size="sm"
                      style={{
                        marginRight: 5,
                      }}
                      onClick={() => changeStatus(row)}
                      color="success"
                    >
                      Yes
                    </Button>
                    <Button
                      size="sm"
                      style={{
                        marginLeft: 5,
                      }}
                      onClick={() => setRowIndexTable(rowIndexTable - 1)}
                      color="danger"
                    >
                      No
                    </Button>
                  </PopoverBody>
                </UncontrolledPopover>
              )}
            </>
          )
        }
      },

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          rowIndex !== rowIndexTable && setRowIndexTable(rowIndex)
        },
        onMouseEnter: (e, column, columnIndex, row, rowIndex) => {
          setRowIndexTable(rowIndex)
        },
      },
    },
    {
      dataField: "dummy01",
      text: "Action",
      isDummyField: true,
      formatter: (cellContent, row, rowIndex) => {
        return (
          <div
            style={{
              display: "flex",
            }}
          >
            <Button
              size="sm"
              type="button"
              className="btn btn-warning "
              onClick={() => editUserData(row)}
            >
              Edit
            </Button>

            <Button
              size="sm"
              type="button"
              style={{ marginLeft: 10 }}
              className="btn btn-link"
              color="secondary"
              onClick={() => {
                setmodal_xlarge(true)
                setEditSectionOne({ section: row.section1, primary: true })
                setEditSectionTwo(null)
              }}
            >
              Section 1
            </Button>
            <Button
              size="sm"
              type="button"
              style={{ marginLeft: 10 }}
              className="btn btn-link"
              color="secondary"
              onClick={() => {
                setmodal_xlarge(true)
                setEditSectionTwo({ section: row.section2, primary: false })
                setEditSectionOne(null)
              }}
            >
              Section 2
            </Button>
            {rowIndex === rowIndexTable && modal_xlarge && (
              <Modal
                size="md"
                isOpen={modal_xlarge}
                toggle={() => {
                  tog_xlarge()
                }}
              >
                <div className="modal-header">
                  <SecondaryTable
                    data={
                      editSectionOne != null ? editSectionOne : editSectionTwo
                    }
                    onClick={() => setmodal_xlarge(!modal_xlarge)}
                  />
                </div>
              </Modal>
            )}
          </div>
        )
      },
      events: {
        onMouseEnter: (e, column, columnIndex, row, rowIndex) => {
          setRowIndexTable(rowIndex)
        },
      },
    },
  ]

  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange,
  }) => (
    <div className="btn-group" role="group">
      {options.map(option => {
        const isSelect = currSizePerPage === `${option.page}`
        return (
          <button
            key={option.text}
            type="button"
            onClick={() => onSizePerPageChange(option.page)}
            className={`btn ${isSelect ? "btn-secondary" : "btn-primary"}`}
          >
            {option.text}
          </button>
        )
      })}
    </div>
  )

  const defaultSorted = [
    {
      dataField: "name",
      order: "asc",
    },
  ]
  const pageOptions = {
    sizePerPage: 10,
    sizePerPageRenderer,
    totalSize: suggestionList.length, // replace later with size(customers),
  }
  const { SearchBar } = Search
  if (loader) {
    return <Loader />
  }
  return (
    <>
      <Tostify />
      <LayoutModal
        title="Suggestions List"
        sideTitle="User"
        button={true}
        btnTitle="Add New Suggestions"
        onclick={() => {
          props.history.push("/add-suggestions")
        }}
      >
        <PaginationProvider
          pagination={paginationFactory(pageOptions)}
          keyField="_id"
          columns={columns}
          data={suggestionList}
        >
          {({ paginationProps, paginationTableProps }) => (
            <ToolkitProvider
              keyField="_id"
              columns={columns}
              data={suggestionList}
              search
            >
              {toolkitProps => (
                <React.Fragment>
                  <Row className="mb-2">
                    <Col md="4">
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <SearchBar {...toolkitProps.searchProps} />
                          <i className="bx bx-search-alt search-icon" />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl="12">
                      <div className="table-responsive">
                        <BootstrapTable
                          keyField={"_id"}
                          responsive
                          bordered={false}
                          striped={true}
                          defaultSorted={defaultSorted}
                          hover
                          classes={"table align-middle table-nowrap"}
                          headerWrapperClasses={"thead-light"}
                          {...toolkitProps.baseProps}
                          {...paginationTableProps}
                        />
                      </div>
                    </Col>
                  </Row>
                </React.Fragment>
              )}
            </ToolkitProvider>
          )}
        </PaginationProvider>
      </LayoutModal>
    </>
  )
}

SuggestionsList.propTypes = {
  userData: PropTypes.object,
  editUser: PropTypes.any,
  history: PropTypes.any,
}

const mapStateToProps = state => {
  return { userData: state.userData }
}

export default connect(mapStateToProps)(withRouter(SuggestionsList))
