import React, { useState, useEffect } from "react"
import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Input
} from "reactstrap"
import Switch from "react-switch"
import axios from "axios"
import { toast } from "react-toastify"
//Import Breadcrumb
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { useDispatch,connect } from "react-redux"
import Tostify from "components/Custom/Tostify"
import classnames from "classnames"
import AddDetails from "./AddDetails"
import OverView from "./AddOverView"
import Features from "./AddFeatures"
import AddOns from "./AddOns"
import Professor from "./AddProfessor"
import AddLessons from "./AddLessons"
import AddReviews from "./AddReviews"
import { courseId } from "../../store/actions"

function AddCourses(props) {
  const [customActiveTab, setcustomActiveTab] = useState("1")
  const [toggleSwitch, setToggleSwitch] = useState(false)
  const [loader, setLoader] = useState(false)
  const [premium, setPremium] = useState(false)
  const dispatch = useDispatch()
  useEffect(() => {}, [props.courseId])

  useEffect(() => {
    if (props.editData) {
      if (props.editData.isActive) {
        setToggleSwitch(true)
      }
      setPremium(props.editData.isPremium)
      if (props.editData.isPremium) {
        setTimeout(() => {
          try {
            document.getElementById("premium").checked = true
          } catch (e) {
            console.log(e)
          }
        }, 200)
      }
    }
    return () => dispatch(courseId(null))
  }, [props.editData])

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }

  const setCoursePremium = data => {
    setLoader(true)
    var config = {
      method: "put",
      url: `${process.env.REACT_APP_BASE_URL}/courses/premium/${data ?  data :props.editData._id }/${premium  ? "0" : "1"}`,
      headers: {
        "x-auth-token": props.userData.token,
        "Content-Type": "application/json",
      },
    }

    axios(config)
      .then(function (response) {
        setLoader(false)
        // let values = CourseList
        // values[CourseList.indexOf(data)].isPremium = !data.isPremium
        // setcoursesList([])
        // setTimeout(()=>{
        //   setcoursesList([...values])
        // },200)
        setTimeout(() => {
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        }, 200)
      })
      .catch(function (error) {
        console.log(error)
        setLoader(false)
        toast.error(error.response && error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
  }

  const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
          borderRadius: 50,
        }}
      ></div>
    )
  }

  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          borderRadius: 50,
          paddingRight: 2,
        }}
      ></div>
    )
  }

  const toggle = () => {
    if (toggleSwitch) {
      setToggleSwitch(false)
      var config = {
        method: "put",
        url: `${process.env.REACT_APP_BASE_URL}/courses/status/${
          props.courseId ? props.courseId : props.editData._id
        }/0`,
        headers: {
          "x-auth-token": props.userData.token,
          "Content-Type": "application/json",
        },
      }
      axios(config)
        .then(function (response) {
          setLoader(false)
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        })
        .catch(function (error) {
          console.log(error)
          setLoader(false)
          toast.error(error.response && error.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        })
    } else {
      setToggleSwitch(true)
      var config = {
        method: "put",
        url: `${process.env.REACT_APP_BASE_URL}/courses/status/${
          props.courseId ? props.courseId : props.editData._id
        }/1`,
        headers: {
          "x-auth-token": props.userData.token,
          "Content-Type": "application/json",
        },
      }
      axios(config)
        .then(function (response) {
          setLoader(false)
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        })
        .catch(function (error) {
          console.log(error)
          setLoader(false)
          toast.error(error.response && error.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        })
    }
  }

  return (
    <>
      <Tostify />
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>

                      <CardTitle className="h4">
                        {" "}
                        {props.editData ? "EDIT COURSE" : " ADD COURSE"}
                      </CardTitle>
                      </div>
                        <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignContent: "center",
                        alignItems: "center",
                      }}
                      >
                        <label>Premium</label>
                    <Input   
                    style={{
                      marginRight: 20,
                      marginLeft: 5,
                      marginBottom: 10,
                    }}             
                  type="checkbox"
                  disabled={
                    props.editData || props.courseId ? false : true
                  }
                  id="premium"
                  // checked={row.isPremium}
                  className="form-control"
                  placeholder="Link"
                  onChange={e => {                           
                    setPremium(e.target.checked)
                    setCoursePremium(props.courseId)
                  }}
              />
                      <Switch
                        disabled={
                          props.editData || props.courseId ? false : true
                        }
                        uncheckedIcon={<Offsymbol />}
                        checkedIcon={<OnSymbol />}
                        className="me-2 mb-sm-8 mb-2"
                        onColor="#34c38f"
                        offColor="#74788d"
                        height={18}
                        width={40}
                        onChange={() => {
                          toggle()
                        }}
                        checked={toggleSwitch}
                      />
                    </div>
                    </div>

                    <Nav tabs className="nav-tabs-custom nav-justified">
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: customActiveTab === "1",
                          })}
                          onClick={() => {
                            toggleCustom("1")
                          }}
                        >
                          <span className="d-block d-sm-none">
                            <i className="fas fa-home"></i>
                          </span>
                          <span className="d-none d-sm-block">Details</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          disabled={
                            props.editData || props.courseId ? false : true
                          }
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: customActiveTab === "2",
                          })}
                          onClick={() => {
                            toggleCustom("2")
                          }}
                        >
                          <span className="d-block d-sm-none">
                            <i className="far fa-user"></i>
                          </span>
                          <span className="d-none d-sm-block">Overview</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          disabled={
                            props.editData || props.courseId ? false : true
                          }
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: customActiveTab === "3",
                          })}
                          onClick={() => {
                            toggleCustom("3")
                          }}
                        >
                          <span className="d-block d-sm-none">
                            <i className="far fa-envelope"></i>
                          </span>
                          <span className="d-none d-sm-block">Features</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          disabled={
                            props.editData || props.courseId ? false : true
                          }
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: customActiveTab === "4",
                          })}
                          onClick={() => {
                            toggleCustom("4")
                          }}
                        >
                          <span className="d-block d-sm-none">
                            <i className="fas fa-cog"></i>
                          </span>
                          <span className="d-none d-sm-block">Add-ons</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          disabled={
                            props.editData || props.courseId ? false : true
                          }
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: customActiveTab === "5",
                          })}
                          onClick={() => {
                            toggleCustom("5")
                          }}
                        >
                          <span className="d-block d-sm-none">
                            <i className="fas fa-cog"></i>
                          </span>
                          <span className="d-none d-sm-block">Professors</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          disabled={
                            props.editData || props.courseId ? false : true
                          }
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: customActiveTab === "6",
                          })}
                          onClick={() => {
                            toggleCustom("6")
                          }}
                        >
                          <span className="d-block d-sm-none">
                            <i className="fas fa-cog"></i>
                          </span>
                          <span className="d-none d-sm-block">Lessons</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          disabled={
                            props.editData || props.courseId ? false : true
                          }
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: customActiveTab === "7",
                          })}
                          onClick={() => {
                            toggleCustom("7")
                          }}
                        >
                          <span className="d-block d-sm-none">
                            <i className="fas fa-cog"></i>
                          </span>
                          <span className="d-none d-sm-block">Review</span>
                        </NavLink>
                      </NavItem>
                    </Nav>

                    <TabContent
                      activeTab={customActiveTab}
                      className="p-3 text-muted"
                    >
                      <TabPane tabId="1">
                        <Row>
                          <Col sm="12">
                            <CardText className="mb-0">
                              <AddDetails />
                            </CardText>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="2">
                        <Row>
                          <Col sm="12">
                            <CardText className="mb-0">
                              <OverView />
                            </CardText>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="3">
                        <Row>
                          <Col sm="12">
                            <CardText className="mb-0">
                              <Features />
                            </CardText>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="4">
                        <Row>
                          <Col sm="12">
                            <CardText className="mb-0">
                              <AddOns />
                            </CardText>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="5">
                        <Row>
                          <Col sm="12">
                            <CardText className="mb-0">
                              <Professor />
                            </CardText>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="6">
                        <Row>
                          <Col sm="12">
                            <CardText className="mb-0">
                              <AddLessons />
                            </CardText>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="7">
                        <Row>
                          <Col sm="12">
                            <CardText className="mb-0">
                              <AddReviews />
                            </CardText>
                          </Col>
                        </Row>
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    </>
  )
}

AddCourses.propTypes = {
  history: PropTypes.object,
  userData: PropTypes.object,
  editData: PropTypes.object,
  courseId: PropTypes.any,
}
const mapStateToProps = state => {
  return {
    userData: state.userData,
    editData: state.userEdit,
    courseId: state.courseId,
  }
}

export default connect(mapStateToProps)(withRouter(AddCourses))
