import React from "react"
import { Redirect } from "react-router-dom"
// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import ChangePass from "../pages/user/changepass"
// Dashboard
import Dashboard from "../pages/Dashboard/index"
// notifications
import AddNotification from "../pages/Notification/AddNotification"
//Currency
import UpdateCurrency from "../pages/Currency/UpdateCurrency"
//Booking Requests
import BookingRequests from "../pages/BookingRequests/BookingRequestList"
//Learning
import AddCourses from "../pages/Learning/AddCourses"
import CourseList from "../pages/Learning/CourseList"
//Webinar
import AddWebinar from "../pages/Webinar/AddWebinar"
import WebinarList from "../pages/Webinar/WebinarList"
// Directory
import Directory from "pages/directory/Directory"
import DirectoryList from "pages/directory/DirectoryList"
//Suggestions
import AddSuggestions from "../pages/Suggestions/AddSuggestion"
import SuggestionsList from "../pages/Suggestions/SuggestionList"
//FAQ
import AddFaq from "../pages/Faq/AddFaq"
import FaqList from "../pages/Faq/FaqList"
// Templates
import AddCategory from "../pages/Templates/AddCateory"
import AddTemplate from "../pages/Templates/AddTemplates"
//City
import AddCity from "../pages/City/AddCity"
//Company
import AddCompany from "pages/Companies/AddCompany"
import ListCompany from "pages/Companies/ListCompany"
// Languages
import Addlanguage from "pages/Language/Addlanguage"
// LoginReport
import LoggedIn from "pages/LoginReport/LoggedIn"
import NotLoggedIn from "pages/LoginReport/NotLoggedIn"
// RegisteredUser
import RegisteredUser from "pages/RegisteredUser/RegisteredUserList"

// User
import AddUser from "../pages/user/adduser"
import Userlist from "../pages/user/userlist"

const authProtectedRoutes = [
  { path: "/change-password", component: ChangePass },
  { path: "/dashboard", component: Dashboard },
  //Notification
  { path: "/add-notification", component: AddNotification },
  //Learning
  { path: "/add-course", component: AddCourses },
  { path: "/course-list", component: CourseList },
  //Currency
  { path: "/currencies", component: UpdateCurrency },
  //Booking Requests
  { path: "/booking-Requests", component: BookingRequests },
  //Webinar
  { path: "/add-webinar", component: AddWebinar },
  { path: "/webinar-list", component: WebinarList },
  //Directory
  { path: "/add-directory", component: Directory },
  { path: "/directory-list", component: DirectoryList },
  // Suggestions
  { path: "/add-suggestions", component: AddSuggestions },
  { path: "/suggestions-list", component: SuggestionsList },
  //FAQ
  { path: "/add-faq", component: AddFaq },
  { path: "/faq-list", component: FaqList },
  // Templates
  { path: "/add-category", component: AddCategory },
  { path: "/add-template", component: AddTemplate },

  // City
  { path: "/add-city", component: AddCity },
  // Companies
  { path: "/add-company", component: AddCompany },
  { path: "/company-list", component: ListCompany },
  // Language
  { path: "/add-language", component: Addlanguage },
  // LoginReport
  { path: "/login-in", component: LoggedIn },
  { path: "/not-logged-id", component: NotLoggedIn },
  //RegisteredUser
  { path: "/registered-user", component: RegisteredUser },
  // User
  { path: "/add-user", component: AddUser },
  { path: "/user-list", component: Userlist },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
]

export { publicRoutes, authProtectedRoutes }
