import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
//redux
import { useDispatch, connect } from "react-redux"
import axios from "axios"
import Tostify from "components/Custom/Tostify"
import { toast } from "react-toastify"
import Loader from "components/Custom/Loader"
import { withRouter, Link } from "react-router-dom"
import * as Yup from "yup"
import { Row, Col, Input, Label, Card, Form, FormFeedback } from "reactstrap"
import LayoutModal from "../../components/Custom/LayoutModel"
import { useFormik } from "formik"
import { userEdit } from "../../store/actions"
import Selector from "components/Custom/Selector"
import ReactStars from "react-rating-stars-component"
function AddSuggestion(props) {
  const [loader, setLoader] = useState(false)
  const [courseCheckBox, setCourseCheckBox] = useState(true)
  const [webinarCheckBox, setWebinarCheckBox] = useState(false)
  const [rating, setRating] = useState(0)
  const [bannerImage, setBannerrImage] = useState(null)
  const [courseTypeList, setCourseTypeList] = useState([])
  const [selectCourseType, setSelectCourseType] = useState(null)
  const [showEditCertificates, setShowEditCertificates] = useState(true)
  const [iconImage, setIconImage] = useState(null)
  const [showEditImage, setShowEditImage] = useState(true)
  const [sectionOne, setSectionOne] = useState([
    {
      id: 1,
      title: "",
      value: "",
      link: false,
      error: false,
    },
  ])
  const [sectionTwo, setSectionTwo] = useState([
    {
      id: 1,
      title: "",
      value: "",
      link: false,
      error: false,
    },
  ])
// getting coursetypes data
      useEffect(() => {
  var config = {
    method: "get",
    url: `${process.env.REACT_APP_BASE_URL}/formdata/coursetypes`,
    headers: {
      "x-auth-token": props.userData.token,
    },
  }

  axios(config)
    .then(function (response) {
      setCourseTypeList(response.data.course_types)
    })
    .catch(function (error) {
      console.log(error)
    })
}, [])

  useEffect(() => {
    document.getElementById("course").checked = true
  }, [])

  useEffect(() => {
    if (props.editData) {
      if (props.editData.ratting) {
        setRating(props.editData.ratting)
      }
      if(props.editData.courseType){

        setSelectCourseType(props.editData.courseType._id)
      }
      if (props.editData.type) {
        document.getElementById("webinar").checked = true
        setWebinarCheckBox(true)
        setCourseCheckBox(false)
      }
      let dummyArrForSectionOne = []
      props.editData.section1.map((d, i) => {
        let dummyObjForSectionOne = {
          id: i + 1,
          title: d.title,
          value: d.value,
          link:d.link,
          error: d.error,
        }
        setTimeout(() => {          
          document.getElementById("link1"+i).checked =d.link
        }, 300);
        dummyArrForSectionOne.push(dummyObjForSectionOne)
      })
      setSectionOne([])
      setTimeout(() => {
        setSectionOne([...dummyArrForSectionOne])
      }, 100)

      let dummyArrForSectionTwo = []
      props.editData.section2.map((d, i) => {
        let dummyObjForSectionTwo = {
          id: i + 1,
          title: d.title,
          value: d.value,
          link:d.link,
          error: d.error,
        }
        setTimeout(() => {          
          document.getElementById("link2"+i).checked =d.link
        }, 300);
        dummyArrForSectionTwo.push(dummyObjForSectionTwo)
      })
      setSectionTwo([])
      setTimeout(() => {
        setSectionTwo([...dummyArrForSectionTwo])
      }, 100)
    }
    return () => dispatch(userEdit(null))
  }, [props.editData])
  const onAddSectionOne = () => {
    const modifiedRows1 = [...sectionOne]
    if (
      modifiedRows1[modifiedRows1.length - 1].title &&
      modifiedRows1[modifiedRows1.length - 1].value
    ) {
      modifiedRows1.push({
        id: modifiedRows1.length + 1,
        title: "",
        value: "",
        link: false,
        error: false,
      })
    } else {
      toast.error("Please insert value in section 1", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      })
    }
    setSectionOne(modifiedRows1)
  }
  const onDeleteSectionOne = id => {
    if (id !== 0) {
      var modifiedRows = [...sectionOne]
      modifiedRows.splice(id, 1)
      setSectionOne([])
      setTimeout(() => {
        setSectionOne([...modifiedRows])
      }, 100)
      sectionOne.map((d, i) => {
        setTimeout(() => {          
          document.getElementById("link1"+i).checked =d.link
        }, 300);
      })
    }
  }
  const onAddSectionTwo = () => {
    const modifiedRows2 = [...sectionTwo]

    if (
      modifiedRows2[modifiedRows2.length - 1].title &&
      modifiedRows2[modifiedRows2.length - 1].value
    ) {
      modifiedRows2.push({
        id: modifiedRows2.length + 1,
        title: "",
        value: "",
        link:false,
        error: false,
      })
    } else {
      toast.error("Please insert value section 2", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      })
    }
    setSectionTwo(modifiedRows2)
  }
  const onDeleteSectionTwo = id => {
    if (id !== 0) {
      var modifiedRows2 = [...sectionTwo]
      setSectionTwo([])
      modifiedRows2.splice(id, 1)
      setTimeout(() => {
        setSectionTwo([...modifiedRows2])
      }, 100)

      modifiedRows2.map((d, i) => {
        setTimeout(() => {     
          document.getElementById("link2"+i).checked =d.link
        }, 300);
      })
    }
  }
  const dispatch = useDispatch()

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    initialValues: {
      title: props.editData ? props.editData.title : "",
      mrp: props.editData ? props.editData.mrp : "",
      price: props.editData ? props.editData.price : "",
      sales: props.editData ? props.editData.sales : "",
      rating: props.editData ? props.editData.noOfRating : "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please enter title"),
      mrp: Yup.string().required("Please enter mrp"),
      price: Yup.string().required("Please enter price"),
      sales: Yup.string().required("Please enter sales"),
      rating: Yup.string().required("Please enter rating"),
    }),

    onSubmit: values => {
      if(courseCheckBox)
      {
        if (selectCourseType == null) {
          return toast.error("Please select a course types", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        }
      }
      if (rating === 0) {
        return toast.error("Please select ratting", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      }

      if (!props.editData) {
        if (bannerImage === null) {
          return toast.error("Please select a banner image", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        }
        if (iconImage === null) {
          return toast.error("Please select a icon image", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        }
      }
      let titleOneError = false
      let valueOneError = false
      let titleTwoError = false
      let valueTwoError = false

      sectionOne.map((d, i) => {
        if (d.title === "") {
          titleOneError = true
        }
        if (d.title === "" || d.value === "") {
          valueOneError = true
        }
      })
      if (titleOneError) {
        return toast.error(
          "Please select title in section 1, title is required",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        )
      }
      if (valueOneError) {
        return toast.error(
          "Please select value in section 1, value is required",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        )
      }

      sectionTwo.map((d, i) => {
        if (d.title === "") {
          titleTwoError = true
        }
        if (d.title === "" || d.value === "") {
          valueTwoError = true
        }
      })
      if (titleTwoError) {
        return toast.error(
          "Please select title in section 2, title is required",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        )
      }
      if (valueTwoError) {
        return toast.error(
          "Please select value in section 2, value is required",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        )
      }
      var data = new FormData()
      data.append("type", courseCheckBox ? 0 : 1)
      data.append("title", values.title)
      data.append("courseType", selectCourseType)
      data.append("mrp", values.mrp)
      data.append("price", values.price)
      data.append("sales", values.sales)
      data.append("noOfRating", values.rating)
      data.append("ratting", rating)
      sectionOne.map((d, i) => {
        data.append(`section1[${i}][title]`, d.title)
        data.append(`section1[${i}][value]`, d.value)
        data.append(`section1[${i}][link]`, d.link)
      })
      sectionTwo.map((d, i) => {
        data.append(`section2[${i}][title]`, d.title)
        data.append(`section2[${i}][value]`, d.value)
        data.append(`section2[${i}][link]`, d.link)
      })
      bannerImage !== null && data.append("banner", bannerImage)
      iconImage !== null && data.append("icon", iconImage)
      var config = {
        method: props.editData ? "put" : "post",
        url: `${process.env.REACT_APP_BASE_URL}/${
          props.editData ? "suggestions/" + props.editData._id : "suggestions"
        }`,
        headers: {
          "x-auth-token": props.userData.token,
          "Content-Type": "application/json",
        },
        data: data,
      }
      setLoader(true)
      axios(config)
        .then(function (response) {
          setLoader(false)
          if (props.editData) {
            dispatch(userEdit(null))
          }
          props.history.push("/suggestions-list")
          setTimeout(() => {
            toast.success(response.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            })
          }, 200)
        })
        .catch(function (error) {
          console.log(error)
          setLoader(false)
          toast.error(error.response && error.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        })
    },
  })  

  if (loader) {
    return <Loader />
  }
  return (
    <>
      <Tostify />
      <div
        className="d-flex flex-column flex-sm-column flex-md-column flex-lg-row"
        style={{ flex: 1, minHeight: "100vh" }}
      >
        <LayoutModal
          button={true}
          size={8}
          title={props.editData ? "Edit Suggestion" : "Add Suggestion"}
          btnTitle={"Suggestion List"}
          onclick={() => {
            props.history.push("/suggestions-list")
          }}
        >
          <Form
            className="form-horizontal"
            id="form"
            onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
              return false
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: 10,
              }}
            >
              <Label className="form-label">
                Select Type <span style={{ color: "red" }}>*</span>
              </Label>
              <div style={{ marginLeft: 30 }}>
                <Label check>
                  <Input
                    style={{ marginRight: 5 }}
                    type="radio"
                    name="radio1"
                    id="course"
                    disabled={props.editData ? true : false}
                    onChange={e => {
                      setCourseCheckBox(true)
                      setWebinarCheckBox(false)
                      setSelectCourseType(null)
                    }}
                  />
                  Course
                </Label>
              </div>
              <div style={{ marginLeft: 20 }}>
                <Label check>
                  <Input
                    style={{ marginRight: 5 }}
                    type="radio"
                    name="radio1"
                    id="webinar"
                    disabled={props.editData ? true : false}
                    onChange={e => {
                      setCourseCheckBox(false)
                      setWebinarCheckBox(true)
                    }}
                  />
                  Webinar
                </Label>
              </div>
            </div>
            <Row>
              <Col lg={6}>
                <div className="mb-3">
                  <Label className="form-label">
                    Title <span style={{ color: "red" }}>*</span>
                  </Label>
                  <Input
                    name="title"
                    id="title"
                    className="form-control"
                    placeholder="Title"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.title || ""}
                    invalid={
                      validation.touched.title && validation.errors.title
                        ? true
                        : false
                    }
                  />
                  {validation.touched.title && validation.errors.title ? (
                    <FormFeedback type="invalid">
                      {validation.errors.title}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              { courseCheckBox && 
               <Col lg={6}>
              <div className="mb-3">
                            <Selector
                              label="Course Type"
                              required
                              data={
                                courseTypeList !== null ? courseTypeList : []
                              }
                              onchange={event => {
                                setSelectCourseType(event.value)
                              }}
                              edit={
                                props.editData &&
                                props.editData.courseType && {
                                  label: props.editData.courseType.name,
                                  value: props.editData.courseType._id,
                                }
                              }
                            />
                          </div>
              </Col>}
             
            </Row>
            <Row>
              <Col lg={6}>
                {" "}
                <div className="mb-3">
                  <Label className="form-label">
                    MRP <span style={{ color: "red" }}>*</span>
                  </Label>
                  <Input
                    name="mrp"
                    id="mrp"
                    className="form-control"
                    placeholder="MRP"
                    type="text"
                    onKeyPress={event => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault()
                      }
                    }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.mrp || ""}
                    invalid={
                      validation.touched.mrp && validation.errors.mrp
                        ? true
                        : false
                    }
                  />
                  {validation.touched.mrp && validation.errors.mrp ? (
                    <FormFeedback type="invalid">
                      {validation.errors.mrp}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col lg={6}>
                {" "}
                <div className="mb-3">
                  <Label className="form-label">
                    Price <span style={{ color: "red" }}>*</span>
                  </Label>
                  <Input
                    name="price"
                    id="price"
                    className="form-control"
                    placeholder="Price"
                    type="text"
                    onKeyPress={event => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault()
                      }
                    }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.price || ""}
                    invalid={
                      validation.touched.price && validation.errors.price
                        ? true
                        : false
                    }
                  />
                  {validation.touched.price && validation.errors.price ? (
                    <FormFeedback type="invalid">
                      {validation.errors.price}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={3}>
                {" "}
                <div className="mb-3">
                  <Label className="form-label">
                    No Of Purchase <span style={{ color: "red" }}>*</span>
                  </Label>
                  <Input
                    name="sales"
                    id="sales"
                    className="form-control"
                    placeholder="Sales"
                    type="text"
                    onKeyPress={event => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault()
                      }
                    }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.sales || ""}
                    invalid={
                      validation.touched.sales && validation.errors.sales
                        ? true
                        : false
                    }
                  />
                  {validation.touched.sales && validation.errors.sales ? (
                    <FormFeedback type="invalid">
                      {validation.errors.sales}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col lg={3}>
                {" "}
                <div className="mb-3">
                  <Label className="form-label">
                    No Of Rating <span style={{ color: "red" }}>*</span>
                  </Label>
                  <Input
                    name="rating"
                    id="rating"
                    className="form-control"
                    placeholder="Rating"
                    type="text"
                    onKeyPress={event => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault()
                      }
                    }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.rating || ""}
                    invalid={
                      validation.touched.rating && validation.errors.rating
                        ? true
                        : false
                    }
                  />
                  {validation.touched.rating && validation.errors.rating ? (
                    <FormFeedback type="invalid">
                      {validation.errors.rating}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col lg={6}>
                {" "}
                <div className="mb-3">
                  <Label
                    style={{
                      marginBottom: -10,
                    }}
                    htmlFor="formrow-email-Input"
                  >
                    Rating <span style={{ color: "red" }}>*</span>
                  </Label>
                  <ReactStars
                    count={5}
                    id="ratingstart"
                    onChange={e => setRating(e)}
                    value={
                      props.editData
                        ? Number(props.editData.ratting)
                        : Number(rating)
                    }
                    size={35}
                    isHalf={true}
                    emptyIcon={<i className="far fa-star"></i>}
                    halfIcon={<i className="fa fa-star-half-alt"></i>}
                    fullIcon={<i className="fa fa-star"></i>}
                    activeColor="#ffd700"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <div className="mb-6">
                  <Label className="form-label">Banner Image</Label>{" "}
                  <span style={{ color: "red" }}>*</span>
                  <Input
                    name="bannerImage"
                    id="bannerImage"
                    className="form-control"
                    type="file"
                    onChange={e => {
                      setBannerrImage(e.target.files[0])
                    }}
                  />
                  {bannerImage !== null && (
                    <Card
                      className="mt-2 mb-10 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                      style={{
                        backgroundColor:
                          bannerImage.size >
                          Number(process.env.REACT_APP_FILE_SIZE)
                            ? "#ff6a6a"
                            : "#2FDD92",
                        color:
                          bannerImage.size >
                          Number(process.env.REACT_APP_FILE_SIZE)
                            ? "#ffffff"
                            : "#131313",
                        width: "97%",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    >
                      <div className="p-2">
                        <Row className="align-items-center">
                          <Col className="col-auto">
                            <img
                              data-dz-thumbnail=""
                              height="80"
                              className="avatar-sm rounded bg-light"
                              style={{
                                objectFit: "cover",
                              }}
                              alt={bannerImage.name}
                              src={URL.createObjectURL(bannerImage)}
                            />
                          </Col>
                          <Col>
                            <Link
                              to="#"
                              style={{
                                color:
                                  bannerImage.size >
                                  Number(process.env.REACT_APP_FILE_SIZE)
                                    ? "#ffffff"
                                    : "#131313",
                              }}
                            >
                              {bannerImage.name}
                            </Link>
                            <p className="mb-0">
                              <strong>
                                {(bannerImage.size / 1048576).toFixed(2) +
                                  " MB"}
                              </strong>
                            </p>
                            <i
                              className="bx bx-no-entry"
                              style={{
                                position: "absolute",
                                top: 0,
                                right: 10,
                                color:
                                  bannerImage.size >
                                  Number(process.env.REACT_APP_FILE_SIZE)
                                    ? "#ffffff"
                                    : "#131313",
                                fontSize: 17,
                                fontWeight: 800,
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setBannerrImage(null)
                                document.getElementById("bannerImage").value =
                                  ""
                              }}
                            ></i>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  )}
                  {props.editData &&
                    props.editData.banner &&
                    showEditCertificates &&
                    bannerImage == null && (
                      <Card
                        className="mt-2 mb-10 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                        style={{
                          backgroundColor:
                            props.editData.banner.fileSize >
                            Number(process.env.REACT_APP_FILE_SIZE)
                              ? "#ff6a6a"
                              : "#2FDD92",
                          color:
                            props.editData.banner.fileSize >
                            Number(process.env.REACT_APP_FILE_SIZE)
                              ? "#ffffff"
                              : "#131313",
                          width: "97%",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      >
                        <div className="p-2">
                          <Row className="align-items-center">
                            <Col className="col-auto">
                              <img
                                data-dz-thumbnail=""
                                height="80"
                                className="avatar-sm rounded bg-light"
                                style={{
                                  objectFit: "cover",
                                }}
                                alt={props.editData.banner.originalname}
                                src={`${process.env.REACT_APP_IMAGE_URL}/suggestion_banner_images/${props.editData.banner.filename}`}
                              />
                            </Col>
                            <Col>
                              <Link
                                to="#"
                                style={{
                                  color:
                                    props.editData.banner.fileSize >
                                    Number(process.env.REACT_APP_FILE_SIZE)
                                      ? "#ffffff"
                                      : "#131313",
                                }}
                              >
                                {props.editData.banner.originalname}
                              </Link>
                              <p className="mb-0">
                                {(
                                  props.editData.banner.fileSize / 1048576
                                ).toFixed(2) + " MB"}
                              </p>
                              <i
                                className="bx bx-no-entry"
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  right: 10,
                                  color:
                                    props.editData.banner.fileSize >
                                    Number(process.env.REACT_APP_FILE_SIZE)
                                      ? "#ffffff"
                                      : "#131313",
                                  fontSize: 17,
                                  fontWeight: 800,
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setShowEditCertificates(false)
                                }}
                              ></i>
                            </Col>
                          </Row>
                        </div>
                      </Card>
                    )}
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-6">
                  <Label className="form-label">Icon Image</Label>{" "}
                  <span style={{ color: "red" }}>*</span>
                  <Input
                    name="iconImage"
                    id="iconImage"
                    className="form-control"
                    type="file"
                    onChange={e => {
                      setIconImage(e.target.files[0])
                    }}
                  />
                  {iconImage !== null && (
                    <Card
                      className="mt-2 mb-10 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                      style={{
                        backgroundColor:
                          iconImage.size >
                          Number(process.env.REACT_APP_FILE_SIZE)
                            ? "#ff6a6a"
                            : "#2FDD92",
                        color:
                          iconImage.size >
                          Number(process.env.REACT_APP_FILE_SIZE)
                            ? "#ffffff"
                            : "#131313",
                        width: "97%",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    >
                      <div className="p-2">
                        <Row className="align-items-center">
                          <Col className="col-auto">
                            <img
                              data-dz-thumbnail=""
                              height="80"
                              className="avatar-sm rounded bg-light"
                              style={{
                                objectFit: "cover",
                              }}
                              alt={iconImage.name}
                              src={URL.createObjectURL(iconImage)}
                            />
                          </Col>
                          <Col>
                            <Link
                              to="#"
                              style={{
                                color:
                                  iconImage.size >
                                  Number(process.env.REACT_APP_FILE_SIZE)
                                    ? "#ffffff"
                                    : "#131313",
                              }}
                            >
                              {iconImage.name}
                            </Link>
                            <p className="mb-0">
                              {(iconImage.size / 1048576).toFixed(2) + " MB"}
                            </p>
                            <i
                              className="bx bx-no-entry"
                              style={{
                                position: "absolute",
                                top: 0,
                                right: 10,
                                color:
                                  iconImage.size >
                                  Number(process.env.REACT_APP_FILE_SIZE)
                                    ? "#ffffff"
                                    : "#131313",
                                fontSize: 17,
                                fontWeight: 800,
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setIconImage(null)
                                document.getElementById("iconImage").value = ""
                              }}
                            ></i>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  )}
                  {props.editData &&
                    props.editData.icon &&
                    showEditImage &&
                    iconImage == null && (
                      <Card
                        className="mt-2 mb-10 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                        style={{
                          backgroundColor:
                            props.editData.icon.fileSize >
                            Number(process.env.REACT_APP_FILE_SIZE)
                              ? "#ff6a6a"
                              : "#2FDD92",
                          color:
                            props.editData.icon.fileSize >
                            Number(process.env.REACT_APP_FILE_SIZE)
                              ? "#ffffff"
                              : "#131313",
                          width: "97%",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      >
                        <div className="p-2">
                          <Row className="align-items-center">
                            <Col className="col-auto">
                              <img
                                data-dz-thumbnail=""
                                height="80"
                                className="avatar-sm rounded bg-light"
                                style={{
                                  objectFit: "cover",
                                }}
                                alt={props.editData.icon.originalname}
                                src={`${process.env.REACT_APP_IMAGE_URL}/suggestion_banner_images/${props.editData.icon.filename}`}
                              />
                            </Col>
                            <Col>
                              <Link
                                to="#"
                                style={{
                                  color:
                                    props.editData.icon.fileSize >
                                    Number(process.env.REACT_APP_FILE_SIZE)
                                      ? "#ffffff"
                                      : "#131313",
                                }}
                              >
                                {props.editData.icon.originalname}
                              </Link>
                              <p className="mb-0">
                                <strong>
                                  {(
                                    props.editData.icon.fileSize / 1048576
                                  ).toFixed(2) + " MB"}
                                </strong>
                              </p>
                              <i
                                className="bx bx-no-entry"
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  right: 10,
                                  color:
                                    props.editData.icon.fileSize >
                                    Number(process.env.REACT_APP_FILE_SIZE)
                                      ? "#ffffff"
                                      : "#131313",
                                  fontSize: 17,
                                  fontWeight: 800,
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setShowEditImage(false)
                                }}
                              ></i>
                            </Col>
                          </Row>
                        </div>
                      </Card>
                    )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <h5
                  style={{
                    marginTop: 30,
                    marginBottom: 20,
                  }}
                >
                  Section 1 (Product Details)
                </h5>
                <div>
                  {(sectionOne || []).map((section1, key) => (
                    <Row key={key}>
                      <Col lg={3} className="mb-3">
                        <label htmlFor="title1">Title</label>
                        <span style={{ marginLeft: 4, color: "red" }}>*</span>
                        <Input
                          type="text"
                          id={"title1" + key}
                          className="form-control"
                          value={sectionOne[key].title}
                          placeholder="Title"
                          onChange={e => {
                            sectionOne[key].title = e.target.value
                            setSectionOne([...sectionOne])
                          }}
                        />
                      </Col>

                      <Col lg={3} className="mb-3">
                        <label htmlFor="value1">Value</label>
                        <span style={{ marginLeft: 4, color: "red" }}>*</span>
                        <Input
                          type="text"
                          id={"value1" + key}
                          value={sectionOne[key].value}
                          className="form-control"
                          placeholder="Value"
                          onChange={e => {
                            sectionOne[key].value = e.target.value
                            setSectionOne([...sectionOne])
                          }}
                        />
                      </Col>
                      <Col lg={3} className="mb-3">
                        <label htmlFor="link1">  Link</label>
                        <span style={{ marginLeft: 4, color: "red" }}>*</span>
                        <Input
                          type="checkbox"
                          id={"link1" + key}
                          // value={sectionOne[key].link}
                          className="form-control"
                          placeholder="Link"
                          onChange={e => {
                            sectionOne[key].link = e.target.checked
                            setSectionOne([...sectionOne])
                          }}
                        />
                      </Col>
                      {section1.id !== 1 && (
                        <Col lg={1} className="align-self-center">
                          <div className="d-grid">
                            <button
                              type="button"
                              className="btn btn-danger"
                              style={{ marginTop: 10 }}
                              onClick={() => onDeleteSectionOne(key)}
                            >
                              {" "}
                              <i className="bx bx-trash"></i>{" "}
                            </button>
                          </div>
                        </Col>
                      )}
                    </Row>
                  ))}
                </div>
                <button
                  type="button"
                  className="btn btn-success mt-3 mt-lg-0"
                  onClick={() => onAddSectionOne()}
                >
                  {" "}
                  <i className="bx bx-plus"></i>
                </button>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <h5
                  style={{
                    marginTop: 30,
                    marginBottom: 20,
                  }}
                >
                  Section 2
                </h5>
                <div>
                  {(sectionTwo || []).map((section2, key) => (
                    <Row key={key}>
                      <Col lg={3} className="mb-3">
                        <label htmlFor="title2">Title</label>
                        <span style={{ marginLeft: 4, color: "red" }}>*</span>
                        <Input
                          type="text"
                          id={"title2" + key}
                          value={sectionTwo[key].title}
                          className="form-control"
                          placeholder="Title"
                          onChange={e => {
                            sectionTwo[key].title = e.target.value
                            setSectionTwo([...sectionTwo])
                          }}
                        />
                      </Col>

                      <Col lg={3} className="mb-3">
                        <label htmlFor="value2">Value</label>
                        <span style={{ marginLeft: 4, color: "red" }}>*</span>
                        <Input
                          type="text"
                          id={"value2" + key}
                          value={sectionTwo[key].value}
                          className="form-control"
                          placeholder="Value"
                          onChange={e => {
                            sectionTwo[key].value = e.target.value
                            setSectionTwo([...sectionTwo])
                          }}
                        />
                      </Col>
                      <Col lg={3} className="mb-3">
                        <label htmlFor="link2">  Link</label>
                        <span style={{ marginLeft: 4, color: "red" }}>*</span>
                        <Input
                          type="checkbox"
                          id={"link2" + key}
                          // value={sectionTwo[key].link}
                          className="form-control"
                          placeholder="Link"
                          onChange={e => {
                            setTimeout(() => {                              
                              sectionTwo[key].link = e.target.checked
                              setSectionTwo([...sectionTwo])
                            }, 100);
                          }}
                        />
                      </Col>
                      {section2.id !== 1 && (
                        <Col lg={1} className="align-self-center">
                          <div className="d-grid">
                            <button
                              type="button"
                              className="btn btn-danger"
                              style={{ marginTop: 10 }}
                              onClick={() => onDeleteSectionTwo(key)}
                            >
                              {" "}
                              <i className="bx bx-trash"></i>{" "}
                            </button>
                          </div>
                        </Col>
                      )}
                    </Row>
                  ))}
                </div>
                <button
                  type="button"
                  className="btn btn-success mt-3 mt-lg-0"
                  onClick={() => onAddSectionTwo()}
                >
                  {" "}
                  <i className="bx bx-plus"></i>
                </button>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col className="text-start">
                <button
                  className="btn btn-primary w-md "
                  type="submit"
                  style={{ marginBottom: 20, marginTop: 20 }}
                >
                  Submit
                </button>
              </Col>
            </Row>
          </Form>
        </LayoutModal>
      </div>
    </>
  )
}

AddSuggestion.propTypes = {
  history: PropTypes.object,
  userData: PropTypes.object,
  editData: PropTypes.object,
}

const mapStateToProps = state => {
  return {
    userData: state.userData,
    editData: state.userEdit,
  }
}

export default connect(mapStateToProps)(withRouter(AddSuggestion))
