import React, { useState, useEffect } from "react"
import LayoutModal from "../../components/Custom/LayoutModel"
import axios from "axios"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { userEdit } from "../../store/actions"
import { useDispatch } from "react-redux"
import { Row, Col, FormFeedback, Input, Label, Form } from "reactstrap"
import { withRouter } from "react-router-dom"
import { toast } from "react-toastify"
import Tostify from "components/Custom/Tostify"
import Loader from "components/Custom/Loader"
import { useFormik } from "formik"
import * as Yup from "yup"
function Adduser(props) {
  const [submitted, setSubmitted] = useState(false)
  const [userTypes, setUserTypes] = useState([])
  const [selectedUser, setSelectedUser] = useState("")
  const [loader, setLoader] = useState(false)
  const dispatch = useDispatch()
  useEffect(() => {
    const token = props.userData.token
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/usertypes`,
      headers: {
        "x-auth-token": token,
      },
    }
    axios(config)
      .then(function (response) {
        setUserTypes(response.data)
        setSelectedUser(response.data[0]._id)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [])

  useEffect(() => {
    const data = props.editData
    if (props.editData) {
      setSelectedUser(props.editData.type._id)
    } else {
      document.getElementById("firstname").value = ""
      document.getElementById("lastname").value = ""
      document.getElementById("email").value = ""
      document.getElementById("phone").value = ""
    }

    return () => dispatch(userEdit(null))
  }, [props.editData])

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    // enableReinitialize: true,
    initialValues: {
      firstname: props.editData ? props.editData.firstname : "",
      lastname: props.editData ? props.editData.lastname : "",
      email: props.editData ? props.editData.email : "",
      phone: props.editData ? props.editData.phone : "",
      pass: "",
      cnfPass: "",
    },
    validationSchema: Yup.object({
      firstname: Yup.string().required("Please Enter Your First Name"),
      lastname: Yup.string().required("Please Enter Your Last Name"),
      email: Yup.string()
        .email("Please Enter Your Valid Email")
        .required("Please Enter Your Email"),
      phone: Yup.string().required("Please Enter Your Number").min(10).max(10),
      pass: props.editData
        ? Yup.string()
        : Yup.string().required("Please Enter Your Password"),
      cnfPass: props.editData
        ? Yup.string()
        : Yup.string()
            .required("Please confirm your Password")
            .oneOf([Yup.ref("pass")], "Your Passwords Does Not Match."),
    }),

    onSubmit: values => {
      setLoader(true)

      var data = JSON.stringify({
        firstname: values.firstname,
        lastname: values.lastname,
        email: values.email,
        phone: values.phone,
        password: values.pass,
        type: selectedUser,
      })

      var updateData = JSON.stringify({
        firstname: values.firstname,
        lastname: values.lastname,
        email: values.email,
        phone: values.phone,
        type: selectedUser,
      })

      var config = {
        method: props.editData ? "put" : "post",
        url: `${process.env.REACT_APP_BASE_URL}/users${
          props.editData ? "/update/" + props.editData._id : ""
        }`,
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": props.userData.token,
        },
        data: props.editData ? updateData : data,
      }

      axios(config)
        .then(function (response) {
          setLoader(false)
          setSubmitted(true)
          setTimeout(() => {
            toast.success(response.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            })
          }, 200)
          props.history.push("/user-list")
        })
        .catch(function (error) {
          setLoader(false)
          console.log(error)
          toast.error(error.response && error.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        })
    },
  })

  const submitAddUser = e => {
    e.preventDefault()
    const selectedType = e.target[6].value.split(",")
    if (e.target[4].value === e.target[5].value) {
      setLoader(true)
      var data = JSON.stringify({
        firstname: e.target[0].value,
        lastname: e.target[1].value,
        email: e.target[2].value,
        phone: e.target[3].value,
        password: e.target[4].value,
        type: selectedType[1],
        type_name: selectedType[0],
      })
      var config = {
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/users`,
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": props.userData.token,
        },
        data: data,
      }
      axios(config)
        .then(function (response) {
          setLoader(false)
          e.target[0].value = ""
          e.target[1].value = ""
          e.target[2].value = ""
          e.target[3].value = ""
          e.target[4].value = ""
          e.target[5].value = ""
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
          setSubmitted(true)
          setTimeout(() => {
            setSubmitted(false)
          }, 1000)
        })
        .catch(function (error) {
          setLoader(false)
          console.log(error)
          toast.error(error.response && error.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        })
    } else {
      console.log("Phone Does not match")
    }
  }

  const editUser = e => {
    e.preventDefault()

    const selectedType = e.target[4].value.split(",")
    var data = JSON.stringify({
      firstname: e.target[0].value,
      lastname: e.target[1].value,
      phone: e.target[3].value,
      type: selectedType[1],
      type_name: selectedType[0],
    })

    var config = {
      method: "put",
      url: `${process.env.REACT_APP_BASE_URL}/users/update/${props.editData._id}`,
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": props.userData.token,
      },
      data: data,
    }

    axios(config)
      .then(function (response) {
        setLoader(false)
        e.target[0].value = ""
        e.target[1].value = ""
        e.target[2].value = ""
        e.target[3].value = ""
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
        setSubmitted(true)
        setTimeout(() => {
          setSubmitted(false)
        }, 1000)
      })
      .catch(function (error) {
        console.log(error)
        setLoader(false)
        toast.error(error.response && error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
  }

  if (loader) {
    return <Loader />
  }

  return (
    <>
      <Tostify />
      <LayoutModal
        button={true}
        size={8}
        title={props.editData ? "Edit User" : "Add User"}
        btnTitle={props.editData ? "Add User" : "User List"}
        onclick={() => {
          props.editData
            ? dispatch(userEdit(null))
            : props.history.push("/user-list")
        }}
        sideTitle="User"
      >
        <Form onSubmit={validation.handleSubmit}>
          <Row className="mb-1">
            <Col md={6}>
              <Label className="form-label mt-2">
                First Name <span style={{ color: "red" }}>*</span>
              </Label>
              <div className="col-md-12">
                <Input
                  id="firstname"
                  className="form-control"
                  type="text"
                  placeholder="Enter your First Name"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.firstname || ""}
                  invalid={
                    validation.touched.firstname && validation.errors.firstname
                      ? true
                      : false
                  }
                />
                {validation.touched.firstname && validation.errors.firstname ? (
                  <FormFeedback type="invalid">
                    {validation.errors.firstname}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col md={6}>
              <Label className="form-label mt-2">
                Last Name <span style={{ color: "red" }}>*</span>
              </Label>
              <div className="col-md-12">
                <Input
                  id="lastname"
                  className="form-control"
                  type="text"
                  placeholder="Enter your Last Name"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.lastname || ""}
                  invalid={
                    validation.touched.lastname && validation.errors.lastname
                      ? true
                      : false
                  }
                />
                {validation.touched.lastname && validation.errors.lastname ? (
                  <FormFeedback type="invalid">
                    {validation.errors.lastname}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row className="mb-1">
            <Col md={6}>
              <Label className="form-label mt-2">
                Email <span style={{ color: "red" }}>*</span>
              </Label>
              <div className="col-md-12">
                <Input
                  id="email"
                  className="form-control"
                  type="email"
                  placeholder="Enter Your Email"
                  disabled={props.editData && true}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.email || ""}
                  invalid={
                    validation.touched.email && validation.errors.email
                      ? true
                      : false
                  }
                />
                {validation.touched.email && validation.errors.email ? (
                  <FormFeedback type="invalid">
                    {validation.errors.email}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>

            <Col md={6}>
              <Label className="form-label mt-2">
                Phone <span style={{ color: "red" }}>*</span>
              </Label>
              <div className="col-md-12">
                <Input
                  id="phone"
                  className="form-control"
                  type="text"
                  placeholder="Enter Your Phone"
                  onKeyPress={event => {
                    if (
                      !/[0-9]/.test(event.key) ||
                      event.target.value.length > 9
                    ) {
                      event.preventDefault()
                    }
                  }}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.phone || ""}
                  invalid={
                    validation.touched.phone && validation.errors.phone
                      ? true
                      : false
                  }
                />
                {validation.touched.phone && validation.errors.phone ? (
                  <FormFeedback type="invalid">
                    {validation.errors.phone}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
          {!props.editData && (
            <Row className="mb-1">
              <Col md={6}>
                <Label className="form-label mt-2">
                  Password <span style={{ color: "red" }}>*</span>
                </Label>
                <div className="col-md-12">
                  <Input
                    id="pass"
                    className="form-control"
                    type="password"
                    placeholder="Enter Your Password"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.pass || ""}
                    invalid={
                      validation.touched.pass && validation.errors.pass
                        ? true
                        : false
                    }
                  />
                  {validation.touched.pass && validation.errors.pass ? (
                    <FormFeedback type="invalid">
                      {validation.errors.pass}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col md={6}>
                <Label className="form-label mt-2">
                  Confirm Password <span style={{ color: "red" }}>*</span>
                </Label>
                <div className="col-md-12">
                  <Input
                    id="cnfPass"
                    className="form-control"
                    type="password"
                    placeholder="Enter Your Password"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.cnfPass || ""}
                    invalid={
                      validation.touched.cnfPass && validation.errors.cnfPass
                        ? true
                        : false
                    }
                  />
                  {validation.touched.cnfPass && validation.errors.cnfPass ? (
                    <FormFeedback type="invalid">
                      {validation.errors.cnfPass}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
          )}
          <Row className="mb-3">
            <Label className="form-label mt-2">
              User Type <span style={{ color: "red" }}>*</span>
            </Label>
            <div className="col-md-12">
              <Input
                id="userType"
                className="form-control"
                type="select"
                placeholder="Enter Your Password"
                onChange={e => {
                  let data = e.target.value.split(",")
                  setSelectedUser(data[1])
                }}
              >
                {userTypes.length > 0 &&
                  userTypes.map((d, i) => {
                    return props.editData &&
                      d._id === props.editData.type._id ? (
                      <option key={d._id} value={[d.name, d._id]}>
                        {d.name}
                      </option>
                    ) : (
                      <option key={d._id} value={[d.name, d._id]}>
                        {d.name}
                      </option>
                    )
                  })}
              </Input>
            </div>
          </Row>
          {!submitted ? (
            <button type="submit" className="btn btn-primary ">
              <i className="bx bxs-hand-up font-size-16 align-middle me-2"></i>{" "}
              Submit
            </button>
          ) : (
            <button type="button" className="btn btn-success ">
              <i className="bx bx-check-double font-size-16 align-middle me-2"></i>{" "}
              Success
            </button>
          )}
        </Form>
      </LayoutModal>
    </>
  )
}

Adduser.propTypes = {
  userData: PropTypes.object,
  editData: PropTypes.object,
  history: PropTypes.object,
}

const mapStateToProps = state => {
  return {
    userData: state.userData,
    editData: state.userEdit,
  }
}

export default connect(mapStateToProps)(withRouter(Adduser))
