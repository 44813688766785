import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"
import { connect } from "react-redux"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

const SidebarContent = props => {
  const ref = useRef()
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  const isAdmin = props.userData.isAdmin
  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Menu")} </li>
            <li>
              <Link to="/dashboard">
                <i className="bx bx-home-circle"></i>
                <span>{props.t("Dashboard")}</span>
              </Link>
            </li>
            <li>
              <Link to="/add-notification">
                <i className="bx bxs-bell-plus"></i>
                <span>{props.t("Send Notification")}</span>
              </Link>
            </li>
            <li>
              <Link to="/currencies">
                <i className="bx bx-rupee"></i>
                <span>{props.t("Country Rates")}</span>
              </Link>
            </li>
            <li>
              <Link to="/booking-Requests">
                <i className="bx bx-bold"></i>
                <span>{props.t("Booking Requests")}</span>
              </Link>
            </li>
            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bxs-graduation"></i>
                <span>{props.t("Learning")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/add-course">{props.t("Add Course")}</Link>
                </li>
                <li>
                  <Link to="/course-list">{props.t("Course List")}</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-support"></i>
                <span>{props.t("Webinar")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/add-webinar">{props.t("Add Webinar")}</Link>
                </li>
                <li>
                  <Link to="/webinar-list">{props.t("Webinar List")}</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bxs-briefcase"></i>

                <span>{props.t("Directory")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/add-directory">{props.t("Add Directory")}</Link>
                </li>
                <li>
                  <Link to="/directory-list">{props.t("Directory List")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bxs-briefcase"></i>

                <span>{props.t("Suggestions")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/add-suggestions">
                    {props.t("Add Suggestions")}
                  </Link>
                </li>
                <li>
                  <Link to="/suggestions-list">
                    {props.t("Suggestions List")}
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bxl-foursquare"></i>
                <span>{props.t("FAQs")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/add-faq">{props.t("Add FAQ")}</Link>
                </li>
                <li>
                  <Link to="/faq-list">{props.t("FAQ List")}</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bxs-news"></i>
                <span>{props.t("Templates")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/add-category">{props.t("Add Categories")}</Link>
                </li>
                <li>
                  <Link to="/add-template">{props.t("Add Template")}</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bxs-yin-yang"></i>

                <span>{props.t("Entities")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/add-city">{props.t("Cities")}</Link>
                </li>
                <li>
                  <Link to="/#" className="has-arrow">
                    <span>{props.t("Companies")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/add-company">{props.t("Add Company")}</Link>
                    </li>
                    <li>
                      <Link to="/company-list">{props.t("Company List")}</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/add-language">{props.t("Languages")}</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bxs-report"></i>
                <span>{props.t("Report")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/login-in">{props.t("Logged-In")}</Link>
                </li>
                <li>
                  <Link to="/not-logged-id">{props.t("Not Logged-In")}</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/registered-user">
                <i className="bx bxs-user-check"></i>
                <span>{props.t("Registered User List")}</span>
              </Link>
            </li>
            {isAdmin && (
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-user"></i>
                  <span>{props.t("User")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/add-user">{props.t("Add User")}</Link>
                  </li>
                  <li>
                    <Link to="/user-list">{props.t("User List")}</Link>
                  </li>
                </ul>
              </li>
            )}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  userData: PropTypes.object,
}

const mapStateToProps = state => {
  return {
    userData: state.userData,
    editData: state.userEdit,
  }
}

export default connect(mapStateToProps)(
  withRouter(withTranslation()(SidebarContent))
)
