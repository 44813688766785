import { connect } from "react-redux"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import React, { useState, useEffect } from "react"
import axios from "axios"
import BootstrapTable from "react-bootstrap-table-next"
import { LocalizationProvider } from "@mui/lab"
import MobileDatePicker from "@mui/lab/MobileDatePicker"
import moment from "moment"
import MomentUtils from "@date-io/moment"
import { TextField } from "@mui/material"
import "react-datepicker/dist/react-datepicker.css"
import Loader from "components/Custom/Loader"
import Tostify from "components/Custom/Tostify"
import LayoutModal from "../../components/Custom/LayoutModel"
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import { Row, Col } from "../imports/import"
function NotLoggedIn(props) {
  const date = new Date()
  const [notLoggedinList, setNotLoggedInList] = useState([])
  const [loader, setLoader] = useState(false)
  const [fromDate, setFromDate] = useState(
    new Date(date.getFullYear(), date.getMonth(), 1)
  )
  const [toDate, setToDate] = useState(new Date())
  const getLoggedinList = () => {
    var data = JSON.stringify({
      from: fromDate,
      to: toDate,
    })
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/notLoggedinUsers`,
      headers: {
        "x-auth-token": props.userData.token,
        "Content-Type": "application/json",
      },
      data: data,
    }
    setLoader(true)
    axios(config)
      .then(function (response) {
        setLoader(false)
        setNotLoggedInList(response.data)
      })
      .catch(function (error) {
        setLoader(false)
        console.log(error)
      })
  }
  useEffect(() => {
    getLoggedinList()
  }, [])

  const handleSubmit = () => {
    getLoggedinList()
  }

  const columns = [
    {
      dataField: "_id.name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "_id.phone",
      text: "Phone",
      sort: true,
    },
    {
      dataField: "count",
      text: "Count",
      sort: true,
    },
  ]

  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange,
  }) => (
    <div className="btn-group" role="group">
      {options.map(option => {
        const isSelect = currSizePerPage === `${option.page}`
        return (
          <button
            key={option.text}
            type="button"
            onClick={() => onSizePerPageChange(option.page)}
            className={`btn ${isSelect ? "btn-secondary" : "btn-primary"}`}
          >
            {option.text}
          </button>
        )
      })}
    </div>
  )

  const defaultSorted = [
    {
      dataField: "name",
      order: "asc",
    },
  ]

  const pageOptions = {
    sizePerPage: 50,
    sizePerPageRenderer,
    totalSize: notLoggedinList.length, // replace later with size(customers),
  }
  const { SearchBar } = Search
  if (loader) {
    return <Loader />
  }
  return (
    <>
      <Tostify />
      <LayoutModal title="Not LoggedIn User List" sideTitle="User">
        <PaginationProvider
          pagination={paginationFactory(pageOptions)}
          keyField="_id.userId"
          columns={columns}
          data={notLoggedinList}
        >
          {({ paginationProps, paginationTableProps }) => (
            <ToolkitProvider
              keyField="_id.userId"
              columns={columns}
              data={notLoggedinList}
              search
            >
              {toolkitProps => (
                <React.Fragment>
                  <Row className="mb-2">
                    <Col md="4">
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <SearchBar {...toolkitProps.searchProps} />
                          <i className="bx bx-search-alt search-icon" />
                        </div>
                      </div>
                    </Col>
                    <Col md="8">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <LocalizationProvider dateAdapter={MomentUtils}>
                            <MobileDatePicker
                              label="From Date"
                              inputFormat="DD/MM/YYYY"
                              value={fromDate}
                              onChange={e => {
                                const DateFrom = moment(e)
                                setFromDate(DateFrom)
                              }}
                              renderInput={params => (
                                <TextField variant="standard" {...params} />
                              )}
                            />
                          </LocalizationProvider>
                        </div>
                        <div>
                          {" "}
                          <LocalizationProvider dateAdapter={MomentUtils}>
                            <MobileDatePicker
                              label="To Date"
                              inputFormat="DD/MM/YYYY"
                              value={toDate}
                              onChange={e => {
                                const dateTo = moment(e)
                                setToDate(dateTo)
                                // setTimeout(() => {
                                //   handleSubmit()
                                // }, 1000)
                              }}
                              renderInput={params => (
                                <TextField variant="standard" {...params} />
                              )}
                            />
                          </LocalizationProvider>
                        </div>

                        <div>
                          {" "}
                          <button
                            className="btn btn-primary w-md btn btn-sm "
                            type="submit"
                            style={{ marginTop: 15 }}
                            onClick={() => handleSubmit()}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl="12">
                      <div className="table-responsive">
                        <BootstrapTable
                          keyField={"_id"}
                          responsive
                          bordered={false}
                          striped={true}
                          defaultSorted={defaultSorted}
                          hover
                          classes={"table align-middle table-nowrap"}
                          headerWrapperClasses={"thead-light"}
                          {...toolkitProps.baseProps}
                          {...paginationTableProps}
                        />
                      </div>
                    </Col>
                  </Row>
                </React.Fragment>
              )}
            </ToolkitProvider>
          )}
        </PaginationProvider>
      </LayoutModal>
    </>
  )
}

NotLoggedIn.propTypes = {
  userData: PropTypes.object,
  editUser: PropTypes.any,
  history: PropTypes.any,
}

const mapStateToProps = state => {
  return { userData: state.userData }
}

export default connect(mapStateToProps)(withRouter(NotLoggedIn))
