import React, { useState, useRef, useEffect } from "react"
import axios from "axios"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import LayoutModal from "../../components/Custom/LayoutModel"
import { useDispatch } from "react-redux"
import {
  Row,
  Col,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  Button,
} from "reactstrap"
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import Loader from "components/Custom/Loader"
import BootstrapTable from "react-bootstrap-table-next"
import { toast } from "react-toastify"
import { userEdit } from "../../store/actions"
import { withRouter, Link } from "react-router-dom"
import Tostify from "components/Custom/Tostify"
import "../DatatableScss/datatables.scss"
function CompanyList(props) {
  const dispatch = useDispatch()
  const [rowIndexTable, setRowIndexTable] = useState()
  const [loader, setLoader] = useState(false)
  const [companyList, setCompanyList] = useState([])
  useEffect(() => {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/companies/0`,
      headers: {
        "x-auth-token": props.userData.token,
      },
    }

    axios(config)
      .then(function (response) {
        setCompanyList(response.data.companies)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [])

  const editCompanyData = data => {
    try {
      localStorage.setItem("editData", JSON.stringify(data))
    } catch (e) {
      console.log("company list editData localStorage fail")
    }

    try {
      dispatch(userEdit(data))

      props.history.push("/add-company")
    } catch (e) {
      console.log(e, "company list redux editdata fail")
    }
  }

  const changeStatus = data => {
    setRowIndexTable(rowIndexTable - 1)
    setLoader(true)
    var config = {
      method: "put",
      url: `${process.env.REACT_APP_BASE_URL}/companies/${data._id}/${
        data.isActive ? "0" : "1"
      }`,
      headers: {
        "x-auth-token": props.userData.token,
      },
      data: data,
    }

    axios(config)
      .then(function (response) {
        setLoader(false)
        setTimeout(() => {
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        }, 200)
        let values = companyList
        values[companyList.indexOf(data)].isActive = !data.isActive
        setCompanyList([...values])
      })
      .catch(function (error) {
        console.log(error)
        setLoader(false)
        toast.error(error.response && error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
  }

  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "tagLine",
      text: "Tagline",
      sort: true,
    },
    {
      dataField: "gst",
      text: "GST Number",
      sort: true,
    },
    {
      dataField: "df0",
      isDummyField: true,
      text: "Image",
      formatter: (cellContent, row, rowIndex) => {
        return (
          <div
            style={{
              display: "flex",
            }}
          >
            {row.file && (
              <img
                data-dz-thumbnail=""
                height="80"
                className="avatar-sm rounded bg-light"
                style={{
                  objectFit: "cover",
                }}
                src={`${process.env.REACT_APP_IMAGE_URL}/company/${row.file.filename}`}
              />
            )}
          </div>
        )
      },
    },
    {
      dataField: "isActive",
      text: "Status",
      sort: true,
      formatter: (cellContent, row, rowIndex, field) => {
        if (row.isActive) {
          return (
            <>
              <Button
                size="sm"
                type="button"
                id={rowIndex === rowIndexTable ? "PopoverLegacy" : ""}
                className="btn btn-success"
              >
                Active
              </Button>
              {rowIndex === rowIndexTable && (
                <UncontrolledPopover
                  placement="top"
                  target="PopoverLegacy"
                  trigger="legacy"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <PopoverHeader style={{ textAlign: "center" }}>
                    Are you sure, you want to deactive This company ?
                  </PopoverHeader>
                  <PopoverBody>
                    <Button
                      size="sm"
                      style={{
                        marginRight: 3,
                      }}
                      onClick={() => changeStatus(row)}
                      color="primary"
                    >
                      Yes
                    </Button>
                    <Button
                      size="sm"
                      style={{
                        marginLeft: 5,
                      }}
                      onClick={() => setRowIndexTable(rowIndexTable - 1)}
                      color="danger"
                    >
                      No
                    </Button>
                  </PopoverBody>
                </UncontrolledPopover>
              )}
            </>
          )
        } else {
          return (
            <>
              {" "}
              <Button
                size="sm"
                type="button"
                id={rowIndex === rowIndexTable ? "PopoverLegacy" : ""}
                className="btn btn-danger"
              >
                Deactive
              </Button>
              {rowIndex === rowIndexTable && (
                <UncontrolledPopover
                  placement="top"
                  target="PopoverLegacy"
                  trigger="legacy"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <PopoverHeader style={{ textAlign: "center" }}>
                    {" "}
                    Are you sure, you want to active this company ?
                  </PopoverHeader>
                  <PopoverBody>
                    <Button
                      style={{
                        marginRight: 5,
                      }}
                      size="sm"
                      onClick={() => changeStatus(row)}
                      color="primary"
                    >
                      Yes
                    </Button>
                    <Button
                      style={{
                        marginLeft: 5,
                      }}
                      size="sm"
                      onClick={() => setRowIndexTable(rowIndexTable - 1)}
                      color="danger"
                    >
                      No
                    </Button>
                  </PopoverBody>
                </UncontrolledPopover>
              )}
            </>
          )
        }
      },
      events: {
        onMouseEnter: (e, column, columnIndex, row, rowIndex) => {
          setRowIndexTable(rowIndex)
        },
      },
    },
    {
      dataField: "df1",
      isDummyField: true,
      text: "Action",
      formatter: (cellContent, row, rowIndex) => {
        return (
          <div
            style={{
              display: "flex",
            }}
          >
            <Button
              size="sm"
              type="button"
              className="btn btn-warning"
              onClick={() => editCompanyData(row)}
            >
              Edit
            </Button>
          </div>
        )
      },
      events: {
        onMouseEnter: (e, column, columnIndex, row, rowIndex) => {
          setRowIndexTable(rowIndex)
        },
      },
    },
  ]

  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange,
  }) => (
    <div className="btn-group" role="group">
      {options.map(option => {
        const isSelect = currSizePerPage === `${option.page}`
        return (
          <button
            key={option.text}
            type="button"
            onClick={() => onSizePerPageChange(option.page)}
            className={`btn ${isSelect ? "btn-secondary" : "btn-primary"}`}
          >
            {option.text}
          </button>
        )
      })}
    </div>
  )

  const defaultSorted = [
    {
      dataField: "name",
      order: "asc",
    },
  ]

  const pageOptions = {
    sizePerPage: 10,
    sizePerPageRenderer,
    totalSize: companyList.length, // replace later with size(customers),
  }
  const { SearchBar } = Search
  if (loader) {
    return <Loader />
  }
  return (
    <>
      <Tostify />

      <div
        className="d-flex flex-column flex-sm-column flex-md-column flex-lg-row"
        style={{ flex: 1, minHeight: "100vh" }}
      >
        <div className="col-sm-12 col-lg-10">
          <LayoutModal
            title="Company List"
            sideTitle="Company"
            button={true}
            btnTitle={"Add Compnany"}
            onclick={() => {
              props.history.push("/add-company")
            }}
          >
            <PaginationProvider
              pagination={paginationFactory(pageOptions)}
              keyField="_id"
              columns={columns}
              data={companyList}
            >
              {({ paginationProps, paginationTableProps }) => (
                <ToolkitProvider
                  keyField="_id"
                  columns={columns}
                  data={companyList}
                  search
                >
                  {toolkitProps => (
                    <React.Fragment>
                      <Row className="mb-2">
                        <Col
                          md="4"
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            width: "100%",
                          }}
                        >
                          <div className="search-box me-2 mb-2 d-inline-block">
                            <div className="position-relative">
                              <SearchBar {...toolkitProps.searchProps} />
                              <i className="bx bx-search-alt search-icon" />
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xl="12">
                          <div className="table-responsive">
                            <BootstrapTable
                              keyField="_id"
                              responsive
                              bordered={false}
                              striped={true}
                              defaultSorted={defaultSorted}
                              hover
                              classes={"table align-middle table-nowrap"}
                              headerWrapperClasses={"thead-light"}
                              {...toolkitProps.baseProps}
                              {...paginationTableProps}
                            />
                          </div>
                        </Col>
                      </Row>
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              )}
            </PaginationProvider>
          </LayoutModal>
        </div>
      </div>
    </>
  )
}
CompanyList.propTypes = {
  companyData: PropTypes.object,
  editCompany: PropTypes.any,
  history: PropTypes.any,
  userData: PropTypes.object,
}

const mapStateToProps = state => {
  return {
    userData: state.userData,
    editData: state.userEdit,
  }
}

export default connect(mapStateToProps)(withRouter(CompanyList))
