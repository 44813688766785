import React, { useState } from "react"
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
// import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import "../DatatableScss/datatables.scss"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { Row, Col } from "../imports/import"
import { Container } from "reactstrap"
import { withRouter } from "react-router-dom"
import Loader from "components/Custom/Loader"
function ContactsTable(props) {
  const [directoryList, setDirectoryList] = useState(props.data)
  const [loader, setLoader] = useState(false)
  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
    },
    {
      dataField: "mobile",
      text: "Mobile",
      sort: true,
    },
    {
      dataField: "designation",
      text: "Designation",
      sort: true,
    },
  ]

  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange,
  }) => (
    <div className="btn-group" role="group">
      {options.map(option => {
        const isSelect = currSizePerPage === `${option.page}`
        return (
          <button
            key={option.text}
            type="button"
            onClick={() => onSizePerPageChange(option.page)}
            className={`btn ${isSelect ? "btn-secondary" : "btn-primary"}`}
          >
            {option.text}
          </button>
        )
      })}
    </div>
  )
  const defaultSorted = [
    {
      dataField: "name",
      order: "asc",
    },
  ]
  const pageOptions = {
    sizePerPageRenderer,
    totalSize: directoryList.length, // replace later with size(customers),
    custom: true,
  }
  const { SearchBar } = Search

  if (loader) {
    return <Loader />
  }

  return (
    <Container fluid>
      <PaginationProvider
        pagination={paginationFactory(pageOptions)}
        keyField="_id"
        columns={columns}
        data={directoryList}
      >
        {({ paginationProps, paginationTableProps }) => (
          <ToolkitProvider
            keyField="_id"
            columns={columns}
            data={directoryList}
            search
          >
            {toolkitProps => (
              <React.Fragment>
                <Row className="mb-2">
                  <h6 style={{ marginTop: 10, position: "absolute" }}>
                    {props.dirData.name}
                  </h6>
                  <Col
                    md="4"
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      width: "100%",
                    }}
                  >
                    <div className="search-box me-2 mb-2 d-inline-block">
                      <div className="position-relative">
                        <SearchBar {...toolkitProps.searchProps} />
                        <i className="bx bx-search-alt search-icon" />
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col xl="12">
                    <div className="table-responsive">
                      <BootstrapTable
                        responsive
                        bordered={true}
                        striped={false}
                        defaultSorted={defaultSorted}
                        hover
                        classes={"table align-middle table-nowrap"}
                        headerWrapperClasses={"thead-light"}
                        {...toolkitProps.baseProps}
                        {...paginationTableProps}
                      />
                    </div>
                  </Col>
                </Row>

                <Row className="align-items-md-center mt-30">
                  <Col className="inner-custom-pagination d-flex">
                    <div className="d-inline">
                      <SizePerPageDropdownStandalone {...paginationProps} />
                    </div>
                    <div className="text-md-right ms-auto">
                      <PaginationListStandalone {...paginationProps} />
                    </div>
                  </Col>
                </Row>
              </React.Fragment>
            )}
          </ToolkitProvider>
        )}
      </PaginationProvider>
    </Container>
  )
}
ContactsTable.propTypes = {
  userData: PropTypes.object,
  editUser: PropTypes.any,
  history: PropTypes.any,
  data: PropTypes.any,
  dirData: PropTypes.object,
}

const mapStateToProps = state => {
  return { userData: state.userData }
}

export default connect(mapStateToProps)(withRouter(ContactsTable))
