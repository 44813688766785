import { WEBINARID } from "./actionTypes"

const initialState = null

const webinarId = (state = initialState, action) => {
  switch (action.type) {
    case WEBINARID:
      return action.payload
    default:
      return state
  }
}

export default webinarId
